import Swal from "sweetalert2";

export default async function InfoAlert() {
  const result = await Swal.fire({
    title: "Sei sicuro di voler procedere all'eliminazione del trimestre?",
    icon: "info",
    showCancelButton: true,
    cancelButtonText: "No",
    confirmButtonText: "Si",
    customClass: {
      confirmButton:
        "MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary",
      cancelButton:
        "MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedSecondary MyCancelButton",
      title: "MuiTypography-h6"
    },
    buttonsStyling: false
  });
  if (result.value === true) return true;
  else return false;
}

