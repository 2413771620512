import React, { Component } from "react";

import { withStyles } from "@material-ui/styles";
import {
  Paper,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Slide,
  Dialog,
  Button,
  AppBar,
  Toolbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableSortLabel,
  TableRow,
  IconButton,
  TableContainer
} from "@material-ui/core";

import { Close } from "@material-ui/icons";

import moment from "moment";

import { Bar, Pie, Line } from "react-chartjs-2";

const styles = theme => ({
  paper: {
    padding: theme.spacing(3, 2),
    marginTop: 48
  },
  titolo: {
    marginTop: -45,
    background: "linear-gradient(40deg,#45cafc,#3269c2)",
    color: "white",
    padding: theme.spacing(2, 2),
    boxShadow: "0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15)",
    maxWidth: 405,
    borderRadius: 8
  },
  item: {
    height: "45vh",
    maxHeight: 325
  },
  itemLeft: {
    height: "45vh",
    maxHeight: 325,
    [theme.breakpoints.down("sm")]: {
      height: "auto"
    }
  },
  bottoneDettagli: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    fontSize: 16,
    padding: theme.spacing(1, 3)
  },
  tableContainer: {
    padding: 15,
    paddingTop: 90,
    paddingBottom: 60,
    minHeight: "100vh"
  },
  table: {
    maxWidth: 1200
  }
});

var dataset_attivi = []

const barOptions = (voce, updateDatasetVisibili = null) => ({
  scales: {

    yAxes: [
      {
        afterUpdate: function (axis) {
          if (updateDatasetVisibili !== null) {
            updateDatasetVisibili(axis.chart.data.datasets.filter((dataset, index) => axis.chart.getDatasetMeta(index).hidden !== true).map(dataset => dataset.label))
          }
        },
        ticks: {
          fontColor: "#9f9f9f",
          beginAtZero: true,
          callback: function (label, index, labels) {
            return Math.round(label / 1000).toLocaleString() + "k €";
          }
        }
      }
    ]
  },
  tooltips: {
    callbacks: {
      title: (tooltipItem, data) => {
        return `${voce} ${tooltipItem[0].label}`;
      },
      label: (tooltipItem, data) => {
        const datasetIndex = tooltipItem.datasetIndex;
        const anno = data.datasets[datasetIndex].label;
        const valore = Math.round(tooltipItem.value);
        var stringaPercentuale = "";
        if (datasetIndex !== 0) {
          const valoreAnnoPrec =
            data.datasets[datasetIndex - 1].data[tooltipItem.index];
          const percentuale = Math.round(
            (100 * (valore - valoreAnnoPrec)) / Math.abs(valoreAnnoPrec)
          );

          if (isFinite(percentuale))
            if (percentuale >= 0) {
              stringaPercentuale = `(+${percentuale}%)`;
            } else {
              stringaPercentuale = `(${percentuale}%)`;
            }
        }

        return `${anno}: ${valore.toLocaleString()}€ ${stringaPercentuale}`;
      }
    }
  }
});

const pieOptionsTrimestrale = {
  legend: {
    position: "bottom",
    onClick: null
  },
  tooltips: {
    mode: "nearest",
    callbacks: {
      title: (tooltipItem, data) => {
        return `${tooltipItem[0].index + 1}° trimestre`;
      },
      label: (tooltipItem, data) => {
        const indice = tooltipItem.index;
        const totale = data.datasets[0].data.reduce(
          (totale, curr) => totale + curr,
          0
        );
        const totalePeriodo = data.datasets[0].data[indice];
        const percentuale = Math.round((1000 * totalePeriodo) / totale) / 10;
        return `${percentuale.toLocaleString()}%`;
      }
    }
  }
};

const pieOptionsAnnuale = {
  legend: {
    position: "bottom",
    onClick: null
  },
  tooltips: {
    mode: "nearest",
    callbacks: {
      title: (tooltipItem, data) => {
        return data.labels[tooltipItem[0].index];
      },
      label: (tooltipItem, data) => {
        const indice = tooltipItem.index;
        const totale = data.datasets[0].data.reduce(
          (totale, curr) => totale + curr,
          0
        );
        const totalePeriodo = data.datasets[0].data[indice];
        const percentuale = Math.round((1000 * totalePeriodo) / totale) / 10;
        return `${percentuale.toLocaleString()}%`;
      }
    }
  }
};

const lineOptions = {
  legend: {
    onClick: null
  },
  tooltips: {
    callbacks: {
      title: (tooltipItem, data) => {
        return (
          data.datasets[0].label +
          " " +
          data.datasets[tooltipItem[0].datasetIndex].data[
            tooltipItem[0].index
          ].x.format("YYYY")
        );
      },
      label: (tooltipItem, data) => {
        const ultimoValore =
          data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].y;

        var stringaPercentuale = "";

        if (tooltipItem.index !== 0) {
          const penultimoValore =
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index - 1]
              .y;
          const percentuale = Math.round(
            (100 * (ultimoValore - penultimoValore)) / Math.abs(penultimoValore)
          );
          if (percentuale >= 0) {
            stringaPercentuale = ` (+${percentuale}%)`;
          } else {
            stringaPercentuale = ` (${percentuale}%)`;
          }
        }

        return (
          Math.round(ultimoValore).toLocaleString() + "€" + stringaPercentuale
        );
      }
    }
  },
  scales: {
    yAxes: [
      {
        ticks: {
          fontColor: "#9f9f9f",
          beginAtZero: true,
          callback: function (label, index, labels) {
            return Math.round(label / 1000).toLocaleString() + "k €";
          }
        }
      }
    ],
    xAxes: [
      {
        type: "time",
        time: {
          unit: "year"
        },
        gridLines: {
          drawBorder: false,
          color: "rgba(255,255,255,0.1)",
          zeroLineColor: "transparent",
          display: false
        },
        ticks: {
          padding: 20,
          fontColor: "#9f9f9f"
        }
      }
    ]
  }
};

const colors = [
  "rgba(255, 117, 114, 0.8)",
  "rgba(131, 157, 255, 0.8)",
  "rgba(0, 196, 147, 0.8)",
  "rgba(251, 174, 49, 0.8)"
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class EconomicoLayout extends Component {
  state = {
    comparativaEqua: false,
    dettagliOpened: false,
    datasetVisibili: []
  };

  openDettagli = () => {
    this.setState({
      dettagliOpened: true
    });
  };
  closeDettagli = () => {
    this.setState({
      dettagliOpened: false
    });
  };
  updateDatasetVisibili = (datasets) => {
    if (JSON.stringify(datasets) !== JSON.stringify(this.state.datasetVisibili)) {
      this.setState({
        datasetVisibili: datasets
      });
    }
  }
  render() {
    const { classes, dati } = this.props;

    const ultimoAnno = dati.categorie.reduce((ultimoAnno, value) => {
      const anno = moment(value.periodo).year();
      if (anno >= ultimoAnno) return anno;
      else return ultimoAnno;
    }, 0);

    const ultimoTrimestreUltimoAnno = dati.categorie
      .filter(value => moment(value.periodo).year() === ultimoAnno)
      .reduce((ultimoTrimestre, value) => {
        const trimestre = moment(value.periodo).quarter();
        if (trimestre >= ultimoTrimestre) return trimestre;
        else return ultimoTrimestre;
      }, 0);

    var index = 0;
    var datasetsBarTrimestrale = [];
    var datasetAnnuale = [];
    dati.categorie
      .reduce((map, current) => {
        const data = moment(current.periodo);
        const anno = data.year();
        const periodo = data.quarter() - 1;

        if (map.has(anno)) {
          var periodi = map.get(anno);
          periodi[periodo] += current.importo;
          map.set(anno, periodi);
        } else {
          var periodi = [0, 0, 0, 0];
          periodi[periodo] = current.importo;
          map.set(anno, periodi);
        }

        return map;
      }, new Map())
      .forEach((value, key) => {
        datasetsBarTrimestrale.push({
          label: key,
          data: value.map(value => Math.round(value)),
          backgroundColor: colors[index]
        });

        datasetAnnuale.push({
          x: moment(key, "YYYY"),
          y: this.state.comparativaEqua
            ? value
              .slice(0, ultimoTrimestreUltimoAnno)
              .reduce((totale, curr) => totale + curr, 0)
            : value.reduce((totale, curr) => totale + curr, 0)
        });
        index += 1;
      });

    var periodi = [0, 0, 0, 0];
    dati.categorie
      .filter(dato => {
        return this.state.datasetVisibili.includes(moment(dato.periodo).year())
      }).forEach(dato => {
        const periodo = moment(dato.periodo).quarter() - 1;
        periodi[periodo] += dato.importo;
      });

    const nrAnni = datasetAnnuale.length;
    var vettoreAnni = new Array(nrAnni);
    for (var i = 0; i < nrAnni; i++) {
      vettoreAnni[nrAnni - 1 - i] = ultimoAnno - i;
    }

    var dettagli = [];
    dati.dettagli
      .reduce((map, dettaglio) => {
        if (
          moment(dettaglio.periodo).quarter() > ultimoTrimestreUltimoAnno &&
          this.state.comparativaEqua
        ) {
          return map;
        }
        const anno = moment(dettaglio.periodo).year();
        const indiceVettore = anno + nrAnni - ultimoAnno;

        if (!map.has(dettaglio.conto)) {
          var voce = new Array(nrAnni + 1).fill(0);
          voce[0] = dettaglio.descrizione;
          voce[indiceVettore] = dettaglio.importo;
          map.set(dettaglio.conto, voce);
        } else {
          var voce = map.get(dettaglio.conto);
          voce[indiceVettore] += dettaglio.importo;
          map.set(dettaglio.conto, voce);
        }
        return map;
      }, new Map())
      .forEach((value, key) => {
        dettagli.push(value);
      });
    this.props.trimestri(datasetsBarTrimestrale);
    this.props.dettagli(dettagli);

    if (this.props.download) {
      return (
        <div style={{ width: 915, height: 400 }}>
          <Bar
            id={this.props.idGrafico}
            options={barOptions(this.props.voce)}
            data={{
              labels: [
                "1° trimestre",
                "2° trimestre",
                "3° trimestre",
                "4° trimestre"
              ],
              datasets: datasetsBarTrimestrale
                .sort((a, b) => {
                  if (a.label > b.label) return 1;
                  else if (a.label < b.label) return -1;
                  else return 0;
                })
                .slice(-4)

            }}
          />
        </div>
      );
    }

    return (
      <React.Fragment>
        <Paper className={classes.paper}>
          <Grid
            container
            spacing={2}
            align="center"
            alignItems="center"
            justify="center"
          >
            <Grid item xs={12}>
              <Typography className={classes.titolo} variant="h6">
                {this.props.voce} trimestrali
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              className={classes.item}
              style={{ paddingBottom: 75, maxHeight: 280, maxWidth: 330 }}
            >
              <Typography
                variant="h6"
                align="center"
                style={{ marginBottom: 30 }}
              >
                Stagionalità trimestrale
              </Typography>
              <Pie
                options={pieOptionsTrimestrale}
                data={{
                  datasets: [
                    {
                      backgroundColor: colors,
                      data: periodi
                    }
                  ],
                  labels: ["1°", "2°", "3°", "4°"]
                }}
              />
            </Grid>
            <Grid item xs={12} md={8} className={classes.item}>
              <Bar
                id={this.props.idGrafico}
                options={barOptions(this.props.voce, this.updateDatasetVisibili)}
                data={{
                  labels: [
                    "1° trimestre",
                    "2° trimestre",
                    "3° trimestre",
                    "4° trimestre"
                  ],
                  datasets: datasetsBarTrimestrale
                    .sort((a, b) => {
                      if (a.label > b.label) return 1;
                      else if (a.label < b.label) return -1;
                      else return 0;
                    })
                    .slice(-4)

                }}
              />
            </Grid>
          </Grid>
        </Paper>
        <Paper
          className={classes.paper}
          style={{
            marginTop: 40,
            marginBottom: 40
          }}
        >
          <Grid
            container
            spacing={2}
            align="center"
            alignItems="center"
            justify="center"
          >
            <Grid item xs={12} align="center">
              <Typography className={classes.titolo} variant="h6">
                {this.props.voce} annuali
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              className={classes.itemLeft}
              style={{
                paddingBottom: ultimoTrimestreUltimoAnno === 4 ? 75 : 0,
                maxHeight: 280,
                maxWidth: 330
              }}
            >
              {ultimoTrimestreUltimoAnno === 4 ? (
                <>
                  <Typography
                    variant="h6"
                    align="center"
                    style={{ marginBottom: 30 }}
                  >
                    Stagionalità annuale
                  </Typography>
                  <Pie
                    options={pieOptionsAnnuale}
                    data={{
                      datasets: [
                        {
                          backgroundColor: colors,
                          data: datasetAnnuale
                            .map(value => value.y)
                            .sort((a, b) => a - b)
                        }
                      ],
                      labels: datasetAnnuale
                        .map(value => value.x.format("YYYY"))
                        .sort((a, b) => a - b)
                    }}
                  />
                </>
              ) : (
                <div
                  style={{
                    textAlign: "start"
                  }}
                >
                  <Typography variant="body2">
                    Nota: L'anno {ultimoAnno} presenta i dati solo fino al{" "}
                    {ultimoTrimestreUltimoAnno}° trimestre!
                  </Typography>

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.comparativaEqua}
                        onChange={() => {
                          this.setState({
                            comparativaEqua: !this.state.comparativaEqua
                          });
                        }}
                        color="primary"
                      />
                    }
                    label={`Mostra tutti gli anni fino al ${ultimoTrimestreUltimoAnno}° trimestre`}
                    classes={{
                      label: classes.label
                    }}
                  />
                </div>
              )}
            </Grid>
            <Grid item xs={12} md={8} className={classes.item}>
              <Line
                options={lineOptions}
                data={{
                  datasets: [
                    {
                      label: this.props.voce,
                      borderColor: "rgba(76,175,80,1)",
                      backgroundColor: "rgba(0,0,0,0)",
                      pointRadius: 3,
                      borderWidth: 3,
                      lineTension: 0,
                      data: datasetAnnuale.sort((a, b) => {
                        return a.x.valueOf() - b.x.valueOf();
                      })
                    }
                  ]
                }}
              />
            </Grid>
            <Button
              variant="contained"
              color="primary"
              onClick={this.openDettagli}
              className={classes.bottoneDettagli}
            >
              Mostra dettagli
            </Button>
            <Dialog
              fullScreen
              open={this.state.dettagliOpened}
              onClose={this.closeDettagli}
              TransitionComponent={Transition}
            >
              <AppBar className={classes.appBar}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={this.closeDettagli}
                    aria-label="close"
                  >
                    <Close />
                  </IconButton>
                  <Typography variant="h6">
                    Dettagli {this.props.voce.toLowerCase()}
                  </Typography>
                </Toolbar>
              </AppBar>
              <Grid
                container
                align="center"
                alignItems="center"
                justify="center"
              >
                <Grid item xs className={classes.tableContainer}>
                  <Paper className={classes.table}>
                    <SortedTable
                      vettoreAnni={vettoreAnni}
                      dettagli={dettagli}
                    />
                  </Paper>
                </Grid>
              </Grid>
            </Dialog>
          </Grid>
        </Paper>
      </React.Fragment>
    );
  }
}

class SortedTable extends Component {
  state = {
    orderBy: "descrizione",
    direction: "desc"
  };
  createSortHandler = property => {
    if (property === this.state.orderBy) {
      this.setState({
        orderBy: property,
        direction: this.state.direction === "asc" ? "desc" : "asc"
      });
    } else {
      this.setState({
        orderBy: property,
        direction: "desc"
      });
    }
  };

  render() {
    const { orderBy, direction } = this.state;

    const dettagli = this.props.dettagli.sort((a, b) => {
      if (orderBy === "descrizione")
        return a[0].toLowerCase().localeCompare(b[0].toLowerCase());
      else {
        const index = this.props.vettoreAnni.indexOf(orderBy) + 1;
        return b[index] - a[index];
      }
    });

    if (direction === "asc") dettagli.reverse();

    return (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sortDirection={direction}>
                <TableSortLabel
                  active={orderBy === "descrizione"}
                  direction={direction}
                  onClick={() => {
                    this.createSortHandler("descrizione");
                  }}
                >
                  Descrizione
                </TableSortLabel>
              </TableCell>
              {this.props.vettoreAnni.map(anno => (
                <TableCell align="right" key={anno} sortDirection={direction}>
                  <TableSortLabel
                    active={orderBy === anno}
                    direction={direction}
                    onClick={() => {
                      this.createSortHandler(anno);
                    }}
                  >
                    {anno} (€){" "}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dettagli.map((dettaglio, indice) => {
              return (
                <TableRow key={indice} hover={true}>
                  {dettaglio.map((row, index) => {
                    if (index === 0) {
                      return (
                        <TableCell component="th" scope="row" key={index}>
                          {row}
                        </TableCell>
                      );
                    } else {
                      return (
                        <TableCell align="right" key={index}>
                          {Math.round(row).toLocaleString()}
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

export default withStyles(styles)(EconomicoLayout);
