import axios from "axios";

import {
  USER_FAIL,
  LOGOUT_SUCCESS,
  USER_LOADED,
  API_URL,
  AGGIUNTA_CLIENTE,
  UPDATE_CLIENTE_SELEZIONATO,
} from "./types";
import SuccessAlert from "../components/alerts/SuccessAlert";
import ErrorAlert from "../components/alerts/ErrorAlert";

export const loadUser = () => async (dispatch) => {
  try {
    const result = await axios.get(API_URL + "auth/user/", getToken());
    dispatch({
      type: USER_LOADED,
      payload: result.data,
    });
  } catch (error) {
    dispatch({
      type: USER_FAIL,
    });
  }
};

export const login = (username, password) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    username,
    password,
  });

  try {
    const tokenRequest = await axios.post(
      API_URL + "auth/login/",
      body,
      config
    );
    localStorage.setItem("token", tokenRequest.data);
    console.log();
    const userRequest = await axios.get(API_URL + "auth/user/", getToken());
    dispatch({
      type: USER_LOADED,
      payload: userRequest.data,
    });

    setTimeout(() => {
      const token = localStorage.getItem("notification-token");
      console.log("Invio Token");
      console.log(token);
      axios.put(API_URL + "notification/", { token }, getToken());
    }, 10000);
  } catch (error) {
    ErrorAlert("Credenziali non valide");
    dispatch({
      type: USER_FAIL,
    });
  }
};

export const creazioneUtenteDaCommercialista = (user, password) => async (
  dispatch
) => {
  try {
    const payload = JSON.stringify({ ...user, password });
    const utente = await axios.post(
      API_URL + "auth/register/",
      payload,
      getToken()
    );
    SuccessAlert("Utente creato con successo");
    dispatch({
      type: AGGIUNTA_CLIENTE,
      payload: utente.data.ragione_sociale,
    });
  } catch (error) {
    var errori = [];
    Object.values(error.response.data).forEach((data) => {
      if (Array.isArray(data)) {
        data.forEach((msg) => {
          errori.push(msg);
        });
      } else {
        errori.push(data);
      }
    });
    ErrorAlert(errori.join("\n"));
  }
};

export const updateClienteSelezionato = (cliente) => async (dispatch) => {
  dispatch({
    type: UPDATE_CLIENTE_SELEZIONATO,
    payload: cliente,
  });
};

export const logout = () => async (dispatch) => {
  try {
    await axios.post(API_URL + "auth/logout/", null, getToken());
    dispatch({
      type: LOGOUT_SUCCESS,
    });
  } catch (error) {}
};

export const getToken = () => {
  const token = localStorage.getItem("token");

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }

  return config;
};

export const inviaNotifica = (titolo, body) => async (dispatch, getState) => {
  try {
    await axios.post(
      API_URL +
        `notification/?cliente=${getState().auth.clienteSelezionato.replace(
          "&",
          "%26"
        )}`,
      {
        title: titolo,
        body,
        host: window.location.origin,
      },
      getToken()
    );
    SuccessAlert("Notifica inviata correttamente");
  } catch (error) {}
};
