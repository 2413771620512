import React, { Component } from "react";
import { Paper, Typography, Grid, TextField, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import User from "../../../models/User";

import { connect } from "react-redux";
import { inviaNotifica } from "../../../actions/auth";

const styles = (theme) => ({
  paper: {
    margin: "auto",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    maxWidth: 500,
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  textField: {
    maxWidth: "100%",
  },
  form: {
    marginTop: theme.spacing(2),
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  button: { margin: theme.spacing(1) },
});

class InviaNotifica extends Component {
  state = {
    titolo: "",
    titolo_error: false,
    titolo_error_msg: "",
    body: "",
    body_error: false,
    body_error_msg: "",
    errors: false,
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();

    var state = {
      ...this.state,
      titolo_error: false,
      titolo_error_msg: "",
      body_error: false,
      body_error_msg: "",
      errors: false,
    };

    // titolo VALIDATION
    if (state.titolo.length === 0) {
      state.errors = true;
      state.titolo_error = true;
      state.titolo_error_msg = "Il campo non può essere vuoto";
    }
    // body VALIDATION
    if (state.body.length === 0) {
      state.errors = true;
      state.body_error = true;
      state.body_error_msg = "Il campo non può essere vuoto";
    }

    if (state.errors) {
      this.setState(state);
      return;
    }

    this.props.inviaNotifica(state.titolo, state.body);

    this.setState({
      titolo: "",
      titolo_error: false,
      titolo_error_msg: "",
      body: "",
      body_error: false,
      body_error_msg: "",
      errors: false,
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h5" align="center">
          Notifica
        </Typography>
        <form noValidate className={classes.form} onSubmit={this.onSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="off"
                autoFocus
                required
                name="titolo"
                label="Titolo"
                placeholder="Inserire un titolo..."
                fullWidth
                value={this.state.titolo}
                onChange={this.onChange}
                error={this.state.titolo_error}
                helperText={this.state.titolo_error_msg}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="off"
                required
                name="body"
                label="Descrizione"
                fullWidth
                placeholder="Inserire una descrizione..."
                value={this.state.body}
                onChange={this.onChange}
                error={this.state.body_error}
                helperText={this.state.body_error_msg}
                multiline
                rows={10}
              />
            </Grid>
            <div className={classes.buttonContainer}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                type="submit"
              >
                Invia notifica
              </Button>
            </div>
          </Grid>
        </form>
      </Paper>
    );
  }
}

export default connect(null, { inviaNotifica })(
  withStyles(styles)(InviaNotifica)
);
