import Swal from "sweetalert2";

export default function SuccessAlert(msg) {
  Swal.fire({
    title: msg,
    icon: "success",
    confirmButtonText: "OK",
    customClass: {
      confirmButton:
        "MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary",
      title: "MuiTypography-h6"
    },
    buttonsStyling: false
  });
}
