import React, { Component } from "react";

import { connect } from "react-redux";
import {
  getDescrizioneConti,
  updateDescrizioneConti,
  getCostiDellaProduzione,
  getValoreDellaProduzione
} from "../../../actions/bilancio";
import Loading from "../../spinners/Loading";
import { withStyles, Button } from "@material-ui/core";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MaterialTable from "material-table";

const styles = theme => ({
  paper: {
    padding: theme.spacing(3, 2),
    margin: "auto",
    marginTop: 48,
    maxWidth: 800
  },
  titolo: {
    marginTop: -45,
    background: "linear-gradient(40deg,#45cafc,#3269c2)",
    color: "white",
    padding: theme.spacing(2, 2),
    boxShadow: "0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15)",
    maxWidth: 405,
    borderRadius: 8
  },
  list: {
    padding: 0
  },
  autocomplete: {
    width: "100%"
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginTop: 20
  },
  button: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(8),
    padding: "11px 0px",
    fontSize: 16,
    width: "100%"
  }
});

class GestioneConti extends Component {
  state = {
    rimappature: [],
    options: []
  };

  componentDidMount() {
    this.props.getDescrizioneConti();
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.descrizioneConti !== null) {
      var rimappature = Array(props.descrizioneConti.descrizioni.length).fill(
        ""
      );
      props.descrizioneConti.descrizioni.forEach((descrizione, index) => {
        if (
          props.descrizioneConti.rimappatura.find(
            v => v.descrizione === descrizione
          ) !== undefined
        ) {
          rimappature[index] = props.descrizioneConti.rimappatura.find(
            v => v.descrizione === descrizione
          ).rimappatura;
        }
      });
      this.setState({
        rimappature
      });
    }
  }

  capitalizeFirstLetter = string => {
    if (string !== "") return string.charAt(0).toUpperCase() + string.slice(1);
    else return string;
  };

  distinct = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  render() {
    if (this.props.descrizioneConti === null) {
      return <Loading />;
    }
    var pageSizeOptions = [];
    for (
      var i = 10;
      i < this.props.descrizioneConti.descrizioni.length;
      i *= 2
    ) {
      pageSizeOptions.push(i);
    }

    if (
      this.props.descrizioneConti.descrizioni.length >
      pageSizeOptions.slice(-1)[0]
    ) {
      pageSizeOptions.push(this.props.descrizioneConti.descrizioni.length);
    }

    if (this.state.rimappature.length === 0) {
      return <Loading />;
    }
    return (
      <div
        style={{
          maxWidth: 800,
          margin: "auto"
        }}
      >
        <MaterialTable
          columns={[
            {
              title: "Descrizione originale",
              field: "descrizione"
            },
            {
              title: "Nuova descrizione",
              field: "rimappatura",
              render: rowData => {
                return (
                  <Autocomplete
                    freeSolo
                    value={this.state.rimappature[rowData.id]}
                    onClose={e => {
                      var val = this.capitalizeFirstLetter(
                        e.target.type === "text"
                          ? e.target.value
                          : e.target.innerHTML
                      );

                      var rimappature = this.state.rimappature;
                      rimappature[rowData.id] = val;
                      this.setState({
                        options: [...this.state.options, val]
                          .filter(this.distinct)
                          .sort(),
                        rimappature
                      });
                    }}
                    onInputChange={(event, value, reason) => {
                      if (reason === "clear") {
                        var rimappature = this.state.rimappature;
                        rimappature[rowData.id] = "";
                        this.setState({
                          rimappature
                        });
                      }
                    }}
                    options={this.state.options
                      .concat(
                        this.props.descrizioneConti.rimappatura.map(
                          v => v.rimappatura
                        )
                      )
                      .filter(this.distinct)}
                    renderInput={params => (
                      <TextField
                        {...params}
                        placeholder="Inserisci nuova descrizione..."
                        margin="none"
                        variant="standard"
                        fullWidth
                      />
                    )}
                  />
                );
              }
            }
          ]}
          data={this.props.descrizioneConti.descrizioni.map(
            (descrizione, id) => {
              return {
                descrizione,
                id
              };
            }
          )}
          title="Descrizioni"
          options={{
            pageSizeOptions: pageSizeOptions,
            pageSize: pageSizeOptions[0],
            padding: "dense"
          }}
          localization={{
            pagination: {
              labelDisplayedRows: "{from}-{to} of {count}",
              firstTooltip: "Prima pagina",
              lastTooltip: "Ultima pagina",
              nextTooltip: "Prossima pagina",
              previousTooltip: "Pagina precedente",
              labelRowsSelect: "Righe"
            },

            body: {
              emptyDataSourceMessage: "Nessuna descrizione trovata",
              filterRow: {
                filterTooltip: "filtra"
              }
            }
          }}
        />
        <div className={this.props.classes.buttonContainer}>
          <Button
            variant="contained"
            color="primary"
            className={this.props.classes.button}
            type="submit"
            onClick={() => {
              var toSend = [];
              this.state.rimappature.forEach((v, index) => {
                if (v !== "") {
                  toSend.push({
                    descrizione: this.props.descrizioneConti.descrizioni[index],
                    rimappatura: this.state.rimappature[index]
                  });
                }
              });
              this.props.updateDescrizioneConti(toSend);
              setTimeout(() => {
                this.props.getCostiDellaProduzione(true);
                this.props.getValoreDellaProduzione(true);
                this.props.getDescrizioneConti();
              }, 500);
            }}
          >
            Salva descrizioni
          </Button>
        </div>
      </div>
    );
  }
}

const mapstateToProps = state => ({
  descrizioneConti: state.bilancio.descrizioneConti
});

export default connect(mapstateToProps, {
  getDescrizioneConti,
  getValoreDellaProduzione,
  getCostiDellaProduzione,
  updateDescrizioneConti
})(withStyles(styles)(GestioneConti));
