import React, { Component } from "react";

import { connect } from "react-redux";
import EconomicoLayout from "../../layout/EconomicoLayout";
import Loading from "../../spinners/Loading";
import moment from "moment";

class Ricavi extends Component {
  render() {
    if (
      this.props.ricavi.categorie === null ||
      this.props.ricavi.dettagli === null
    ) {
      return <Loading />;
    }

    if (this.props.periodo === 1) {
      const maxAnno = this.props.ricavi.dettagli.reduce((max, curr) => {
        if (moment(curr.periodo).isAfter(max)) {
          return moment(curr.periodo);
        } else {
          return max;
        }
      }, moment(1970));
      var dettagli = this.props.ricavi.dettagli.filter(
        v => moment(v.periodo).year() === maxAnno.year()
      );
      var categorie = this.props.ricavi.categorie.filter(
        v => moment(v.periodo).year() === maxAnno.year()
      );
    } else {
      var dettagli = this.props.ricavi.dettagli;
      var categorie = this.props.ricavi.categorie;
    }

    return (
      <EconomicoLayout
        voce="Ricavi"
        dati={{
          dettagli,
          categorie
        }}
        download={this.props.download}
        trimestri={this.props.trimestri}
        dettagli={this.props.dettagli}
        idGrafico="grafico-ricavi"
      />
    );
  }
}

const mapStateToProps = state => ({
  ricavi: state.bilancio.ValoreDellaProduzione
});

export default connect(mapStateToProps)(Ricavi);
