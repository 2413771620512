import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getCostiDellaProduzione,
  updateVociCosti
} from "../../../actions/bilancio";
import Loading from "../../spinners/Loading";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Button } from "@material-ui/core";

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: 12,
  borderBottom: "solid 1px #ccc",
  // change background colour if dragging
  background: isDragging ? "#009be5" : "white",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "rgba(0,128,0,0.5)" : "lightgrey",
  padding: 0,
  width: "100%",
  height: "100%",
  border: "solid 1px #ccc"
});

class GestioneCosti extends Component {
  state = {
    categorie: [],
    materiePrime: [],
    servizi: [],
    beniDiTerzi: [],
    personale: [],
    oneriGestione: []
  };
  id2List = {
    droppable: "materiePrime",
    droppable1: "servizi",
    droppable2: "beniDiTerzi",
    droppable3: "personale",
    droppable4: "oneriGestione"
  };

  UNSAFE_componentWillReceiveProps(props) {
    if (props.costi.categorie !== null && props.costi.dettagli !== null) {
      var categorie = [];

      props.costi.categorie.forEach(categoria => {
        if (
          categorie.find(v => v.descrizione === categoria.descrizione) ===
            undefined &&
          (categoria.descrizione === "Acquisti di materie prime e merci" ||
            categoria.descrizione === "Acquisizione di servizi" ||
            categoria.descrizione === "Godimento beni di terzi" ||
            categoria.descrizione === "Costi per il personale" ||
            categoria.descrizione === "Oneri diversi di gestione")
        ) {
          categorie.push({
            id: categoria.id,
            descrizione: categoria.descrizione
          });
        }
      });

      var materiePrime = [];
      props.costi.dettagli
        .filter(costo => {
          return costo.categoria === "Acquisti di materie prime e merci";
        })
        .map(v => {
          return {
            id: String(v.id),
            descrizione: v.descrizione,
            categoria: "Acquisti di materie prime e merci"
          };
        })
        .forEach(costo => {
          const found = materiePrime.find(
            v => v.descrizione === costo.descrizione
          );
          if (found === undefined) {
            materiePrime.push(costo);
          } else {
            found.id = `${found.id}-${costo.id}`;
          }
        });

      var servizi = [];
      props.costi.dettagli
        .filter(costo => {
          return costo.categoria === "Acquisizione di servizi";
        })
        .map(v => {
          return {
            id: String(v.id),
            descrizione: v.descrizione,
            categoria: "Acquisizione di servizi"
          };
        })
        .forEach(costo => {
          const found = servizi.find(v => v.descrizione === costo.descrizione);
          if (found === undefined) {
            servizi.push(costo);
          } else {
            found.id = `${found.id}-${costo.id}`;
          }
        });

      var beniDiTerzi = [];
      props.costi.dettagli
        .filter(costo => {
          return costo.categoria === "Godimento beni di terzi";
        })
        .map(v => {
          return {
            id: String(v.id),
            descrizione: v.descrizione,
            categoria: "Godimento beni di terzi"
          };
        })
        .forEach(costo => {
          const found = beniDiTerzi.find(
            v => v.descrizione === costo.descrizione
          );
          if (found === undefined) {
            beniDiTerzi.push(costo);
          } else {
            found.id = `${found.id}-${costo.id}`;
          }
        });

      var personale = [];
      props.costi.dettagli
        .filter(costo => {
          return costo.categoria === "Costi per il personale";
        })
        .map(v => {
          return {
            id: String(v.id),
            descrizione: v.descrizione,
            categoria: "Costi per il personale"
          };
        })
        .forEach(costo => {
          const found = personale.find(
            v => v.descrizione === costo.descrizione
          );
          if (found === undefined) {
            personale.push(costo);
          } else {
            found.id = `${found.id}-${costo.id}`;
          }
        });

      var oneriGestione = [];
      props.costi.dettagli
        .filter(costo => {
          return costo.categoria === "Oneri diversi di gestione";
        })
        .map(v => {
          return {
            id: String(v.id),
            descrizione: v.descrizione,
            categoria: "Oneri diversi di gestione"
          };
        })
        .forEach(costo => {
          const found = oneriGestione.find(
            v => v.descrizione === costo.descrizione
          );
          if (found === undefined) {
            oneriGestione.push(costo);
          } else {
            found.id = `${found.id}-${costo.id}`;
          }
        });

      this.setState({
        categorie,
        materiePrime: materiePrime.sort((a, b) =>
          a.descrizione.localeCompare(b.descrizione)
        ),
        servizi: servizi.sort((a, b) =>
          a.descrizione.localeCompare(b.descrizione)
        ),
        beniDiTerzi: beniDiTerzi.sort((a, b) =>
          a.descrizione.localeCompare(b.descrizione)
        ),
        personale: personale.sort((a, b) =>
          a.descrizione.localeCompare(b.descrizione)
        ),
        oneriGestione: oneriGestione.sort((a, b) =>
          a.descrizione.localeCompare(b.descrizione)
        )
      });
    }
  }

  getList = id => this.state[this.id2List[id]];

  onDragEnd = result => {
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId !== destination.droppableId) {
      const result = move(
        this.getList(source.droppableId),
        this.getList(destination.droppableId),
        source,
        destination
      );
      this.setState({
        [this.id2List[Object.keys(result)[0]]]: result[Object.keys(result)[0]],
        [this.id2List[Object.keys(result)[1]]]: result[Object.keys(result)[1]]
      });
    }
  };

  componentDidMount() {
    this.props.getCostiDellaProduzione(true);
  }
  render() {
    if (
      this.props.costi.categorie === null ||
      this.props.costi.dettagli === null
    ) {
      return <Loading />;
    }
    console.log(this.state);
    return (
      <>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <div style={{ display: "flex", marginBottom: 100 }}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div style={{ width: "100%" }}>
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <h3>Materie prime e merci</h3>
                  </div>
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {this.state.materiePrime.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            {item.descrizione}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                </div>
              )}
            </Droppable>
            <div style={{ width: 50 }} />
            <Droppable droppableId="droppable1">
              {(provided, snapshot) => (
                <div style={{ width: "100%" }}>
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <h3>Servizi</h3>
                  </div>
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {this.state.servizi.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            {item.descrizione}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>{" "}
                </div>
              )}
            </Droppable>
            <div style={{ width: 50 }} />
            <Droppable droppableId="droppable2">
              {(provided, snapshot) => (
                <div style={{ width: "100%" }}>
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <h3>Affitti e noleggi</h3>
                  </div>
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {this.state.beniDiTerzi.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            {item.descrizione}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                </div>
              )}
            </Droppable>
            <div style={{ width: 50 }} />

            <Droppable droppableId="droppable3">
              {(provided, snapshot) => (
                <div style={{ width: "100%" }}>
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <h3>Personale</h3>
                  </div>
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {this.state.personale.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            {item.descrizione}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                </div>
              )}
            </Droppable>

            <div style={{ width: 50 }} />
            <Droppable droppableId="droppable4">
              {(provided, snapshot) => (
                <div style={{ width: "100%" }}>
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <h3>Oneri diversi di gestione</h3>
                  </div>
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {this.state.oneriGestione.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            {item.descrizione}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                </div>
              )}
            </Droppable>
          </div>
        </DragDropContext>
        <div style={{ width: "100%", textAlign: "center", marginBottom: 100 }}>
          <Button
            variant="contained"
            color="primary"
            style={{
              width: 200,
              padding: "11px 0px",
              fontSize: 16
            }}
            type="submit"
            onClick={() => {
              var toSend = [];
              this.state.personale
                .filter(v => v.categoria !== "Costi per il personale")
                .map(v => {
                  return {
                    descrizione: v.descrizione,
                    rimap: "Costi per il personale"
                  };
                })
                .forEach(v => toSend.push(v));
              this.state.materiePrime
                .filter(
                  v => v.categoria !== "Acquisti di materie prime e merci"
                )
                .map(v => {
                  return {
                    descrizione: v.descrizione,
                    rimap: "Acquisti di materie prime e merci"
                  };
                })
                .forEach(v => toSend.push(v));
              this.state.servizi
                .filter(v => v.categoria !== "Acquisizione di servizi")
                .map(v => {
                  return {
                    descrizione: v.descrizione,
                    rimap: "Acquisizione di servizi"
                  };
                })
                .forEach(v => toSend.push(v));
              this.state.beniDiTerzi
                .filter(v => v.categoria !== "Godimento beni di terzi")
                .map(v => {
                  return {
                    descrizione: v.descrizione,
                    rimap: "Godimento beni di terzi"
                  };
                })
                .forEach(v => toSend.push(v));
              this.state.oneriGestione
                .filter(v => v.categoria !== "Oneri diversi di gestione")
                .map(v => {
                  console.log(v);
                  return {
                    descrizione: v.descrizione,
                    rimap: "Oneri diversi di gestione"
                  };
                })
                .forEach(v => toSend.push(v));

              this.props.updateVociCosti(toSend);
              setTimeout(() => {
                this.props.getCostiDellaProduzione(true);
              }, 500);
            }}
          >
            Salva
          </Button>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  costi: state.bilancio.CostiDellaProduzione
});

export default connect(mapStateToProps, {
  getCostiDellaProduzione,
  updateVociCosti
})(GestioneCosti);
