import React from 'react'
import axios from 'axios'
import { API_URL } from '../../../actions/types'
import { withStyles } from "@material-ui/styles";
import { Close } from "@material-ui/icons";
import { connect } from "react-redux";
import {
    Paper,
    Grid,
    Typography,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    TableContainer,
    Dialog,
    AppBar,
    Slide,
    Toolbar,
    IconButton
} from "@material-ui/core";
import { getToken } from '../../../actions/auth';
import { GrDocumentPdf } from "react-icons/gr";
import { SiMicrosoftexcel } from "react-icons/si";
import { AiOutlineFile } from "react-icons/ai";



const styles = (theme) => ({
    paper: {
        padding: theme.spacing(3, 2),
        marginTop: 48,
        marginBottom: 40,
    },
    titolo: {
        marginTop: -45,
        background: "linear-gradient(40deg,#45cafc,#3269c2)",
        color: "white",
        padding: theme.spacing(2, 2),
        boxShadow: "0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15)",
        maxWidth: 600,
        borderRadius: 8,
    },
    item: {
        // height: "60vh"
    },
    cell: {
        border: "1px solid rgba(224, 224, 224, 1)",
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function ListaDocumenti({ classes, clienteSelezionato, filter, titolo }) {
    const [documentoAperto, setDocumentoAperto] = React.useState()
    const [modalAperto, setModalAperto] = React.useState(false)
    const [listaDocumenti, setListaDocumenti] = React.useState([])


    const base64toBlob = (data, type) => {
        const bytes = atob(data);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }

        return new Blob([out], { type });
    };

    const getDocumento = async (id) => {
        const request = await axios.get(API_URL + `documenti/${id}/`, {
            ...getToken(),
            params: {
                cliente: clienteSelezionato
            }
        })
        const blob = base64toBlob(request.data.file, request.data.content_type)
        setDocumentoAperto(URL.createObjectURL(blob));
        return {
            url: URL.createObjectURL(blob),
            content_type: request.data.content_type,
            nome: request.data.nome
        }
    }

    React.useEffect(() => {
        axios.get(API_URL + "documenti/", {
            ...getToken(),
            params: {
                cliente: clienteSelezionato
            }
        }).then(request => {
            setListaDocumenti(request.data.filter(doc => doc.categoria === filter))
        })

    }, [clienteSelezionato])


    const getIcon = (content_type) => {
        switch (content_type) {
            case "application/pdf":
                return <GrDocumentPdf color="red" colorRendering="red" fontSize="1.25em" />
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                return <SiMicrosoftexcel colorRendering="red" fontSize="1.25em" />
            default:
                return <AiOutlineFile colorRendering="red" fontSize="1.25em" />
        }
    }

    return (
        <Paper className={classes.paper}>
            <Dialog
                fullScreen
                open={modalAperto}
                onClose={() => {
                    setModalAperto(false)
                    setDocumentoAperto(null)
                }}
                TransitionComponent={Transition}

            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => {
                                setModalAperto(false)
                                setDocumentoAperto(null)
                            }}
                            aria-label="close"
                        >
                            <Close />
                        </IconButton>
                        <Typography variant="h6">
                            Documento
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div style={{ height: 'calc(100vh - 60px)', marginTop: 60, overflow: "hidden" }}>
                    <object data={documentoAperto} width="100%" height="100%" />
                </div>
            </Dialog>
            <Grid
                container
                spacing={2}
                align="center"
                alignItems="center"
                justify="center"
            >
                <Grid item xs={12}>
                    <Typography className={classes.titolo} variant="h6">
                        Lista Documenti {titolo}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={12} className={classes.item}>
                    <TableContainer>
                        <Table
                            size="small"
                            id="tabella-redditivita"
                            style={{ width: "99%" }}
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        align="center"
                                        style={{
                                            fontWeight: "bold",
                                            width: "50%"
                                        }}
                                    >
                                        Descrizione
                                    </TableCell>
                                    {filter !== '2086' ?
                                        <TableCell
                                            align="center"
                                            style={{
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Data caricamento
                                        </TableCell> :
                                        <>
                                            <TableCell
                                                align="center"
                                                style={{
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                Anno
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                style={{
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                Semestre
                                            </TableCell>
                                        </>
                                    }
                                    {filter !== '2086' && filter !== '231' ?
                                        <TableCell
                                            align="center"
                                            style={{
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Anno
                                        </TableCell> : <></>
                                    }
                                    <TableCell
                                        align="center"
                                    >
                                        Documento
                                    </TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody className={classes.cell}>
                                {listaDocumenti.map(documento => {
                                    return (
                                        <TableRow>
                                            <TableCell
                                                className={classes.cell}
                                                component="th"
                                                scope="row"
                                            >
                                                {documento.descrizione}
                                            </TableCell>
                                            {filter !== '2086' ?
                                                <TableCell
                                                    className={classes.cell}
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {documento.data_caricamento}
                                                </TableCell> :
                                                <>
                                                    <TableCell
                                                        className={classes.cell}
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        {documento.anno}
                                                    </TableCell>
                                                    <TableCell
                                                        className={classes.cell}
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        {documento.semestre}
                                                    </TableCell>
                                                </>
                                            }
                                            {filter !== '2086' && filter !== '231' ?
                                                <TableCell
                                                    className={classes.cell}
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {documento.anno}
                                                </TableCell> : <></>
                                            }
                                            <TableCell
                                                className={classes.cell}
                                                component="th"
                                                scope="row"
                                                style={{
                                                    textAlign: "center",
                                                    cursor: "pointer"
                                                }}
                                                onClick={async () => {
                                                    const file = await getDocumento(documento.id)
                                                    if (file.content_type === 'application/pdf')
                                                        setModalAperto(true)
                                                    else {
                                                        var a = document.createElement("a");
                                                        a.href = file.url;
                                                        a.download = file.nome;
                                                        a.click();
                                                    }
                                                }}
                                            >
                                                {getIcon(documento.content_type)}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Paper>
    );
}

const mapStateToProps = state => ({
    clienteSelezionato: state.auth.clienteSelezionato
});

export default connect(mapStateToProps)(withStyles(styles)(ListaDocumenti))