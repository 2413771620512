import React, { Component } from "react";
import ProvaCard from "../../cards/HomepageCard";
import { Grid, Paper, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import {
  getValoreDellaProduzione,
  getCostiDellaProduzione
} from "../../../actions/bilancio";

import { TrendingUp, AccountBalance, Publish } from "@material-ui/icons";

import moment from "moment";

import { Line } from "react-chartjs-2";
import Loading from "../../spinners/Loading";

const styles = theme => ({
  container: {
    // marginTop: 50
  },
  paper: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    padding: theme.spacing(3, 2),
    paddingBottom: theme.spacing(7),
    height: "65vh"
  }
});

class Home extends Component {
  calcolaDati = (costi, ricavi) => {
    const ultimoPeriodoCosti = costi.reduce((ultimoPeriodo, current) => {
      const currentPeriodo = moment(current.periodo);
      if (currentPeriodo.isAfter(ultimoPeriodo)) return currentPeriodo;
      else return ultimoPeriodo;
    }, moment(1970));

    const ultimoPeriodoRicavi = ricavi.reduce((ultimoPeriodo, current) => {
      const currentPeriodo = moment(current.periodo);
      if (currentPeriodo.isAfter(ultimoPeriodo)) return currentPeriodo;
      else return ultimoPeriodo;
    }, moment(1970));

    var ultimoPeriodo = moment(1970);
    if (ultimoPeriodoCosti.isAfter(ultimoPeriodoRicavi))
      ultimoPeriodo = ultimoPeriodoCosti;
    else ultimoPeriodo = ultimoPeriodoRicavi;
    const penultimoPeriodo = moment(ultimoPeriodo).subtract(1, "Q");

    const totaleCostiUltimoPeriodo = costi
      .filter(costo => moment(costo.periodo).isSame(ultimoPeriodo))
      .reduce((totale, current) => {
        return totale + current.importo;
      }, 0);

    const totaleRicaviUltimoPeriodo = ricavi
      .filter(ricavo => moment(ricavo.periodo).isSame(ultimoPeriodo))
      .reduce((totale, current) => {
        return totale + current.importo;
      }, 0);

    const totaleCostiPenultimoPeriodo = costi
      .filter(costo => moment(costo.periodo).isSame(penultimoPeriodo))
      .reduce((totale, current) => {
        return totale + current.importo;
      }, 0);

    const totaleRicaviPenultimoPeriodo = ricavi
      .filter(ricavo => moment(ricavo.periodo).isSame(penultimoPeriodo))
      .reduce((totale, current) => {
        return totale + current.importo;
      }, 0);

    return {
      totaleRicaviUltimoPeriodo,
      totaleCostiUltimoPeriodo,
      totaleRicaviPenultimoPeriodo,
      totaleCostiPenultimoPeriodo,
      ultimoPeriodoCosti
    };
  };

  render() {
    const { classes, ricavi, costi } = this.props;

    if (ricavi === null || costi === null) {
      return <Loading />;
    }

    const {
      totaleRicaviUltimoPeriodo,
      totaleCostiUltimoPeriodo,
      totaleRicaviPenultimoPeriodo,
      totaleCostiPenultimoPeriodo,
      ultimoPeriodoCosti
    } = this.calcolaDati(costi, ricavi);

    var coloreRicavi = "";
    var coloreCosti = "";
    var coloreUtileNetto = "";

    const percentualeRicavi = Math.round(
      100 *
      ((totaleRicaviUltimoPeriodo - totaleRicaviPenultimoPeriodo) /
        totaleRicaviPenultimoPeriodo)
    );

    const percentualeCosti = Math.round(
      100 *
      ((totaleCostiUltimoPeriodo - totaleCostiPenultimoPeriodo) /
        totaleCostiPenultimoPeriodo)
    );

    const utileOperativoUltimoPeriodo =
      totaleRicaviUltimoPeriodo - totaleCostiUltimoPeriodo;
    const utileOperativoPenultimoPeriodo =
      totaleRicaviPenultimoPeriodo - totaleCostiPenultimoPeriodo;

    const percentualeUtileOperativo = Math.round(
      100 *
      ((utileOperativoUltimoPeriodo - utileOperativoPenultimoPeriodo) /
        utileOperativoPenultimoPeriodo)
    );

    if (totaleRicaviUltimoPeriodo >= totaleRicaviPenultimoPeriodo)
      coloreRicavi = "green";
    else coloreRicavi = "red";

    if (totaleCostiUltimoPeriodo >= totaleCostiPenultimoPeriodo)
      coloreCosti = "red";
    else coloreCosti = "green";

    if (utileOperativoUltimoPeriodo >= utileOperativoPenultimoPeriodo)
      coloreUtileNetto = "green";
    else coloreUtileNetto = "red";

    return (
      <div className={classes.container}>
        <h1 style={{ textAlign: 'center', marginBottom: 40 }}>Andamento ultimo trimestre</h1>
        <Grid container spacing={5} alignItems="stretch">
          <Grid item xs={12} sm={12} md={4}>
            <ProvaCard
              voce="RICAVI"
              importo={totaleRicaviUltimoPeriodo}
              percentuale={percentualeRicavi}
              periodo={ultimoPeriodoCosti}
              colore={coloreRicavi}
              icon={<AccountBalance fontSize="large" />}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <ProvaCard
              voce="COSTI"
              periodo={ultimoPeriodoCosti}
              importo={totaleCostiUltimoPeriodo}
              percentuale={percentualeCosti}
              colore={coloreCosti}
              icon={<Publish fontSize="large" />}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <ProvaCard
              voce="UTILE OPERATIVO"
              periodo={ultimoPeriodoCosti}
              importo={utileOperativoUltimoPeriodo}
              percentuale={percentualeUtileOperativo}
              colore={coloreUtileNetto}
              icon={<TrendingUp fontSize="large" />}
            />
          </Grid>
        </Grid>
        <Paper className={classes.paper}>
          <Chart ricavi={this.props.ricavi} costi={this.props.costi} />
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ricavi: state.bilancio.ValoreDellaProduzione.categorie,
  costi:
    state.bilancio.CostiDellaProduzione.categorie === null
      ? null
      : state.bilancio.CostiDellaProduzione.categorie.filter(categoria => {
        if (
          categoria.descrizione === "Acquisti di materie prime e merci" ||
          categoria.descrizione === "Acquisizione di servizi" ||
          categoria.descrizione === "Godimento beni di terzi" ||
          categoria.descrizione === "Costi per il personale" ||
          categoria.descrizione === "Oneri diversi di gestione"
        ) {
          return true;
        } else {
          return false;
        }
      })
});

export default connect(mapStateToProps, {
  getCostiDellaProduzione,
  getValoreDellaProduzione
})(withStyles(styles)(Home));

const options = {
  tooltips: {
    callbacks: {
      title: (tooltipItem, data) => {
        return data.datasets[tooltipItem[0].datasetIndex].data[
          tooltipItem[0].index
        ].x.format("[Q]Q - YYYY");
      },
      label: (tooltipItem, data) => {
        return (
          data.datasets[tooltipItem.datasetIndex].label +
          ": " +
          data.datasets[tooltipItem.datasetIndex].data[
            tooltipItem.index
          ].y.toLocaleString() +
          " €"
        );
      },
      labelTextColor: (tooltipItem, data) => {
        return tooltipItem.datasetIndex === 1
          ? "rgba(244,67,54,1)"
          : "rgba(76, 175, 80, 1)";
      }
    }
  },
  scales: {
    yAxes: [
      {
        ticks: {
          fontColor: "#9f9f9f",
          beginAtZero: true,
          callback: function (label, index, labels) {
            return Math.round(label / 1000).toLocaleString() + "k €";
          }
        }
      }
    ],
    xAxes: [
      {
        type: "time",
        time: {
          unit: "quarter"
        },
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: "rgba(255,255,255,0.1)",
          zeroLineColor: "transparent",
          display: false
        },
        ticks: {
          padding: 20,
          fontColor: "#9f9f9f"
        }
      }
    ]
  }
};

function Chart(props) {
  var datasetRicavi = [];
  props.ricavi
    .reduce((map, current) => {
      if (map.has(current.periodo))
        return map.set(
          current.periodo,
          map.get(current.periodo) + current.importo
        );
      else return map.set(current.periodo, current.importo);
    }, new Map())
    .forEach((value, key) => {
      datasetRicavi.push({
        x: moment(key),
        y: Math.round(value)
      });
    });

  var datasetCosti = [];
  props.costi
    .reduce((map, current) => {
      if (map.has(current.periodo))
        return map.set(
          current.periodo,
          map.get(current.periodo) + current.importo
        );
      else return map.set(current.periodo, current.importo);
    }, new Map())
    .forEach((value, key) => {
      datasetCosti.push({
        x: moment(key),
        y: Math.round(value)
      });
    });

  datasetCosti.forEach(v => {
    if (
      datasetRicavi.find(v2 => moment(v2.x).isSame(v.x, "quarter")) ===
      undefined
    ) {
      datasetRicavi.push({
        x: v.x,
        y: 0
      });
    }
  });
  datasetRicavi.forEach(v => {
    if (
      datasetCosti.find(v2 => moment(v2.x).isSame(v.x, "quarter")) === undefined
    ) {
      datasetCosti.push({
        x: v.x,
        y: 0
      });
    }
  });

  const data = {
    datasets: [
      {
        label: "Ricavi",
        borderColor: "rgba(76,175,80,1)",
        backgroundColor: "rgba(0,0,0,0)",
        pointRadius: 3,
        borderWidth: 3,
        lineTension: 0,
        data: datasetRicavi.sort((a, b) => {
          return a.x.valueOf() - b.x.valueOf();
        })
      },
      {
        label: "Costi",
        borderColor: "rgba(244,67,54,1)",
        backgroundColor: "rgba(0,0,0,0)",
        pointRadius: 3,
        borderWidth: 3,
        lineTension: 0,
        data: datasetCosti.sort((a, b) => {
          return a.x.valueOf() - b.x.valueOf();
        })
      }
    ]
  };

  return (
    <>
      <Typography variant="h6" align="center">
        Andamento costi e ricavi
      </Typography>
      <Line options={options} data={data} />
    </>
  );
}
