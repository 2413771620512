import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import {
  Paper,
  Grid,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
} from "@material-ui/core";

import moment from "moment";

import {
  getOneriFinanziari,
  getRettifiche,
  getProventiFinanziari,
  getImposte,
  setEBIT,
  setEBITDA,
} from "../../../actions/bilancio";
import Loading from "../../spinners/Loading";

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(3, 2),
    marginTop: 48,
    marginBottom: 40,
  },
  titolo: {
    marginTop: -45,
    background: "linear-gradient(40deg,#45cafc,#3269c2)",
    color: "white",
    padding: theme.spacing(2, 2),
    boxShadow: "0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15)",
    maxWidth: 600,
    borderRadius: 8,
  },
  item: {
    // height: "60vh"
  },
  cell: {
    border: "1px solid rgba(224, 224, 224, 1)",
  },
});

class Redditivita extends Component {
  state = {
    rettifiche: false,
  };

  ricaricoCostiFalse = ["Tutor S.r.l."];

  componentDidMount() {
    this.props.getOneriFinanziari();
    this.props.getProventiFinanziari();
    this.props.getRettifiche();
    this.props.getImposte();
  }

  createRow = (
    descrizione,
    importo,
    ricavi = "",
    costi = "",
    fontWeight = "nornmal",
    dettaglio = false
  ) => {
    return {
      descrizione,
      importo,
      ricavi,
      costi,
      fontWeight,
      dettaglio,
    };
  };

  percentuale = (valoreInput, riferimento) => {
    return Math.round((100 * valoreInput) / riferimento);
  };

  render() {
    if (
      this.props.ricavi == null ||
      this.props.oneriFinanziari == null ||
      this.props.proventiFinanziari == null ||
      this.props.rettifiche == null ||
      this.props.costi == null ||
      this.props.costi.dettagli == null ||
      this.props.costi.categorie == null
    ) {
      return <Loading />;
    }

    const {
      classes,
      ricavi,
      costi,
      oneriFinanziari,
      proventiFinanziari,
      rettifiche,
    } = this.props;
    var rows = new Array();

    const ultimoAnno = Math.max(
      ...ricavi.map((ricavi) => moment(ricavi.periodo).year()),
      ...costi.categorie.map((costo) => moment(costo.periodo).year())
    );

    // Ricavi
    const ricaviUltimoAnno = ricavi.filter(
      (ricavo) =>
        moment(ricavo.periodo).year() === ultimoAnno &&
        ricavo.descrizione === "Ricavi delle vendite e delle prestazioni"
    );
    const totaleRicaviUltimoAnno = ricaviUltimoAnno.reduce(
      (totale, curr) => totale + curr.importo,
      0
    ) + rettifiche.fatture_da_emettere;
    rows.push(
      this.createRow(
        "Ricavi delle vendite e delle prestazioni",
        Math.round(totaleRicaviUltimoAnno).toLocaleString()
      )
    );
    if (rettifiche.fatture_da_emettere !== 0)
      rows.push(
        this.createRow(
          "di cui Fatture da emettere",
          Math.round(rettifiche.fatture_da_emettere).toLocaleString(),
          "",
          "",
          "normal",
          true
        )
      );
    // Costi
    const costiUltimoAnno = costi.categorie.filter(
      (costo) => moment(costo.periodo).year() === ultimoAnno
    );
    const dettagliCostiUltimoAnno = costi.dettagli.filter(
      (costo) => moment(costo.periodo).year() === ultimoAnno
    );

    //Materie prime e merci
    const materiePrimeMerci = costiUltimoAnno.filter(
      (costo) => costo.descrizione === "Acquisti di materie prime e merci"
    );
    const totaleMateriePrimeMerci = materiePrimeMerci.reduce(
      (totale, curr) => totale + curr.importo,
      0
    );
    rows.push(
      this.createRow(
        "Costi materie prime e merci",
        Math.round(totaleMateriePrimeMerci).toLocaleString(),
        -this.percentuale(totaleMateriePrimeMerci, totaleRicaviUltimoAnno),
        Math.abs(
          Math.round(
            ((totaleRicaviUltimoAnno - totaleMateriePrimeMerci) /
              totaleMateriePrimeMerci) *
            100
          )
        )
      )
    );

    // Valore del venduto
    const totaleValoreDelVenduto =
      totaleRicaviUltimoAnno - totaleMateriePrimeMerci;
    const percentualeValoreDelVenduto = this.percentuale(
      totaleValoreDelVenduto,
      totaleRicaviUltimoAnno
    );
    rows.push(
      this.createRow(
        "Valore del venduto",
        Math.round(totaleValoreDelVenduto).toLocaleString(),
        percentualeValoreDelVenduto < 0 ? 0 : percentualeValoreDelVenduto,
        "",
        "bold"
      )
    );

    // Costi per servizi
    const servizi = costiUltimoAnno.filter(
      (costo) => costo.descrizione === "Acquisizione di servizi"
    );
    const totaleServizi =
      servizi.reduce((totale, curr) => totale + curr.importo, 0) +
      rettifiche.risconti_assicurazioni;

    if (totaleServizi !== 0)
      rows.push(
        this.createRow(
          "Costi per servizi",
          Math.round(totaleServizi).toLocaleString(),
          -this.percentuale(totaleServizi, totaleRicaviUltimoAnno),
          Math.abs(
            Math.round(
              ((totaleRicaviUltimoAnno - totaleServizi) / totaleServizi) * 100
            )
          )
        )
      );

    if (rettifiche.risconti_assicurazioni !== 0)
      rows.push(
        this.createRow(
          "di cui Risconti Attivi, Assicurazioni",
          Math.round(rettifiche.risconti_assicurazioni).toLocaleString(),
          -this.percentuale(
            rettifiche.risconti_assicurazioni,
            totaleRicaviUltimoAnno
          ),
          "",
          "normal",
          true
        )
      );

    // Compensi Amministratori
    const compensiAmministratori = dettagliCostiUltimoAnno.filter(
      (costo) => costo.descrizione === "Compensi amministratori"
    );
    const totaleCompensiAmministratori = compensiAmministratori.reduce(
      (totale, curr) => totale + curr.importo,
      0
    );

    if (totaleCompensiAmministratori !== 0)
      rows.push(
        this.createRow(
          "di cui Compensi Amministratori",
          Math.round(totaleCompensiAmministratori).toLocaleString(),
          -this.percentuale(
            totaleCompensiAmministratori,
            totaleRicaviUltimoAnno
          ),
          "",
          "normal",
          true
        )
      );

    // Costi godimento beni di terzi
    const godimentoBeniTerzi = costiUltimoAnno.filter(
      (costo) => costo.descrizione === "Godimento beni di terzi"
    );
    const totaleGodimentoBeniTerzi =
      godimentoBeniTerzi.reduce((totale, curr) => totale + curr.importo, 0) +
      rettifiche.risconti_affitti;

    if (totaleGodimentoBeniTerzi !== 0)
      rows.push(
        this.createRow(
          "Costi per godimento beni di terzi",
          Math.round(totaleGodimentoBeniTerzi).toLocaleString(),
          -this.percentuale(totaleGodimentoBeniTerzi, totaleRicaviUltimoAnno)
        )
      );

    if (rettifiche.risconti_affitti !== 0)
      rows.push(
        this.createRow(
          "di cui Risconti Attivi, Affitti",
          Math.round(rettifiche.risconti_affitti).toLocaleString(),
          -this.percentuale(
            rettifiche.risconti_affitti,
            totaleRicaviUltimoAnno
          ),
          "",
          "normal",
          true
        )
      );

    // Valore aggiunto
    const totaleValoreAggiunto =
      totaleValoreDelVenduto - totaleServizi - totaleGodimentoBeniTerzi;
    const percentualeValoreAggiunto = this.percentuale(
      totaleValoreAggiunto,
      totaleRicaviUltimoAnno
    );

    if (totaleServizi !== 0)
      rows.push(
        this.createRow(
          "Valore aggiunto",
          Math.round(totaleValoreAggiunto).toLocaleString(),
          percentualeValoreAggiunto < 0 ? 0 : percentualeValoreAggiunto,
          "",
          "bold"
        )
      );

    // Costo personale
    const personale = costiUltimoAnno.filter(
      (costo) => costo.descrizione === "Costi per il personale"
    );
    const totalePersonale =
      personale.reduce((totale, curr) => totale + curr.importo, 0) +
      rettifiche.accantonamento_tfr +
      rettifiche.ratei +
      rettifiche.inail +
      rettifiche.accantonamento_13_14;

    if (totalePersonale !== 0)
      rows.push(
        this.createRow(
          "Costi per il personale",
          Math.round(totalePersonale).toLocaleString(),
          -this.percentuale(totalePersonale, totaleRicaviUltimoAnno)
        )
      );
    if (rettifiche.accantonamento_tfr !== 0)
      rows.push(
        this.createRow(
          "di cui Accantonamento TFR",
          Math.round(rettifiche.accantonamento_tfr).toLocaleString(),
          -this.percentuale(
            rettifiche.accantonamento_tfr,
            totaleRicaviUltimoAnno
          ),
          "",
          "normal",
          true
        )
      );
    if (rettifiche.ratei !== 0)
      rows.push(
        this.createRow(
          "di cui Ratei",
          Math.round(rettifiche.ratei).toLocaleString(),
          -this.percentuale(
            rettifiche.ratei,
            totaleRicaviUltimoAnno
          ),
          "",
          "normal",
          true
        )
      );

    if (rettifiche.ratei !== 0)
      rows.push(
        this.createRow(
          "di cui INAIL",
          Math.round(rettifiche.inail).toLocaleString(),
          -this.percentuale(
            rettifiche.inail,
            totaleRicaviUltimoAnno
          ),
          "",
          "normal",
          true
        )
      );

    if (rettifiche.accantonamento_13_14 !== 0)
      rows.push(
        this.createRow(
          "di cui Accantonamento 13/14esima",
          Math.round(rettifiche.accantonamento_13_14).toLocaleString(),
          -this.percentuale(
            rettifiche.accantonamento_13_14,

          ),
          "",
          "normal",
          true
        )
      );
    if (rettifiche.fatture_da_ricevere !== 0)
      rows.push(
        this.createRow(
          "Fatture da ricevere",
          Math.round(rettifiche.fatture_da_ricevere).toLocaleString(),
          -this.percentuale(rettifiche.fatture_da_ricevere, totaleRicaviUltimoAnno),
          "",
          "normal"
        )
      );

    if (rettifiche.rimanenze !== 0)
      rows.push(
        this.createRow(
          "Rimanenze",
          Math.round(rettifiche.rimanenze).toLocaleString(),
          -this.percentuale(rettifiche.rimanenze, totaleRicaviUltimoAnno),
          "",
          "normal"
        )
      );

    // Margine operativo lordo (EBITDA)
    const totaleMargineOperativoLordo = totaleValoreAggiunto - totalePersonale - rettifiche.fatture_da_ricevere - rettifiche.rimanenze;
    const percentualeMargineOperativoLordo = this.percentuale(
      totaleMargineOperativoLordo,
      totaleRicaviUltimoAnno
    );
    this.props.setEBITDA(totaleMargineOperativoLordo);
    rows.push(
      this.createRow(
        "Margine Operativo Lordo (EBITDA)",
        Math.round(totaleMargineOperativoLordo).toLocaleString(),
        percentualeMargineOperativoLordo < 0
          ? 0
          : percentualeMargineOperativoLordo,
        "",
        "bold"
      )
    );
    // Ammortamenti
    if (rettifiche.ammortamenti !== 0)
      rows.push(
        this.createRow(
          "Ammortamenti, accantonamenti, svalutazioni",
          Math.round(rettifiche.ammortamenti).toLocaleString(),
          -this.percentuale(rettifiche.ammortamenti, totaleRicaviUltimoAnno),
          Math.abs(
            Math.round(
              ((totaleRicaviUltimoAnno - rettifiche.ammortamenti) /
                rettifiche.ammortamenti) *
              100
            )
          )
        )
      );

    // Oneri diversi di gestione
    const oneriDiversiDiGestione = costiUltimoAnno.filter(
      (costo) => costo.descrizione === "Oneri diversi di gestione"
    );
    const totaleOneriDiversiDiGestione = oneriDiversiDiGestione.reduce(
      (totale, curr) => totale + curr.importo,
      0
    );

    if (totaleOneriDiversiDiGestione !== 0)
      rows.push(
        this.createRow(
          "Oneri diversi di gestione",
          Math.round(totaleOneriDiversiDiGestione).toLocaleString(),
          -this.percentuale(
            totaleOneriDiversiDiGestione,
            totaleRicaviUltimoAnno
          )
        )
      );

    // Ricavi diversi di gestione
    const ricaviDiversiDiGestione = ricavi.filter(
      (ricavo) =>
        moment(ricavo.periodo).year() === ultimoAnno &&
        ricavo.descrizione !== "Ricavi delle vendite e delle prestazioni"
    );
    const totaleRicaviDiversiDiGestione = ricaviDiversiDiGestione.reduce(
      (totale, curr) => totale + curr.importo,
      0
    );

    if (totaleRicaviDiversiDiGestione !== 0)
      rows.push(
        this.createRow(
          "Ricavi diversi di gestione",
          Math.round(totaleRicaviDiversiDiGestione).toLocaleString(),
          -this.percentuale(
            totaleRicaviDiversiDiGestione,
            totaleRicaviUltimoAnno
          )
        )
      );

    // Margine Operaivo Netto (EBIT)
    const totaleMargineOperativoNetto =
      totaleMargineOperativoLordo -
      totaleOneriDiversiDiGestione -
      rettifiche.ammortamenti +
      totaleRicaviDiversiDiGestione;

    const percentualeMargineOperativoNetto = this.percentuale(
      totaleMargineOperativoNetto,
      totaleRicaviUltimoAnno
    );
    this.props.setEBIT(totaleMargineOperativoNetto);
    rows.push(
      this.createRow(
        "Margine Operaivo Netto (EBIT)",
        Math.round(totaleMargineOperativoNetto).toLocaleString(),
        percentualeMargineOperativoNetto < 0
          ? 0
          : percentualeMargineOperativoNetto,
        "",
        "bold"
      )
    );

    // Oneri finanziari
    const totaleOneriFinanziari = oneriFinanziari
      .filter((o) => moment(o.periodo).year() === ultimoAnno)
      .reduce((totale, curr) => totale + curr.importo, 0);

    if (totaleOneriFinanziari !== 0)
      rows.push(
        this.createRow(
          "Oneri finanziari",
          Math.round(totaleOneriFinanziari).toLocaleString(),
          -this.percentuale(totaleOneriFinanziari, totaleRicaviUltimoAnno)
        )
      );

    // Proventi finanziari
    const totaleProventiFinanziari = proventiFinanziari
      .filter((o) => moment(o.periodo).year() === ultimoAnno)
      .reduce((totale, curr) => totale + curr.importo, 0);

    if (Math.round(totaleProventiFinanziari) !== 0)
      rows.push(
        this.createRow(
          "Proventi finanziari",
          Math.round(totaleProventiFinanziari).toLocaleString(),
          -this.percentuale(totaleProventiFinanziari, totaleRicaviUltimoAnno)
        )
      );

    // Utile (Perdita) d'eserizio ante imposte
    const totaleUtilePerdita =
      totaleMargineOperativoNetto -
      totaleOneriFinanziari +
      totaleProventiFinanziari;
    const percentualeUtilePerdita = this.percentuale(
      totaleUtilePerdita,
      totaleRicaviUltimoAnno
    );

    rows.push(
      this.createRow(
        (percentualeUtilePerdita < 0 ? "Perdita " : "Utile ") +
        "d'esercizio ante imposte",
        Math.round(totaleUtilePerdita).toLocaleString(),
        percentualeUtilePerdita < 0 ? 0 : percentualeUtilePerdita,
        "",
        "bold"
      )
    );

    var imposte = 0;
    if (this.props.clienteSelezionatoImposte !== 0) {
      imposte = Math.round(this.props.clienteSelezionatoImposte);
    } else {
      imposte = Math.round(
        (totaleUtilePerdita * this.props.clienteSelezionatoPercentualeImposte) /
        100
      );
    }

    const utilePostImposte = totaleUtilePerdita - imposte;
    const percentualeUtilePerditaPost = this.percentuale(
      utilePostImposte,
      totaleRicaviUltimoAnno
    );

    const percentualeImposte = this.percentuale(
      imposte,
      totaleRicaviUltimoAnno
    );

    if (imposte !== 0 && totaleUtilePerdita > 0) {
      rows.push(
        this.createRow(
          "Previsione imposte",
          imposte.toLocaleString(),
          percentualeImposte < 0 ? 0 : percentualeImposte,
          "",
          ""
        )
      );
      rows.push(
        this.createRow(
          (utilePostImposte < 0 ? "Perdita " : "Utile ") +
          "d'esercizio post imposte",
          Math.round(utilePostImposte).toLocaleString(),
          percentualeUtilePerditaPost < 0 ? 0 : percentualeUtilePerditaPost,
          "",
          "bold"
        )
      );
    }

    return (
      <Paper className={classes.paper}>
        <Grid
          container
          spacing={2}
          align="center"
          alignItems="center"
          justify="center"
        >
          <Grid item xs={12}>
            <Typography className={classes.titolo} variant="h6">
              Andamento economico della produzione anno {ultimoAnno}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} className={classes.item}>
            <TableContainer>
              <Table
                size="small"
                id="tabella-redditivita"
                style={{ width: "99%" }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Descrizione
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Importo (€)
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: "bold",
                        display: this.ricaricoCostiFalse.includes(
                          this.props.clienteSelezionato
                        )
                          ? "none"
                          : "",
                      }}
                    >
                      % sui ricavi
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      % ricarico dei costi
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={classes.cell}>
                  {rows.map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{
                            fontWeight: row.fontWeight,
                            paddingLeft: row.dettaglio ? "5em" : 16,
                            fontStyle: row.dettaglio ? "italic" : "normal",
                          }}
                        >
                          {row.descrizione}
                        </TableCell>
                        <TableCell
                          className={classes.cell}
                          align="center"
                          style={{
                            fontWeight: row.fontWeight,
                          }}
                        >
                          {row.importo}
                        </TableCell>
                        <TableCell
                          className={classes.cell}
                          align="center"
                          style={{
                            fontWeight: row.fontWeight,
                            color: row.fontWeight === "bold" ? "green" : "red",
                            display: this.ricaricoCostiFalse.includes(
                              this.props.clienteSelezionato
                            )
                              ? "none"
                              : "",
                          }}
                        >
                          {row.ricavi !== "" && !isNaN(row.ricavi)
                            ? row.ricavi + "%"
                            : ""}
                        </TableCell>
                        <TableCell
                          className={classes.cell}
                          align="center"
                          style={{
                            fontWeight: row.fontWeight,
                            color: "green",
                          }}
                        >
                          {row.costi !== "" && !isNaN(row.costi)
                            ? row.costi + "%"
                            : ""}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

const mapStateToProps = (state) => ({
  ricavi: state.bilancio.ValoreDellaProduzione.categorie,
  costi: state.bilancio.CostiDellaProduzione,
  oneriFinanziari: state.bilancio.OneriFinanziari.categorie,
  proventiFinanziari: state.bilancio.ProventiFinanziari.categorie,
  rettifiche: state.bilancio.rettifiche,
  clienteSelezionato: state.auth.clienteSelezionato,
  clienteSelezionatoImposte: state.auth.clienteSelezionatoImposte,
  clienteSelezionatoPercentualeImposte:
    state.auth.clienteSelezionatoPercentualeImposte,
});

export default connect(mapStateToProps, {
  getOneriFinanziari,
  getRettifiche,
  getImposte,
  getProventiFinanziari,
  setEBIT,
  setEBITDA,
})(withStyles(styles)(Redditivita));
