import React, { Component } from "react";

import { connect } from "react-redux";
import EconomicoLayout from "../../layout/EconomicoLayout";
import Loading from "../../spinners/Loading";
import moment from "moment";

class CostiAffittiNoleggi extends Component {
  render() {
    if (
      this.props.costi.categorie === null ||
      this.props.costi.dettagli === null
    ) {
      return <Loading />;
    }

    var maxAnno = null;
    if (this.props.periodo === 1) {
      maxAnno = this.props.costi.categorie.reduce((max, curr) => {
        if (moment(curr.periodo).isAfter(max)) {
          return moment(curr.periodo);
        } else {
          return max;
        }
      }, moment(1970));
    }

    return (
      <EconomicoLayout
        voce="Costi affitti e noleggi"
        idGrafico="grafico-costi-affitti"
        trimestri={this.props.trimestri}
        dettagli={this.props.dettagli}
        download={this.props.download}
        dati={{
          categorie:
            this.props.periodo !== 1
              ? this.props.costi.categorie
              : this.props.costi.categorie.filter(
                  v => moment(v.periodo).year() === maxAnno.year()
                ),
          dettagli:
            this.props.periodo !== 1
              ? this.props.costi.dettagli.filter(dettaglio => {
                  return dettaglio.categoria === "Godimento beni di terzi";
                })
              : this.props.costi.dettagli
                  .filter(dettaglio => {
                    return dettaglio.categoria === "Godimento beni di terzi";
                  })
                  .filter(v => moment(v.periodo).year() === maxAnno.year())
        }}
      />
    );
  }
}

const mapStateToProps = state => ({
  costi: {
    categorie:
      state.bilancio.CostiDellaProduzione.categorie === null
        ? null
        : state.bilancio.CostiDellaProduzione.categorie.filter(categoria => {
            if (categoria.descrizione === "Godimento beni di terzi") {
              return true;
            } else {
              return false;
            }
          }),
    dettagli: state.bilancio.CostiDellaProduzione.dettagli
  }
});

export default connect(mapStateToProps)(CostiAffittiNoleggi);
