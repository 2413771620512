import React, { Component } from "react";

import { connect } from "react-redux";

import EconomicoLayout from "../../layout/EconomicoLayout";
import Loading from "../../spinners/Loading";
import moment from "moment";

class CostiTotali extends Component {
  render() {
    if (
      this.props.costi.categorie === null ||
      this.props.costi.dettagli === null
    ) {
      return <Loading />;
    }
    var maxAnno = null;
    if (this.props.periodo === 1) {
      maxAnno = this.props.costi.categorie.reduce((max, curr) => {
        if (moment(curr.periodo).isAfter(max)) {
          return moment(curr.periodo);
        } else {
          return max;
        }
      }, moment(1970));
    }

    return (
      <EconomicoLayout
        voce="Costi totali"
        idGrafico="grafico-costi-totali"
        trimestri={this.props.trimestri}
        dettagli={this.props.dettagli}
        download={this.props.download}
        dati={{
          categorie:
            this.props.periodo !== 1
              ? this.props.costi.categorie
              : this.props.costi.categorie.filter(
                  v => moment(v.periodo).year() === maxAnno.year()
                ),
          dettagli:
            this.props.periodo !== 1
              ? this.props.costi.categorie.map(categoria => {
                  return {
                    ...categoria,
                    conto: categoria.descrizione
                  };
                })
              : this.props.costi.categorie
                  .filter(v => moment(v.periodo).year() === maxAnno.year())
                  .map(categoria => {
                    return {
                      ...categoria,
                      conto: categoria.descrizione
                    };
                  })
        }}
      />
    );
  }
}

const mapStateToProps = state => ({
  costi: {
    categorie:
      state.bilancio.CostiDellaProduzione.categorie === null
        ? null
        : state.bilancio.CostiDellaProduzione.categorie.filter(categoria => {
            if (
              categoria.descrizione === "Acquisti di materie prime e merci" ||
              categoria.descrizione === "Acquisizione di servizi" ||
              categoria.descrizione === "Godimento beni di terzi" ||
              categoria.descrizione === "Costi per il personale" ||
              categoria.descrizione === "Oneri diversi di gestione"
            ) {
              return true;
            } else {
              return false;
            }
          })
  }
});

export default connect(mapStateToProps)(CostiTotali);
