import React, { Component } from "react";
import {
  Paper,
  Typography,
  Grid,
  Button,
  Select,
  Input,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

import { connect } from "react-redux";

import moment from "moment";

import { DatePicker } from "@material-ui/pickers";
import { DropzoneArea } from "material-ui-dropzone";
import {
  caricaDati,
  initState,
  getValoreDellaProduzione,
  getCostiDellaProduzione,
} from "../../../actions/bilancio";
import ErrorAlert from "../../alerts/ErrorAlert";
import { ExpandMore } from "@material-ui/icons";
import Axios from "axios";
import { API_URL } from "../../../actions/types";
import { getToken } from "../../../actions/auth";
import SuccessAlert from "../../alerts/SuccessAlert";
import Loading from "../../spinners/Loading";

const styles = (theme) => ({
  paper: {
    margin: "auto",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),

    maxWidth: 850,
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  textField: {
    maxWidth: "100%",
  },
  form: {
    marginTop: theme.spacing(2),
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  button: { margin: theme.spacing(1) },
});

class CaricamentoDocumenti extends Component {
  state = {
    key: 0,
    descrizione: "",
    categoria: "2086",
    file: null,
    anno: null,
    semestre: null,
    loading: false
  };

  formatDate = (date) => {
    return date.year();
  };
  cambiaData = (data) => {
    this.setState({
      data: data.endOf("year"),
      trimestre: data
        .quarter(this.state.trimestre)
        .startOf("quarter")
        .isBefore(moment())
        ? this.state.trimestre
        : 1,
    });
  };

  filesUpload = (files) => {
    if (files.length === 1) {
      this.setState({
        file: files[0],
      });
    } else {
      this.setState({
        file: null,
      });
    }
  };
  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true })
    if (this.state.categoria !== "231" && this.state.anno === null) {
      ErrorAlert("Si prega di inserire la data");
      this.setState({ loading: false })
      return;
    }
    if (this.state.descrizione === "") {
      ErrorAlert("Si prega di inserire una descrizione");
      this.setState({ loading: false })
      return;
    }
    if (this.state.file === null) {
      ErrorAlert("Si prega di caricare un file");
      this.setState({ loading: false })
      return;
    }

    const formData = new FormData();

    formData.append("file", this.state.file);
    formData.append("cliente", this.props.clienteSelezionato);
    formData.append("descrizione", this.state.descrizione);
    formData.append("categoria", this.state.categoria);
    if (this.state.categoria === '2086') {
      formData.append("anno", this.state.anno);
      formData.append("semestre", this.state.semestre);
    }
    if (this.state.categoria !== '2086' && this.state.categoria !== '231') {
      formData.append("anno", this.state.anno);
    }

    try {
      await Axios.post(API_URL + "documenti/", formData, {
        ...getToken(),
        params: { cliente: this.props.clienteSelezionato },
      });
      SuccessAlert("File caricato");
    } catch (error) {
      ErrorAlert("Errore");
    }

    this.setState({
      key: this.state.key + 1,
      file: null,
      descrizione: "",
      loading: false
    });
  };
  render() {
    const { classes } = this.props;

    if (this.state.loading === true) {
      return <Loading />
    }

    return (
      <div className={classes.wrapper}>

        <Paper className={classes.paper}>
          <Typography component="h1" variant="h5" align="center">
            Caricamento documenti
          </Typography>
          <form noValidate className={classes.form} onSubmit={this.onSubmit}>
            <Grid container spacing={2} alignItems="center" justify="center">
              <Grid item xs={8}>
                <FormControl fullWidth>
                  <InputLabel>Descrizione</InputLabel>
                  <Input
                    value={this.state.descrizione}
                    onChange={(e) =>
                      this.setState({ descrizione: e.target.value })
                    }
                  ></Input>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel>Categoria</InputLabel>
                  <Select
                    value={this.state.categoria}
                    onChange={(e) =>
                      this.setState({ categoria: e.target.value, anno: null, semestre: null })
                    }
                  >
                    {[
                      "2086",
                      "231",
                      "Bilanci",
                      "Altri documenti",
                      "GDPR - Data Breach",
                      "GDPR - Informativa",
                      "GDPR - lettere di nomina incaricati interni",
                      "GDPR - Lettere d'incarico resp. esterni",
                      "Modello organizzativo privacy",
                      "GDPR - Registro dei trattamenti",
                      "GDPR - Revoca consenso",
                      "Redditi",
                    ].map((v) => {
                      return (
                        <MenuItem value={v}>
                          {v === "Modello organizzativo privacy"
                            ? `GDPR - ${v}`
                            : v === "Altri documenti"
                              ? `GDPR - ${v}`
                              : v}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              {this.state.categoria === '2086' ?
                <>
                  <Grid item xs={12} sm={4} md={3}>
                    <FormControl fullWidth>
                      <InputLabel>Semestre</InputLabel>
                      <Select
                        value={this.state.semestre}
                        onChange={(e) =>
                          this.setState({ semestre: e.target.value })
                        }
                      >
                        {[
                          1, 2
                        ].map((v) => {
                          return (
                            <MenuItem value={v}>
                              {v} °
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <FormControl fullWidth>
                      <InputLabel>Anno</InputLabel>
                      <Select
                        value={this.state.anno}
                        onChange={(e) =>
                          this.setState({ anno: e.target.value })
                        }
                      >
                        {Array(5).fill(0).map((value, index) => new Date().getFullYear() - index).map((v) => {
                          return (
                            <MenuItem value={v}>
                              {v}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </> : this.state.categoria !== '2086' && this.state.categoria !== '231' ? <Grid item xs={12} sm={4} md={3}>
                  <FormControl fullWidth>
                    <InputLabel>Anno</InputLabel>
                    <Select
                      value={this.state.anno}
                      onChange={(e) =>
                        this.setState({ anno: e.target.value })
                      }
                    >
                      {Array(5).fill(0).map((value, index) => new Date().getFullYear() - index).map((v) => {
                        return (
                          <MenuItem value={v}>
                            {v}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid> : ""}



              <Grid item xs={12}>
                <DropzoneArea
                  key={this.state.key}
                  filesLimit={1}
                  // acceptedFiles={[".pdf"]}
                  showFileNames={true}
                  dropzoneText="Trascina o clicca per selezionare un file"
                  maxFileSize={20485760}
                  getFileAddedMessage={(fileName) =>
                    `File ${fileName} aggiunto con successo`
                  }
                  getFileRemovedMessage={(fileName) =>
                    `File ${fileName} rimosso`
                  }
                  getDropRejectMessage={(
                    rejectedFile,
                    acceptedFiles,
                    maxFileSize
                  ) => {
                    if (rejectedFile.size > maxFileSize) {
                      return `Il file ${rejectedFile.name} supera la dimensione massima di 20M`;
                    } else {
                      return `Il file ${rejectedFile.name
                        } non è in formato ${acceptedFiles.join(", ")}`;
                    }
                  }}
                  onChange={this.filesUpload}
                />
              </Grid>
              <div className={classes.buttonContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  type="submit"
                >
                  CARICA
                </Button>
              </div>
            </Grid>
          </form>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  clienteSelezionato: state.auth.clienteSelezionato,
});

export default connect(mapStateToProps, {
  caricaDati,
  getValoreDellaProduzione,
  getCostiDellaProduzione,
})(withStyles(styles)(CaricamentoDocumenti));
