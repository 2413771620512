import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  Drawer,
  AppBar,
  Toolbar,
  Button,
  Collapse,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  CssBaseline,
  Typography,
  Divider,
  IconButton,
  Popper,
  MenuItem,
  MenuList,
  Grow,
  Paper,
  ClickAwayListener,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core/";

import { useHistory } from "react-router-dom";

import {
  CloudUpload,
  Menu,
  ChevronLeft,
  Add,
  AccountBalance,
  Home,
  ExpandLess,
  ExpandMore,
  Euro,
  TrendingUp,
  CloudDownload,
  AccountCircle,
  Settings,
  ListAlt,
  ErrorOutline,
  DeleteForever,
  Notifications,
  Description,
} from "@material-ui/icons/";

import { Link, withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { logout, updateClienteSelezionato } from "../../actions/auth";
import { useEffect } from "react";
import {
  getCostiDellaProduzione,
  getValoreDellaProduzione,
  getDescrizioneConti,
  getRettifiche,
  getCancellaList,
  initState,
  getProventiFinanziari,
  getOneriFinanziari,
  getImposte,
  getTributi,
} from "../../actions/bilancio";

const drawerWidth = 330;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    maxWidth: 1500,
    margin: "auto",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    scrollbarWidth: 0,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: 0,
  },
  chevron: {
    color: "rgba(255, 255, 255, 0.7)",
    "&:hover,&:focus": {
      backgroundColor: "rgba(255, 255, 255, 0.08)",
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  appBarTitle: {
    [theme.breakpoints.down("xs")]: {
      visibility: "hidden",
      width: 0,
    },
  },
  content: {
    minWidth: 360,
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  link: {
    textDecoration: "none",
  },
  item: {
    paddingTop: 2,
    paddingBottom: 2,
    color: "rgba(255, 255, 255, 0.7)",
    "&:hover,&:focus": {
      backgroundColor: "rgba(255, 255, 255, 0.08)",
    },
  },
  itemCategory: {
    backgroundColor: "#232f3e",
    boxShadow: "0 -1px 0 #404854 inset",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  itemActiveItem: {
    color: "#4fc3f7",
  },
  itemPrimary: {
    fontSize: "inherit",
  },
  itemIcon: {
    minWidth: "auto",
    marginRight: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },
}));

function useWindowSize() {
  const isClient = typeof window === "object";

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }

  const [windowSize, setWindowSize] = React.useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

function MiniDrawer(props) {
  const size = useWindowSize();
  const classes = useStyles();
  const [open, setOpen] = React.useState(size.width > 1000 ? true : false);
  const [openCosti, setOpenCosti] = React.useState(false);
  const [openGestioneConti, setOpenGestioneConti] = React.useState(false);
  const [openDocumenti, setOpenDocumenti] = React.useState(false);
  const [openGDPR, setOpenGDPR] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [openMenu, setOpenMenu] = React.useState(false);
  const url = props.location.pathname;
  const [localClienteSelezionato, setLocalClienteSelezionato] =
    React.useState("");
  const history = useHistory();

  useEffect(() => {
    setLocalClienteSelezionato(props.clienteSelezionato);
    props.initState();
    props.getCostiDellaProduzione(true);
    props.getValoreDellaProduzione(true);
    props.getImposte();
    props.getTributi();

    switch (url) {
      case "/dashboard/eliminazione-dati":
        props.getCancellaList();
        break;

      case "/dashboard/redditivita":
        props.getOneriFinanziari();
        props.getProventiFinanziari();
        props.getRettifiche();
        break;

      case "/dashboard/rinomina-conti":
        props.getDescrizioneConti();
        break;

      case "/dashboard/download":
      case "/dashboard/rettifiche":
        history.push("/dashboard/homepage");
        break;

      default:
        break;
    }
  }, [props.clienteSelezionato]);

  const activeObject = {
    creaUtente: url === "/dashboard/crea-utente" ? true : false,
    inviaNotifica: url === "/dashboard/invia-notifica" ? true : false,
    caricamentoDati: url === "/dashboard/caricamento-dati" ? true : false,
    caricamentoDocumenti:
      url === "/dashboard/caricamento-documenti" ? true : false,
    eliminazioneDocumenti:
      url === "/dashboard/eliminazione-documenti" ? true : false,
    caricamentoImposte: url === "/dashboard/caricamento-imposte" ? true : false,
    rettifiche: url === "/dashboard/rettifiche" ? true : false,
    homepage: url === "/dashboard/homepage" ? true : false,
    utileGestionale: url === "/dashboard/utile-gestionale" ? true : false,
    ricavi: url === "/dashboard/ricavi" ? true : false,
    costiMateriePrime: url === "/dashboard/costi-materie-prime" ? true : false,
    costiServizi: url === "/dashboard/costi-servizi" ? true : false,
    costiPersonale: url === "/dashboard/costi-personale" ? true : false,
    costiAffittiNoleggi:
      url === "/dashboard/costi-affitti-noleggi" ? true : false,
    costiOneriGestione:
      url === "/dashboard/costi-oneri-diversi-gestione" ? true : false,
    costiTotali: url === "/dashboard/costi-totali" ? true : false,
    tributi: url === "/dashboard/tributi" ? true : false,
    redditivita: url === "/dashboard/redditivita" ? true : false,
    download: url === "/dashboard/download" ? true : false,
    documenti2086: url === "/dashboard/documenti-2086" ? true : false,
    documenti231: url === "/dashboard/documenti-231" ? true : false,
    documentiBilanci: url === "/dashboard/documenti-bilanci" ? true : false,
    documentiRedditi: url === "/dashboard/documenti-redditi" ? true : false,
    documentiGDPRDataBreach:
      url === "/dashboard/documenti-GDPR-DataBreach" ? true : false,
    documentiGDPRInformativa:
      url === "/dashboard/documenti-GDPR-Informativa" ? true : false,
    documentiGDPRModelloOrganizzativoPrivacy:
      url === "/dashboard/documenti-GDPR-ModelloOrganizzativoPrivacy"
        ? true
        : false,
    documentiGDPRAltriDocumenti:
      url === "/dashboard/documenti-GDPR-AltriDocumenti" ? true : false,
    documentiGDPRletteredinominaincaricatiinterni:
      url === "/dashboard/documenti-GDPR-letteredinominaincaricatiinterni"
        ? true
        : false,
    documentiGDPRLetteredincaricorespesterni:
      url === "/dashboard/documenti-GDPR-Letteredincaricorespesterni"
        ? true
        : false,
    documentiGDPRRegistrodeitrattamenti:
      url === "/dashboard/documenti-GDPR-Registrodeitrattamenti" ? true : false,
    documentiGDPRRevocaconsenso:
      url === "/dashboard/documenti-GDPR-Revocaconsenso" ? true : false,
    documentiAltriDpocumenti:
      url === "/dashboard/documenti-GDPR-AltriDocumenti" ? true : false,
    documentiModelloOrganizzativoPrivacy:
      url === "/dashboard/documenti-GDPR-ModelloOrganizzativoPrivacy"
        ? true
        : false,
    gestioneConti: url === "/dashboard/rinomina-conti" ? true : false,
    gestioneCosti: url === "/dashboard/gestione-costi" ? true : false,
    eliminazioneDati: url === "/dashboard/eliminazione-dati" ? true : false,
    organigramma: url === "/dashboard/organigramma" ? true : false,
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const toogleCostiMenu = () => {
    setOpenCosti(!openCosti);
  };

  const toogleGestioneCostiMenu = () => {
    setOpenGestioneConti(!openGestioneConti);
  };

  const handleToggle = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenMenu(false);
  };
  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenMenu(false);
    }
  }

  const handleToogleMobile = () => {
    if (size.width < 700) {
      setOpen(false);
    }
  };

  var appBarTitle = "";

  switch (url) {
    case "/dashboard/crea-utente":
      appBarTitle = "Crea utente";
      break;
    case "/dashboard/invia-notifica":
      appBarTitle = "Invia notifica";
      break;
    case "/dashboard/caricamento-dati":
      appBarTitle = "Caricamento dati";
      break;
    case "/dashboard/caricamento-documenti":
      appBarTitle = "Caricamento documenti";
      break;

    case "/dashboard/eliminazione-documenti":
      appBarTitle = "Eliminazione documenti";
      break;
    case "/dashboard/caricamento-imposte":
      appBarTitle = "Caricamento imposte";
      break;
    case "/dashboard/rettifiche":
      appBarTitle = "Rettifiche";
      break;
    case "/dashboard/homepage":
      appBarTitle = "Homepage";
      break;
    case "/dashboard/utile-gestionale":
      appBarTitle = "Utile gestionale";
      break;
    case "/dashboard/ricavi":
      appBarTitle = "Ricavi";
      break;
    case "/dashboard/costi-materie-prime":
      appBarTitle = "Costi - Materie prime e merci";
      break;
    case "/dashboard/costi-servizi":
      appBarTitle = "Costi - Servizi";
      break;
    case "/dashboard/costi-personale":
      appBarTitle = "Costi - Personale";
      break;
    case "/dashboard/costi-affitti-noleggi":
      appBarTitle = "Costi - Affitti e noleggi";
      break;
    case "/dashboard/costi-oneri-diversi-gestione":
      appBarTitle = "Costi - Oneri diversi di gestione";
      break;
    case "/dashboard/costi-totali":
      appBarTitle = "Costi - Costi totali";
      break;
    case "/dashboard/tributi":
      appBarTitle = "Tributi";
      break;
    case "/dashboard/redditivita":
      appBarTitle = "Redditività";
      break;
    case "/dashboard/download":
      appBarTitle = "Download";
      break;
    case "/dashboard/documenti-2086":
      appBarTitle = "Documenti 2086";
      break;
    case "/dashboard/documenti-231":
      appBarTitle = "Documenti 231";
      break;
    case "/dashboard/documenti-GDPR":
      appBarTitle = "Documenti GDPR";
      break;
    case "/dashboard/rinomina-conti":
      appBarTitle = "Gestione conti - Rinomina conti";
      break;
    case "/dashboard/gestione-costi":
      appBarTitle = "Gestione conti - Costi";
      break;
    case "/dashboard/eliminazione-dati":
      appBarTitle = "Eliminazione dati";
      break;
    case "/dashboard/organigramma":
      appBarTitle = "Organigramma";
      break;
    default:
      break;
  }

  if (props.user === null) {
    return "";
  }

  return (
    <div
      className={classes.root}
      style={activeObject.gestioneCosti ? { maxWidth: "100%" } : {}}
    >
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <Menu />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            style={{ flexGrow: 1, fontSize: 16 }}
            className={classes.appBarTitle}
          >
            {appBarTitle}
          </Typography>

          <Button
            ref={anchorRef}
            size="small"
            aria-controls={openMenu ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            startIcon={<AccountCircle />}
            style={{ color: "white", fontSize: 16 }}
          >
            {localClienteSelezionato}
          </Button>
          <Popper
            open={openMenu}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper
                  style={{
                    maxHeight: "85vh",
                    maxWidth: "95vw",
                    overflowY: "scroll",
                  }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={openMenu}
                      style={{ padding: 20 }}
                      onKeyDown={handleListKeyDown}
                    >
                      <FormControl
                        style={{
                          width: "100%",
                        }}
                      >
                        <FormLabel
                          component="legend"
                          style={{ margin: 1 }}
                          focused={false}
                        >
                          Cliente
                        </FormLabel>
                        <RadioGroup
                          aria-label="gender"
                          name="gender1"
                          value={props.clienteSelezionato}
                          onChange={(e) => {
                            props.updateClienteSelezionato(e.target.value);
                            handleToggle();
                          }}
                        >
                          {props.user.clienti.map((cliente, index) => {
                            return (
                              <FormControlLabel
                                key={index}
                                value={cliente}
                                control={<Radio color="primary" />}
                                label={cliente}
                              />
                            );
                          })}
                        </RadioGroup>
                      </FormControl>

                      <Divider />
                      <MenuItem onClick={props.logout} style={{ marginTop: 7 }}>
                        Logout
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        open={open}
      >
        <div style={{ paddingBottom: 25 }}>
          <div className={classes.toolbar}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexGrow: 1,
                color: "white",
                paddingLeft: 10,
              }}
            >
              <img
                src="/logo-bw.png"
                alt="GDP Analytics logo"
                height={40}
                width={40}
                style={{ marginRight: 10 }}
              />
              <span style={{ fontSize: 15 }}> COPERNICO</span>
            </div>
            <IconButton onClick={handleDrawerClose} className={classes.chevron}>
              <ChevronLeft />
            </IconButton>
          </div>
          <Divider />
          {props.user.commercialista || props.user.is_superuser ? (
            <>
              <ListItem className={classes.categoryHeader}>
                <ListItemText
                  classes={{
                    primary: classes.categoryHeaderPrimary,
                  }}
                >
                  {open ? "Gestione Clienti" : "GC"}
                </ListItemText>
              </ListItem>
              <Link
                to="/dashboard/crea-utente"
                className={classes.link}
                onClick={handleToogleMobile}
              >
                <ListItem
                  button
                  className={clsx(
                    classes.item,
                    activeObject.creaUtente && classes.itemActiveItem
                  )}
                >
                  <ListItemIcon className={classes.itemIcon}>
                    <Add />
                  </ListItemIcon>
                  <ListItemText
                    classes={{
                      primary: classes.itemPrimary,
                    }}
                  >
                    Crea utente
                  </ListItemText>
                </ListItem>
              </Link>
              <Link
                to="/dashboard/caricamento-dati"
                className={classes.link}
                onClick={handleToogleMobile}
              >
                <ListItem
                  button
                  className={clsx(
                    classes.item,
                    activeObject.caricamentoDati && classes.itemActiveItem
                  )}
                >
                  <ListItemIcon className={classes.itemIcon}>
                    <CloudUpload />
                  </ListItemIcon>
                  <ListItemText
                    classes={{
                      primary: classes.itemPrimary,
                    }}
                  >
                    Caricamento dati
                  </ListItemText>
                </ListItem>
              </Link>
              <Link
                to="/dashboard/caricamento-documenti"
                className={classes.link}
                onClick={handleToogleMobile}
              >
                <ListItem
                  button
                  className={clsx(
                    classes.item,
                    activeObject.caricamentoDocumenti && classes.itemActiveItem
                  )}
                >
                  <ListItemIcon className={classes.itemIcon}>
                    <CloudUpload />
                  </ListItemIcon>
                  <ListItemText
                    classes={{
                      primary: classes.itemPrimary,
                    }}
                  >
                    Caricamento documenti
                  </ListItemText>
                </ListItem>
              </Link>
              <Link
                to="/dashboard/caricamento-imposte"
                className={classes.link}
                onClick={handleToogleMobile}
              >
                <ListItem
                  button
                  className={clsx(
                    classes.item,
                    activeObject.caricamentoImposte && classes.itemActiveItem
                  )}
                >
                  <ListItemIcon className={classes.itemIcon}>
                    <CloudUpload />
                  </ListItemIcon>
                  <ListItemText
                    classes={{
                      primary: classes.itemPrimary,
                    }}
                  >
                    Caricamento imposte
                  </ListItemText>
                </ListItem>
              </Link>
              <Link
                to="/dashboard/eliminazione-dati"
                className={classes.link}
                onClick={handleToogleMobile}
              >
                <ListItem
                  button
                  className={clsx(
                    classes.item,
                    activeObject.eliminazioneDati && classes.itemActiveItem
                  )}
                >
                  <ListItemIcon className={classes.itemIcon}>
                    <DeleteForever />
                  </ListItemIcon>
                  <ListItemText
                    classes={{
                      primary: classes.itemPrimary,
                    }}
                  >
                    Eliminazione dati
                  </ListItemText>
                </ListItem>
              </Link>
              <Link
                to="/dashboard/eliminazione-documenti"
                className={classes.link}
                onClick={handleToogleMobile}
              >
                <ListItem
                  button
                  className={clsx(
                    classes.item,
                    activeObject.eliminazioneDocumenti && classes.itemActiveItem
                  )}
                >
                  <ListItemIcon className={classes.itemIcon}>
                    <DeleteForever />
                  </ListItemIcon>
                  <ListItemText
                    classes={{
                      primary: classes.itemPrimary,
                    }}
                  >
                    Eliminazione documenti
                  </ListItemText>
                </ListItem>
              </Link>
              <Link
                onClick={handleToogleMobile}
                to="/dashboard/rettifiche"
                className={classes.link}
              >
                <ListItem
                  button
                  className={clsx(
                    classes.item,
                    activeObject.rettifiche && classes.itemActiveItem
                  )}
                >
                  <ListItemIcon className={classes.itemIcon}>
                    <ListAlt />
                  </ListItemIcon>
                  <ListItemText
                    classes={{
                      primary: classes.itemPrimary,
                    }}
                  >
                    Rettifiche
                  </ListItemText>
                </ListItem>
              </Link>
              <ListItem
                button
                onClick={toogleGestioneCostiMenu}
                className={clsx(classes.item, false && classes.itemActiveItem)}
              >
                <ListItemIcon className={classes.itemIcon}>
                  <Settings />
                </ListItemIcon>
                <ListItemText
                  primary="Gestione conti"
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                />
                {openGestioneConti ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openGestioneConti}>
                <Link
                  onClick={handleToogleMobile}
                  to="/dashboard/rinomina-conti"
                  className={classes.link}
                >
                  <ListItem
                    button
                    className={clsx(
                      classes.item,
                      activeObject.gestioneConti && classes.itemActiveItem
                    )}
                  >
                    <ListItemIcon
                      className={classes.itemIcon}
                      style={{
                        paddingLeft: 20,
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: activeObject.gestioneConti
                            ? "#4fc3f7"
                            : "rgba(255, 255, 255, 0.7)",
                          height: 5,
                          width: 5,
                          borderRadius: "50%",
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary="Rinomina conti"
                      classes={{
                        primary: classes.itemPrimary,
                      }}
                    />
                  </ListItem>
                </Link>
                <Link
                  onClick={handleToogleMobile}
                  to="/dashboard/gestione-costi"
                  className={classes.link}
                >
                  <ListItem
                    button
                    className={clsx(
                      classes.item,
                      activeObject.gestioneCosti && classes.itemActiveItem
                    )}
                  >
                    <ListItemIcon
                      className={classes.itemIcon}
                      style={{
                        paddingLeft: 20,
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: activeObject.gestioneCosti
                            ? "#4fc3f7"
                            : "rgba(255, 255, 255, 0.7)",
                          height: 5,
                          width: 5,
                          borderRadius: "50%",
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary="Costi"
                      classes={{
                        primary: classes.itemPrimary,
                      }}
                    />
                  </ListItem>
                </Link>
              </Collapse>
              <Link
                to="/dashboard/invia-notifica"
                className={classes.link}
                onClick={handleToogleMobile}
              >
                <ListItem
                  button
                  className={clsx(
                    classes.item,
                    activeObject.inviaNotifica && classes.itemActiveItem
                  )}
                >
                  <ListItemIcon className={classes.itemIcon}>
                    <Notifications />
                  </ListItemIcon>
                  <ListItemText
                    classes={{
                      primary: classes.itemPrimary,
                    }}
                  >
                    Invia notifica
                  </ListItemText>
                </ListItem>
              </Link>
            </>
          ) : (
            ""
          )}

          <ListItem className={classes.categoryHeader}>
            <ListItemText
              classes={{
                primary: classes.categoryHeaderPrimary,
              }}
            >
              {open ? "Dati Aziendali" : "DA"}
            </ListItemText>
          </ListItem>
          <Link
            onClick={handleToogleMobile}
            to="/dashboard/homepage"
            className={classes.link}
          >
            <ListItem
              button
              className={clsx(
                classes.item,
                activeObject.homepage && classes.itemActiveItem
              )}
            >
              <ListItemIcon className={classes.itemIcon}>
                <Home />
              </ListItemIcon>
              <ListItemText
                classes={{
                  primary: classes.itemPrimary,
                }}
              >
                Homepage
              </ListItemText>
            </ListItem>
          </Link>
          <Link
            onClick={handleToogleMobile}
            to="/dashboard/utile-gestionale"
            className={classes.link}
          >
            <ListItem
              button
              className={clsx(
                classes.item,
                activeObject.utileGestionale && classes.itemActiveItem
              )}
            >
              <ListItemIcon className={classes.itemIcon}>
                <Euro />
              </ListItemIcon>
              <ListItemText
                classes={{
                  primary: classes.itemPrimary,
                }}
              >
                Utile gestionale
              </ListItemText>
            </ListItem>
          </Link>
          <Link
            onClick={handleToogleMobile}
            to="/dashboard/ricavi"
            className={classes.link}
          >
            <ListItem
              button
              className={clsx(
                classes.item,
                activeObject.ricavi && classes.itemActiveItem
              )}
            >
              <ListItemIcon className={classes.itemIcon}>
                <AccountBalance />
              </ListItemIcon>
              <ListItemText
                classes={{
                  primary: classes.itemPrimary,
                }}
              >
                Ricavi
              </ListItemText>
            </ListItem>
          </Link>
          <ListItem
            button
            onClick={toogleCostiMenu}
            className={clsx(classes.item, false && classes.itemActiveItem)}
          >
            <ListItemIcon className={classes.itemIcon}>
              <ErrorOutline />
            </ListItemIcon>
            <ListItemText
              primary="Costi"
              classes={{
                primary: classes.itemPrimary,
              }}
            />
            {openCosti ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openCosti} timeout="auto" unmountOnExit>
            <Link
              onClick={handleToogleMobile}
              to={"/dashboard/costi-materie-prime"}
              className={classes.link}
            >
              <ListItem
                button
                className={clsx(
                  classes.item,
                  activeObject.costiMateriePrime && classes.itemActiveItem
                )}
              >
                <ListItemIcon
                  className={classes.itemIcon}
                  style={{
                    paddingLeft: 20,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: activeObject.costiMateriePrime
                        ? "#4fc3f7"
                        : "rgba(255, 255, 255, 0.7)",
                      height: 5,
                      width: 5,
                      borderRadius: "50%",
                    }}
                  />
                </ListItemIcon>

                <ListItemText
                  primary="Materie prime e merci"
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                />
              </ListItem>
            </Link>

            <Link
              onClick={handleToogleMobile}
              to={"/dashboard/costi-servizi"}
              className={classes.link}
            >
              <ListItem
                button
                className={clsx(
                  classes.item,
                  activeObject.costiServizi && classes.itemActiveItem
                )}
              >
                <ListItemIcon
                  className={classes.itemIcon}
                  style={{
                    paddingLeft: 20,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: activeObject.costiServizi
                        ? "#4fc3f7"
                        : "rgba(255, 255, 255, 0.7)",
                      height: 5,
                      width: 5,
                      borderRadius: "50%",
                    }}
                  />
                </ListItemIcon>

                <ListItemText
                  primary="Servizi"
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                />
              </ListItem>
            </Link>

            <Link
              onClick={handleToogleMobile}
              to={"/dashboard/costi-personale"}
              className={classes.link}
            >
              <ListItem
                button
                className={clsx(
                  classes.item,
                  activeObject.costiPersonale && classes.itemActiveItem
                )}
              >
                <ListItemIcon
                  className={classes.itemIcon}
                  style={{
                    paddingLeft: 20,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: activeObject.costiPersonale
                        ? "#4fc3f7"
                        : "rgba(255, 255, 255, 0.7)",
                      height: 5,
                      width: 5,
                      borderRadius: "50%",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Personale"
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                />
              </ListItem>
            </Link>

            <Link
              onClick={handleToogleMobile}
              to={"/dashboard/costi-affitti-noleggi"}
              className={classes.link}
            >
              <ListItem
                button
                className={clsx(
                  classes.item,
                  activeObject.costiAffittiNoleggi && classes.itemActiveItem
                )}
              >
                <ListItemIcon
                  className={classes.itemIcon}
                  style={{
                    paddingLeft: 20,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: activeObject.costiAffittiNoleggi
                        ? "#4fc3f7"
                        : "rgba(255, 255, 255, 0.7)",
                      height: 5,
                      width: 5,
                      borderRadius: "50%",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Affitti e nolleggi"
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                />
              </ListItem>
            </Link>

            <Link
              onClick={handleToogleMobile}
              to={"/dashboard/costi-oneri-diversi-gestione"}
              className={classes.link}
            >
              <ListItem
                button
                className={clsx(
                  classes.item,
                  activeObject.costiOneriGestione && classes.itemActiveItem
                )}
              >
                <ListItemIcon
                  className={classes.itemIcon}
                  style={{
                    paddingLeft: 20,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: activeObject.costiOneriGestione
                        ? "#4fc3f7"
                        : "rgba(255, 255, 255, 0.7)",
                      height: 5,
                      width: 5,
                      borderRadius: "50%",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Oneri diversi di gestione"
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                />
              </ListItem>
            </Link>

            <Link
              onClick={handleToogleMobile}
              to={"/dashboard/costi-totali"}
              className={classes.link}
            >
              <ListItem
                button
                className={clsx(
                  classes.item,
                  activeObject.costiTotali && classes.itemActiveItem
                )}
              >
                <ListItemIcon
                  className={classes.itemIcon}
                  style={{
                    paddingLeft: 20,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: activeObject.costiTotali
                        ? "#4fc3f7"
                        : "rgba(255, 255, 255, 0.7)",
                      height: 5,
                      width: 5,
                      borderRadius: "50%",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Totale"
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                />
              </ListItem>
            </Link>
          </Collapse>
          {/* <Link
            onClick={handleToogleMobile}
            to="/dashboard/tributi"
            className={classes.link}
          >
            <ListItem
              button
              className={clsx(
                classes.item,
                activeObject.tributi && classes.itemActiveItem
              )}
            >
              <ListItemIcon className={classes.itemIcon}>
                <TrendingUp />
              </ListItemIcon>
              <ListItemText
                classes={{
                  primary: classes.itemPrimary,
                }}
              >
                Tributi
              </ListItemText>
            </ListItem>
          </Link> */}
          <Link
            onClick={handleToogleMobile}
            to="/dashboard/redditivita"
            className={classes.link}
          >
            <ListItem
              button
              className={clsx(
                classes.item,
                activeObject.redditivita && classes.itemActiveItem
              )}
            >
              <ListItemIcon className={classes.itemIcon}>
                <TrendingUp />
              </ListItemIcon>
              <ListItemText
                classes={{
                  primary: classes.itemPrimary,
                }}
              >
                Redditività
              </ListItemText>
            </ListItem>
          </Link>
          <ListItem
            button
            onClick={() => setOpenDocumenti(!openDocumenti)}
            className={clsx(classes.item, false && classes.itemActiveItem)}
          >
            <ListItemIcon className={classes.itemIcon}>
              <Description />
            </ListItemIcon>
            <ListItemText
              primary="Documenti"
              classes={{
                primary: classes.itemPrimary,
              }}
            />
            {openDocumenti ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openDocumenti}>
            <Link
              onClick={handleToogleMobile}
              to="/dashboard/documenti-2086"
              className={classes.link}
            >
              <ListItem
                button
                className={clsx(
                  classes.item,
                  activeObject.documenti2086 && classes.itemActiveItem
                )}
              >
                <ListItemIcon
                  className={classes.itemIcon}
                  style={{
                    paddingLeft: 20,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: activeObject.documenti2086
                        ? "#4fc3f7"
                        : "rgba(255, 255, 255, 0.7)",
                      height: 5,
                      width: 5,
                      borderRadius: "50%",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="art.2086 CC"
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                />

              </ListItem>
            </Link>
            <Link
              onClick={handleToogleMobile}
              to="/dashboard/documenti-bilanci"
              className={classes.link}
            >
              <ListItem
                button
                className={clsx(
                  classes.item,
                  activeObject.documentiBilanci && classes.itemActiveItem
                )}
              >
                <ListItemIcon
                  className={classes.itemIcon}
                  style={{
                    paddingLeft: 20,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: activeObject.documentiBilanci
                        ? "#4fc3f7"
                        : "rgba(255, 255, 255, 0.7)",
                      height: 5,
                      width: 5,
                      borderRadius: "50%",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Bilanci"
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                />

              </ListItem>
            </Link>
            <Link
              onClick={handleToogleMobile}
              to="/dashboard/documenti-231"
              className={classes.link}
            >
              <ListItem
                button
                className={clsx(
                  classes.item,
                  activeObject.documenti231 && classes.itemActiveItem
                )}
              >
                <ListItemIcon
                  className={classes.itemIcon}
                  style={{
                    paddingLeft: 20,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: activeObject.documenti231
                        ? "#4fc3f7"
                        : "rgba(255, 255, 255, 0.7)",
                      height: 5,
                      width: 5,
                      borderRadius: "50%",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Modello L.231"
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                />
              </ListItem>
            </Link>

            <ListItem
              button
              onClick={() => setOpenGDPR(!openGDPR)}
              className={clsx(classes.item, false && classes.itemActiveItem)}
            >
              <ListItemIcon
                className={classes.itemIcon}
                style={{
                  paddingLeft: 20,
                }}
              >
                <div
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.7)",
                    height: 5,
                    width: 5,
                    borderRadius: "50%",
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary="GDPR"
                classes={{
                  primary: classes.itemPrimary,
                }}
              />
              {openGDPR ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openGDPR}>
              <Link
                onClick={handleToogleMobile}
                to="/dashboard/documenti-gdpr-DataBreach"
                className={classes.link}
              >
                <ListItem
                  button
                  className={clsx(
                    classes.item,
                    activeObject.documentiGDPRDataBreach &&
                    classes.itemActiveItem
                  )}
                >
                  <ListItemIcon
                    className={classes.itemIcon}
                    style={{
                      paddingLeft: 40,
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: activeObject.documentiGDPRDataBreach
                          ? "#4fc3f7"
                          : "rgba(255, 255, 255, 0.7)",
                        height: 5,
                        width: 5,
                        borderRadius: "50%",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Data Breach"
                    classes={{
                      primary: classes.itemPrimary,
                    }}
                  />
                </ListItem>
              </Link>
              <Link
                onClick={handleToogleMobile}
                to="/dashboard/documenti-gdpr-Informativa"
                className={classes.link}
              >
                <ListItem
                  button
                  className={clsx(
                    classes.item,
                    activeObject.documentiGDPRInformativa &&
                    classes.itemActiveItem
                  )}
                >
                  <ListItemIcon
                    className={classes.itemIcon}
                    style={{
                      paddingLeft: 40,
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: activeObject.documentiGDPRInformativa
                          ? "#4fc3f7"
                          : "rgba(255, 255, 255, 0.7)",
                        height: 5,
                        width: 5,
                        borderRadius: "50%",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Informativa"
                    classes={{
                      primary: classes.itemPrimary,
                    }}
                  />
                </ListItem>
              </Link>
              <Link
                onClick={handleToogleMobile}
                to="/dashboard/documenti-gdpr-letteredinominaincaricatiinterni"
                className={classes.link}
              >
                <ListItem
                  button
                  className={clsx(
                    classes.item,
                    activeObject.documentiGDPRletteredinominaincaricatiinterni &&
                    classes.itemActiveItem
                  )}
                >
                  <ListItemIcon
                    className={classes.itemIcon}
                    style={{
                      paddingLeft: 40,
                    }}
                  >
                    <div
                      style={{
                        backgroundColor:
                          activeObject.documentiGDPRletteredinominaincaricatiinterni
                            ? "#4fc3f7"
                            : "rgba(255, 255, 255, 0.7)",
                        height: 5,
                        width: 5,
                        borderRadius: "50%",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Lettere di nomina incaricati interni"
                    classes={{
                      primary: classes.itemPrimary,
                    }}
                  />
                </ListItem>
              </Link>
              <Link
                onClick={handleToogleMobile}
                to="/dashboard/documenti-gdpr-Letteredincaricorespesterni"
                className={classes.link}
              >
                <ListItem
                  button
                  className={clsx(
                    classes.item,
                    activeObject.documentiGDPRLetteredincaricorespesterni &&
                    classes.itemActiveItem
                  )}
                >
                  <ListItemIcon
                    className={classes.itemIcon}
                    style={{
                      paddingLeft: 40,
                    }}
                  >
                    <div
                      style={{
                        backgroundColor:
                          activeObject.documentiGDPRLetteredincaricorespesterni
                            ? "#4fc3f7"
                            : "rgba(255, 255, 255, 0.7)",
                        height: 5,
                        width: 5,
                        borderRadius: "50%",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Lettere d'incarico resp. esterni"
                    classes={{
                      primary: classes.itemPrimary,
                    }}
                  />
                </ListItem>
              </Link>
              <Link
                onClick={handleToogleMobile}
                to="/dashboard/documenti-gdpr-ModelloOrganizzativoPrivacy"
                className={classes.link}
              >
                <ListItem
                  button
                  className={clsx(
                    classes.item,
                    activeObject.documentiGDPRModelloOrganizzativoPrivacy &&
                    classes.itemActiveItem
                  )}
                >
                  <ListItemIcon
                    className={classes.itemIcon}
                    style={{
                      paddingLeft: 40,
                    }}
                  >
                    <div
                      style={{
                        backgroundColor:
                          activeObject.documentiGDPRModelloOrganizzativoPrivacy
                            ? "#4fc3f7"
                            : "rgba(255, 255, 255, 0.7)",
                        height: 5,
                        width: 5,
                        borderRadius: "50%",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Modello organizzativo privacy"
                    classes={{
                      primary: classes.itemPrimary,
                    }}
                  />
                </ListItem>
              </Link>
              <Link
                onClick={handleToogleMobile}
                to="/dashboard/documenti-gdpr-Registrodeitrattamenti"
                className={classes.link}
              >
                <ListItem
                  button
                  className={clsx(
                    classes.item,
                    activeObject.documentiGDPRRegistrodeitrattamenti &&
                    classes.itemActiveItem
                  )}
                >
                  <ListItemIcon
                    className={classes.itemIcon}
                    style={{
                      paddingLeft: 40,
                    }}
                  >
                    <div
                      style={{
                        backgroundColor:
                          activeObject.documentiGDPRRegistrodeitrattamenti
                            ? "#4fc3f7"
                            : "rgba(255, 255, 255, 0.7)",
                        height: 5,
                        width: 5,
                        borderRadius: "50%",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Registro dei trattamenti"
                    classes={{
                      primary: classes.itemPrimary,
                    }}
                  />
                </ListItem>
              </Link>
              <Link
                onClick={handleToogleMobile}
                to="/dashboard/documenti-gdpr-Revocaconsenso"
                className={classes.link}
              >
                <ListItem
                  button
                  className={clsx(
                    classes.item,
                    activeObject.documentiGDPRRevocaconsenso &&
                    classes.itemActiveItem
                  )}
                >
                  <ListItemIcon
                    className={classes.itemIcon}
                    style={{
                      paddingLeft: 40,
                    }}
                  >
                    <div
                      style={{
                        backgroundColor:
                          activeObject.documentiGDPRRevocaconsenso
                            ? "#4fc3f7"
                            : "rgba(255, 255, 255, 0.7)",
                        height: 5,
                        width: 5,
                        borderRadius: "50%",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Revoca consenso"
                    classes={{
                      primary: classes.itemPrimary,
                    }}
                  />
                </ListItem>
              </Link>

              <Link
                onClick={handleToogleMobile}
                to="/dashboard/documenti-gdpr-AltriDocumenti"
                className={classes.link}
              >
                <ListItem
                  button
                  className={clsx(
                    classes.item,
                    activeObject.documentiGDPRAltriDocumenti &&
                    classes.itemActiveItem
                  )}
                >
                  <ListItemIcon
                    className={classes.itemIcon}
                    style={{
                      paddingLeft: 40,
                    }}
                  >
                    <div
                      style={{
                        backgroundColor:
                          activeObject.documentiGDPRAltriDocumenti
                            ? "#4fc3f7"
                            : "rgba(255, 255, 255, 0.7)",
                        height: 5,
                        width: 5,
                        borderRadius: "50%",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Altri Documenti"
                    classes={{
                      primary: classes.itemPrimary,
                    }}
                  />
                </ListItem>
              </Link>
            </Collapse>
            <Link
              onClick={handleToogleMobile}
              to="/dashboard/documenti-redditi"
              className={classes.link}
            >
              <ListItem
                button
                className={clsx(
                  classes.item,
                  activeObject.documentiRedditi && classes.itemActiveItem
                )}
              >
                <ListItemIcon
                  className={classes.itemIcon}
                  style={{
                    paddingLeft: 20,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: activeObject.documentiRedditi
                        ? "#4fc3f7"
                        : "rgba(255, 255, 255, 0.7)",
                      height: 5,
                      width: 5,
                      borderRadius: "50%",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Redditi"
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                />

              </ListItem>
            </Link>
            {/* <Link
              onClick={handleToogleMobile}
              to="/dashboard/documenti-gdpr-ModelloOrganizzativoPrivacy"
              className={classes.link}
            >
              <ListItem
                button
                className={clsx(
                  classes.item,
                  activeObject.documentiGDPRModelloOrganizzativoPrivacy &&
                    classes.itemActiveItem
                )}
              >
                <ListItemIcon
                  className={classes.itemIcon}
                  style={{
                    paddingLeft: 20,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: activeObject.documentiGDPRInformativa
                        ? "#4fc3f7"
                        : "rgba(255, 255, 255, 0.7)",
                      height: 5,
                      width: 5,
                      borderRadius: "50%",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Modello organizzativo privacy"
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                />
              </ListItem>
            </Link> */}
            {/* <Link
              onClick={handleToogleMobile}
              to="/dashboard/documenti-gdpr-AltriDoumenti"
              className={classes.link}
            >
              <ListItem
                button
                className={clsx(
                  classes.item,
                  activeObject.documentiGDPRModelloOrganizzativoPrivacy &&
                    classes.itemActiveItem
                )}
              >
                <ListItemIcon
                  className={classes.itemIcon}
                  style={{
                    paddingLeft: 20,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: activeObject.documentiGDPRAltriDocumenti
                        ? "#4fc3f7"
                        : "rgba(255, 255, 255, 0.7)",
                      height: 5,
                      width: 5,
                      borderRadius: "50%",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Altri Documenti"
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                />
              </ListItem>
            </Link> */}
          </Collapse>
          <Link
            onClick={handleToogleMobile}
            to="/dashboard/download"
            className={classes.link}
          >
            <ListItem
              button
              className={clsx(
                classes.item,
                activeObject.download && classes.itemActiveItem
              )}
            >
              <ListItemIcon className={classes.itemIcon}>
                <CloudDownload />
              </ListItemIcon>
              <ListItemText
                classes={{
                  primary: classes.itemPrimary,
                }}
              >
                Download
              </ListItemText>
            </ListItem>
          </Link>
        </div>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  loading: state.bilancio.loading,
  clienteSelezionato: state.auth.clienteSelezionato,
});

export default connect(mapStateToProps, {
  logout,
  updateClienteSelezionato,
  getCostiDellaProduzione,
  getValoreDellaProduzione,
  getDescrizioneConti,
  getProventiFinanziari,
  getOneriFinanziari,
  getRettifiche,
  getCancellaList,
  initState,
  getImposte,
  getTributi,
})(withRouter(MiniDrawer));
