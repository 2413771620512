import React, { Component } from "react";
import { connect } from "react-redux";

import { Redirect } from "react-router-dom";
import {
  getValoreDellaProduzione,
  getCostiDellaProduzione,
  getCreditiVersoSoci,
  getDisponibilitaLiquide,
  getImmobilizzazioniImmateriali,
  getImmobilizzazioniMateriali,
  getImmobilizzazioniFinanziarie,
  getRimanenze,
  getCrediti,
  getAttivitaFinanziarie,
  getRateiRiscontiAttivi,
  getPatrimonioNetto,
  getFondiRischiOneri,
  getTFR,
  getDebiti,
  getRateiRiscontiPassivi,
  getProventiFinanziari,
  getRivalutazioni,
  getProventiStraordinari,
  getOneriFinanziari,
  getSvalutazioni,
  getOneriTributari,
  getContoEconomico
} from "../../actions/bilancio";

class PrivateComponent extends Component {
  componentDidMount() {
    // this.props.getValoreDellaProduzione();
    // this.props.getCostiDellaProduzione();
    // this.props.getCreditiVersoSoci();
    // this.props.getDisponibilitaLiquide();
    // this.props.getImmobilizzazioniImmateriali();
    // this.props.getImmobilizzazioniMateriali();
    // this.props.getImmobilizzazioniFinanziarie();
    // this.props.getRimanenze();
    // this.props.getCrediti();
    // this.props.getAttivitaFinanziarie();
    // this.props.getRateiRiscontiAttivi();
    // this.props.getPatrimonioNetto();
    // this.props.getFondiRischiOneri();
    // this.props.getTFR();
    // this.props.getDebiti();
    // this.props.getRateiRiscontiPassivi();
    // this.props.getProventiFinanziari();
    // this.props.getRivalutazioni();
    // this.props.getProventiStraordinari();
    // this.props.getOneriFinanziari();
    // this.props.getSvalutazioni();
    // this.props.getOneriTributari();
    // this.props.getContoEconomico();
  }
  render() {
    if (this.props.user.username.length === 0) return <Redirect to="/" />;
    else return this.props.children;
  }
}

const mapStateToProps = state => ({
  user: state.auth.user
});

export default connect(mapStateToProps, {
  getValoreDellaProduzione,
  getCostiDellaProduzione,
  getCreditiVersoSoci,
  getDisponibilitaLiquide,
  getImmobilizzazioniImmateriali,
  getImmobilizzazioniMateriali,
  getImmobilizzazioniFinanziarie,
  getRimanenze,
  getCrediti,
  getAttivitaFinanziarie,
  getRateiRiscontiAttivi,
  getPatrimonioNetto,
  getFondiRischiOneri,
  getTFR,
  getDebiti,
  getRateiRiscontiPassivi,
  getProventiFinanziari,
  getRivalutazioni,
  getProventiStraordinari,
  getOneriFinanziari,
  getSvalutazioni,
  getOneriTributari,
  getContoEconomico
})(PrivateComponent);
