import React, { Component } from "react";
import { Paper, Typography, Grid, Button, TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

import { connect } from "react-redux";

import moment from "moment";

import { DatePicker } from "@material-ui/pickers";
import { getRettifiche, postRettifiche } from "../../../actions/bilancio";
import { ExpandMore } from "@material-ui/icons";

const styles = (theme) => ({
  paper: {
    margin: "auto",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(0),
    maxWidth: 850,
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
      padding: theme.spacing(3),
    },
  },
  textField: {
    maxWidth: "100%",
  },
  form: {
    marginTop: theme.spacing(2),
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  button: { margin: theme.spacing(1) },
});

class Rettifiche extends Component {
  state = {
    key: 0,
    data: moment().startOf("year"),
    ammortamenti: 0,
    TFR: 0,
    ratei: 0,
    inail: 0,
    tredicesima: 0,
    fatture_da_emettere: 0,
    fatture_da_ricevere: 0,
    riscontiAssicurazioni: 0,
    riscontiAffitti: 0,
    rimanenze: 0
  };

  componentDidMount() {
    this.props.getRettifiche(this.state.data.year());
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.rettifiche !== null)
      this.setState({
        ammortamenti: props.rettifiche.ammortamenti,
        TFR: props.rettifiche.accantonamento_tfr,
        ratei: props.rettifiche.ratei,
        inail: props.rettifiche.inail,
        tredicesima: props.rettifiche.accantonamento_13_14,
        fatture_da_emettere: props.rettifiche.fatture_da_emettere,
        fatture_da_ricevere: props.rettifiche.fatture_da_ricevere,
        riscontiAssicurazioni: props.rettifiche.risconti_assicurazioni,
        riscontiAffitti: props.rettifiche.risconti_affitti,
        rimanenze: props.rettifiche.rimanenze,
      });
  }
  formatDate = (date) => {
    return `${date.year()}`;
  };
  cambiaData = (data) => {
    this.setState({
      data: data.startOf("quarter"),
    });
    this.props.getRettifiche(data.year());
  };

  onSubmit = (e) => {
    e.preventDefault();

    this.props.postRettifiche({
      cliente: this.props.clienteSelezionato,
      anno: this.state.data.year(),
      ammortamenti: parseInt(this.state.ammortamenti) || 0,
      ratei: parseInt(this.state.ratei) || 0,
      inail: parseInt(this.state.inail) || 0,
      accantonamento_tfr: parseInt(this.state.TFR) || 0,
      accantonamento_13_14: parseInt(this.state.tredicesima) || 0,
      fatture_da_emettere: parseInt(this.state.fatture_da_emettere) || 0,
      fatture_da_ricevere: parseInt(this.state.fatture_da_ricevere) || 0,
      risconti_assicurazioni: parseInt(this.state.riscontiAssicurazioni) || 0,
      risconti_affitti: parseInt(this.state.riscontiAffitti) || 0,
      rimanenze: parseInt(this.state.rimanenze) || 0,
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <Paper className={classes.paper}>
        <div style={{ textAlign: "center" }}>
          <Typography
            component="span"
            variant="h5"
            align="center"
            style={{
              margin: "auto",
            }}
          >
            Rettifiche
          </Typography>
          <span style={{ margin: "auto" }}>
            <DatePicker
              name="data"
              value={this.state.data}
              labelFunc={this.formatDate}
              views={["year"]}
              cancelLabel="Chiudi"
              onChange={this.cambiaData}
              // inputVariant="outlined"
              minDate={moment().subtract(5, "year").startOf("year")}
              maxDate={moment().endOf("year")}
              style={{
                width: 80,
                marginLeft: 20,
                paddingBottom: 3,
                cursor: "pointer",
              }}
              InputProps={{
                endAdornment: <ExpandMore />,
                startAdornment: <div style={{ width: 20 }} />,
                style: { cursor: "pointer" },
              }}
            />
          </span>
        </div>
        <form noValidate className={classes.form} onSubmit={this.onSubmit}>
          <Grid container spacing={2}>
            <Grid container justify="center" style={{ padding: 20 }}>
              <Grid item xs={12} md={8} lg={7}>
                <TextField
                  variant="outlined"
                  label="Ammortamenti"
                  className={classes.textField}
                  margin="normal"
                  type="number"
                  name="ammortamenti"
                  value={this.state.ammortamenti}
                  onChange={this.onChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={8} lg={7}>
                <TextField
                  variant="outlined"
                  label="Accantonamento TFR"
                  className={classes.textField}
                  margin="normal"
                  type="number"
                  name="TFR"
                  value={this.state.TFR}
                  onChange={this.onChange}
                  fullWidth
                  size="small"
                />
              </Grid>

              <Grid item xs={12} md={8} lg={7}>
                <TextField
                  variant="outlined"
                  label="Ratei"
                  className={classes.textField}
                  margin="normal"
                  type="number"
                  name="ratei"
                  value={this.state.ratei}
                  onChange={this.onChange}
                  fullWidth
                  size="small"
                />
              </Grid>

              <Grid item xs={12} md={8} lg={7}>
                <TextField
                  variant="outlined"
                  label="INAIL"
                  className={classes.textField}
                  margin="normal"
                  type="number"
                  name="inail"
                  value={this.state.inail}
                  onChange={this.onChange}
                  fullWidth
                  size="small"
                />
              </Grid>

              <Grid item xs={12} md={8} lg={7}>
                <TextField
                  variant="outlined"
                  label="Accantonamento 13esima/14esima"
                  className={classes.textField}
                  margin="normal"
                  type="number"
                  name="tredicesima"
                  value={this.state.tredicesima}
                  onChange={this.onChange}
                  fullWidth
                  size="small"
                />
              </Grid>

              <Grid item xs={12} md={8} lg={7}>
                <TextField
                  variant="outlined"
                  label="Fatture da emettere"
                  className={classes.textField}
                  margin="normal"
                  type="number"
                  name="fatture_da_emettere"
                  value={this.state.fatture_da_emettere}
                  onChange={this.onChange}
                  fullWidth
                  size="small"
                />
              </Grid>

              <Grid item xs={12} md={8} lg={7}>
                <TextField
                  variant="outlined"
                  label="Fatture da ricevere"
                  className={classes.textField}
                  margin="normal"
                  type="number"
                  name="fatture_da_ricevere"
                  value={this.state.fatture_da_ricevere}
                  onChange={this.onChange}
                  fullWidth
                  size="small"
                />
              </Grid>

              <Grid item xs={12} md={8} lg={7}>
                <TextField
                  variant="outlined"
                  label="Risconti attivi, affitti"
                  className={classes.textField}
                  margin="normal"
                  type="number"
                  name="riscontiAffitti"
                  value={this.state.riscontiAffitti}
                  onChange={this.onChange}
                  fullWidth
                  size="small"
                />
              </Grid>

              <Grid item xs={12} md={8} lg={7}>
                <TextField
                  variant="outlined"
                  label="Risconti attivi, assicurazioni"
                  className={classes.textField}
                  margin="normal"
                  type="number"
                  name="riscontiAssicurazioni"
                  value={this.state.riscontiAssicurazioni}
                  onChange={this.onChange}
                  fullWidth
                  size="small"
                />
              </Grid>

              <Grid item xs={12} md={8} lg={7}>
                <TextField
                  variant="outlined"
                  label="Rimanenze"
                  className={classes.textField}
                  margin="normal"
                  type="number"
                  name="rimanenze"
                  value={this.state.rimanenze}
                  onChange={this.onChange}
                  fullWidth
                  size="small"
                />
              </Grid>


            </Grid>
            <div className={classes.buttonContainer}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                type="submit"
              >
                AGGIORNA DATI
              </Button>
            </div>
          </Grid>
        </form>
      </Paper>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  clienteSelezionato: state.auth.clienteSelezionato,
  rettifiche: state.bilancio.rettifiche,
});

export default connect(mapStateToProps, { getRettifiche, postRettifiche })(
  withStyles(styles)(Rettifiche)
);
