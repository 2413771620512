import React, { Component } from "react";

import { connect } from "react-redux";
import { Paper, Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { Bar } from "react-chartjs-2";

import moment from "moment";
import Loading from "../../spinners/Loading";

const colors = [
  "rgba(255, 117, 114, 0.8)",
  "rgba(131, 157, 255, 0.8)",
  "rgba(0, 196, 147, 0.8)",
  "rgba(251, 174, 49, 0.8)"
];

const styles = theme => ({
  paper: {
    padding: theme.spacing(3, 2),
    marginTop: 48
  },
  titolo: {
    marginTop: -45,
    background: "linear-gradient(40deg,#45cafc,#3269c2)",
    color: "white",
    padding: theme.spacing(2, 2),
    boxShadow: "0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15)",
    maxWidth: 405,
    borderRadius: 8
  },
  item: {
    height: "60vh"
  }
});

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          fontColor: "#9f9f9f",
          beginAtZero: true,
          callback: function(label, index, labels) {
            return Math.round(label / 1000).toLocaleString() + "k €";
          }
        }
      }
    ]
  },
  tooltips: {
    callbacks: {
      title: (tooltipItem, data) => {
        return ` ${tooltipItem[0].label}`;
      },
      label: (tooltipItem, data) => {
        const datasetIndex = tooltipItem.datasetIndex;
        const anno = data.datasets[datasetIndex].label;
        const valore = Math.round(tooltipItem.value);
        var stringaPercentuale = "";
        if (datasetIndex !== 0) {
          const valoreAnnoPrec =
            data.datasets[datasetIndex - 1].data[tooltipItem.index];
          const percentuale = Math.round(
            (100 * (valore - valoreAnnoPrec)) / Math.abs(valoreAnnoPrec)
          );

          if (percentuale >= 0) {
            stringaPercentuale = `(+${percentuale}%)`;
          } else {
            stringaPercentuale = `(${percentuale}%)`;
          }
        }

        return `${anno}: ${valore.toLocaleString()}€ ${stringaPercentuale}`;
      }
    }
  }
};

class UtileGestionale extends Component {
  render() {
    if (this.props.ricavi === null || this.props.costi === null) {
      return <Loading />;
    }
    const { classes, ricavi, costi } = this.props;

    var maxAnno = null;
    if (this.props.periodo === 1) {
      maxAnno = this.props.ricavi.reduce((max, curr) => {
        if (moment(curr.periodo).isAfter(max)) {
          return moment(curr.periodo);
        } else {
          return max;
        }
      }, moment(1970));
    }

    const ricaviMap =
      maxAnno === null
        ? ricavi.reduce((map, current) => {
            const data = moment(current.periodo);
            const anno = data.year();

            if (map.has(anno)) {
              map.set(anno, map.get(anno) + current.importo);
            } else {
              const importo = current.importo;
              map.set(anno, importo);
            }

            return map;
          }, new Map())
        : ricavi
            .filter(v => moment(v.periodo).year() === maxAnno.year())
            .reduce((map, current) => {
              const data = moment(current.periodo);
              const anno = data.year();

              if (map.has(anno)) {
                map.set(anno, map.get(anno) + current.importo);
              } else {
                const importo = current.importo;
                map.set(anno, importo);
              }

              return map;
            }, new Map());

    const costiMap =
      maxAnno === null
        ? costi.reduce((map, current) => {
            const data = moment(current.periodo);
            const anno = data.year();

            if (map.has(anno)) {
              map.set(anno, map.get(anno) + current.importo);
            } else {
              const importo = current.importo;
              map.set(anno, importo);
            }

            return map;
          }, new Map())
        : costi
            .filter(v => moment(v.periodo).year() === maxAnno.year())
            .reduce((map, current) => {
              const data = moment(current.periodo);
              const anno = data.year();

              if (map.has(anno)) {
                map.set(anno, map.get(anno) + current.importo);
              } else {
                const importo = current.importo;
                map.set(anno, importo);
              }

              return map;
            }, new Map());

    const datasetMap = new Map();

    ricaviMap.forEach((value, key) => {
      if (!datasetMap.has(key)) {
        var vettore = new Array(3).fill(0);
        vettore[0] = value;
        datasetMap.set(key, vettore);
      } else {
        var vettore = datasetMap.get(key);
        vettore[0] += value;
        datasetMap.set(key, vettore);
      }
    });

    costiMap.forEach((value, key) => {
      if (!datasetMap.has(key)) {
        var vettore = new Array(3).fill(0);
        vettore[1] = value;
        datasetMap.set(key, vettore);
      } else {
        var vettore = datasetMap.get(key);
        vettore[1] += value;
        datasetMap.set(key, vettore);
      }
    });

    var datasets = [];
    datasetMap.forEach((value, key) => {
      value[2] = value[0] - value[1];
      datasets.push([key, ...value]);
    });
    datasets.sort((a, b) => {
      return a[0] - b[0];
    });
    this.props.func(datasets);

    if (this.props.download) {
      return (
        <div style={{ width: 915, height: 400 }}>
          <Bar
            id="grafico-utile-gestionale"
            options={options}
            data={{
              labels: ["Ricavi", "Costi", "Utile/Perdita"],
              datasets: datasets.map((dataset, index) => {
                return {
                  label: dataset[0],
                  data: dataset.slice(-3).map(value => Math.round(value)),
                  backgroundColor: colors[index]
                };
              })
            }}
          />
        </div>
      );
    }

    return (
      <Paper className={classes.paper}>
        <Grid
          container
          spacing={2}
          align="center"
          alignItems="center"
          justify="center"
        >
          <Grid item xs={12}>
            <Typography className={classes.titolo} variant="h6">
              Utile gestionale
            </Typography>
          </Grid>
          <Grid item xs={12} md={11} className={classes.item}>
            <Bar
              id="grafico-utile-gestionale"
              options={options}
              data={{
                labels: ["Ricavi", "Costi", "Utile/Perdita"],
                datasets: datasets.map((dataset, index) => {
                  return {
                    label: dataset[0],
                    data: dataset.slice(-3).map(value => Math.round(value)),
                    backgroundColor: colors[index]
                  };
                })
              }}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

const mapStateToProps = state => ({
  ricavi: state.bilancio.ValoreDellaProduzione.categorie,
  costi:
    state.bilancio.CostiDellaProduzione.categorie === null
      ? null
      : state.bilancio.CostiDellaProduzione.categorie.filter(categoria => {
          if (
            categoria.descrizione === "Acquisti di materie prime e merci" ||
            categoria.descrizione === "Acquisizione di servizi" ||
            categoria.descrizione === "Godimento beni di terzi" ||
            categoria.descrizione === "Costi per il personale" ||
            categoria.descrizione === "Oneri diversi di gestione"
          ) {
            return true;
          } else {
            return false;
          }
        })
});

export default connect(mapStateToProps)(withStyles(styles)(UtileGestionale));
