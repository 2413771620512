import {
  GET_VALORE_DELLA_PRODUZIONE,
  GET_COSTI_DELLA_PRODUZIONE,
  GET_CREDITI_VERSO_SOCI,
  GET_DISPONIBILITA_LIQUIDE,
  GET_IMMOBILIZZAZIONI_IMMATERIALI,
  GET_IMMOBILIZZAZIONI_FINANZIARIE,
  GET_RIMANENZE,
  GET_CREDITI,
  GET_ATTIVITA_FINANZIARIE,
  GET_RATEI_RISCONTI_ATTIVI,
  GET_PATRIMONIO_NETTO,
  GET_FONDO_RISCHI_ONERI,
  GET_TFR,
  GET_DEBITI,
  GET_RATEI_RISCONTI_PASSIVI,
  GET_PROVENTI_FINANZIARI,
  GET_RIVALUTAZIONI,
  GET_PROVENTI_STRAORDINARI,
  GET_ONERI_FINANZIARI,
  GET_SVALUTAZIONI,
  GET_ONERI_TRIBUTARI,
  GET_CONTO_ECONOMICO,
  GET_IMMOBILIZZAZIONI_MATERIALI,
  GET_RETTIFICHE,
  DESCRIZIONE_CONTI,
  GET_CANCELLA_LIST,
  REMOVE_CANCELLA_LIST,
  GET_TRIBUTI,
  INIT_STATE,
  SET_EBIT,
  SET_EBITDA
} from "../actions/types";

const initialState = {
  ValoreDellaProduzione: { categorie: null, dettagli: null },
  CostiDellaProduzione: { categorie: null, dettagli: null },
  CreditiVersoSoci: { categorie: null, dettagli: null },
  DisponibilitaLiquide: { categorie: null, dettagli: null },
  ImmobilizzazioniImmateriali: { categorie: null, dettagli: null },
  ImmobilizzazioniMateriali: { categorie: null, dettagli: null },
  ImmobilizzazioniFinanziarie: { categorie: null, dettagli: null },
  Rimanenze: { categorie: null, dettagli: null },
  Crediti: { categorie: null, dettagli: null },
  AttivitaFinanziarie: { categorie: null, dettagli: null },
  RateiRiscontiAttivi: { categorie: null, dettagli: null },
  PatrimonioNetto: { categorie: null, dettagli: null },
  FondiRischiOneri: { categorie: null, dettagli: null },
  TFR: { categorie: null, dettagli: null },
  Debiti: { categorie: null, dettagli: null },
  RateiRiscontiPassivi: { categorie: null, dettagli: null },
  ProventiFinanziari: { categorie: null, dettagli: null },
  Rivalutazioni: { categorie: null, dettagli: null },
  ProventiStraordinari: { categorie: null, dettagli: null },
  OneriFinanziari: { categorie: null, dettagli: null },
  Svalutazioni: { categorie: null, dettagli: null },
  OneriTributari: { categorie: null, dettagli: null },
  ContoEconomico: { categorie: null, dettagli: null },
  cancellaList: [],
  tributi: null,
  rettifiche: null,
  descrizioneConti: null,
  EBIT: 0,
  EBITDA: 0
};

export default function(state = initialState, action) {
  switch (action.type) {
    case INIT_STATE:
      return initialState;

    case DESCRIZIONE_CONTI:
      return { ...state, descrizioneConti: action.payload };

    case GET_VALORE_DELLA_PRODUZIONE:
      return {
        ...state,
        ValoreDellaProduzione: action.payload
      };

    case GET_COSTI_DELLA_PRODUZIONE:
      return {
        ...state,
        CostiDellaProduzione: action.payload
      };

    case GET_CREDITI_VERSO_SOCI:
      return {
        ...state,
        CreditiVersoSoci: action.payload
      };

    case GET_DISPONIBILITA_LIQUIDE:
      return {
        ...state,
        DisponibilitaLiquide: action.payload
      };

    case GET_IMMOBILIZZAZIONI_IMMATERIALI:
      return {
        ...state,
        ImmobilizzazioniImmateriali: action.payload
      };

    case GET_IMMOBILIZZAZIONI_MATERIALI:
      return {
        ...state,
        ImmobilizzazioniMateriali: action.payload
      };

    case GET_IMMOBILIZZAZIONI_FINANZIARIE:
      return {
        ...state,
        ImmobilizzazioniFinanziarie: action.payload
      };

    case GET_RIMANENZE:
      return {
        ...state,
        Rimanenze: action.payload
      };

    case GET_CREDITI:
      return {
        ...state,
        Crediti: action.payload
      };

    case GET_ATTIVITA_FINANZIARIE:
      return {
        ...state,
        AttivitaFinanziarie: action.payload
      };

    case GET_RATEI_RISCONTI_ATTIVI:
      return {
        ...state,
        RateiRiscontiAttivi: action.payload
      };

    case GET_PATRIMONIO_NETTO:
      return {
        ...state,
        PatrimonioNetto: action.payload
      };

    case GET_FONDO_RISCHI_ONERI:
      return {
        ...state,
        FondiRischiOneri: action.payload
      };

    case GET_TFR:
      return {
        ...state,
        TFR: action.payload
      };

    case GET_DEBITI:
      return {
        ...state,
        Debiti: action.payload
      };

    case GET_RATEI_RISCONTI_PASSIVI:
      return {
        ...state,
        RateiRiscontiPassivi: action.payload
      };

    case GET_PROVENTI_FINANZIARI:
      return {
        ...state,
        ProventiFinanziari: action.payload
      };

    case GET_RIVALUTAZIONI:
      return {
        ...state,
        Rivalutazioni: action.payload
      };

    case GET_PROVENTI_STRAORDINARI:
      return {
        ...state,
        ProventiStraordinari: action.payload
      };

    case GET_ONERI_FINANZIARI:
      return {
        ...state,
        OneriFinanziari: action.payload
      };

    case GET_SVALUTAZIONI:
      return {
        ...state,
        Svalutazioni: action.payload
      };

    case GET_ONERI_TRIBUTARI:
      return {
        ...state,
        OneriTributari: action.payload
      };

    case GET_CONTO_ECONOMICO:
      return {
        ...state,
        ContoEconomico: action.payload
      };

    case GET_RETTIFICHE:
      return {
        ...state,
        rettifiche: action.payload
      };

    case GET_CANCELLA_LIST:
      return {
        ...state,
        cancellaList: action.payload
      };

    case GET_TRIBUTI:
      return {
        ...state,
        tributi: action.payload
      };

    case REMOVE_CANCELLA_LIST:
      return {
        ...state,
        cancellaList: state.cancellaList.filter(v => v !== action.payload)
      };

    case SET_EBIT:
      return {
        ...state,
        EBIT: action.payload
      };
    case SET_EBITDA:
      return {
        ...state,
        EBITDA: action.payload
      };
    default:
      return state;
  }
}
