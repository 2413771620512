import React, { Component } from "react";
import ReactDOM from "react-dom";

import { withStyles } from "@material-ui/styles";
import {
  Paper,
  Grid,
  Typography,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormGroup,
  Checkbox,
  Divider,
  Button,
  Select,
  MenuItem,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead
} from "@material-ui/core";
import ErrorAlert from "../../alerts/ErrorAlert";
import { PDFDocument } from 'pdf-lib'
import jsPDF from "jspdf";
import "jspdf-autotable";
import { connect } from "react-redux";
import Loading from "../../spinners/Loading";
import Template from "../../../media/images/template_pdf.png";
import moment from "moment";
import { DockTwoTone } from "@material-ui/icons";
import axios from "axios";
import { API_URL } from "../../../actions/types";
import { getToken } from "../../../actions/auth";

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(3, 2),
    marginTop: 48,
    marginBottom: 40,
  },
  titolo: {
    marginTop: -45,
    background: "linear-gradient(40deg,#45cafc,#3269c2)",
    color: "white",
    padding: theme.spacing(2, 2),
    boxShadow: "0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15)",
    maxWidth: 405,
    borderRadius: 8,
  },
  item: {
    // height: "60vh"
  },
  tableBody: {
    border: "1px solid rgba(224, 224, 224, 1)",
  },
  cell: {
    border: "1px solid rgba(224, 224, 224, 1)",
  },
  formControl: {
    marginTop: 20,
  },
  button: {
    marginTop: 20,
    marginBottom: 20,
  },
});

class Download extends Component {
  state = {
    loading: false,
    selezione: {
      utileGestionale: false,
      ricavi: false,
      costiMateriePrime: false,
      costiServizi: false,
      costiPersonale: false,
      costiAffitti: false,
      costiOneri: false,
      costiTotali: false,
      redditivita: false,
    },
    utileGestionale: [],
    listaDocumenti: [],
    listaDocumentiDaScaricare: [],
    periodo: 0,
  };

  getDocumneti = () => {
    axios.get(API_URL + "documenti/", {
      ...getToken(),
      params: {
        cliente: this.props.cliente
      }
    }).then(request => {
      this.setState({
        listaDocumenti: request.data
      })
    })
  }

  componentDidMount = () => {
    this.getDocumneti()
  }

  // UTILE GESTIONALE
  getDatasetsUtileGestionale = (datasets) => {
    this.setState({
      utileGestionale: datasets,
    });
  };

  // RICAVI
  getTrimestriRicavi = (ricavi) => {
    this.setState({
      ricaviTrimestrali: ricavi,
    });
  };

  getDettagliRicavi = (ricavi) => {
    this.setState({
      dettagliRicavi: ricavi,
    });
  };

  // COSTI MATERIE PRIME E MERCI
  getTrimestriCostiMateriePrime = (costi) => {
    this.setState({
      costiMateriePrimeTrimestrali: costi,
    });
  };

  getDettagliCostiMateriePrime = (costi) => {
    this.setState({
      dettagliCostiMateriePrime: costi,
    });
  };

  // COSTI SERVIZI
  getTrimestriCostiServizi = (costi) => {
    this.setState({
      costiServiziTrimestrali: costi,
    });
  };

  getDettagliCostiServizi = (costi) => {
    this.setState({
      dettagliCostiServizi: costi,
    });
  };

  // COSTI PERSONALE
  getTrimestriCostiPersonale = (costi) => {
    this.setState({
      costiPersonaleTrimestrali: costi,
    });
  };

  getDettagliCostiPersonale = (costi) => {
    this.setState({
      dettagliCostiPersonale: costi,
    });
  };

  // COSTI AFFITTI E NOLEGGI
  getTrimestriCostiAffitti = (costi) => {
    this.setState({
      costiAffittiTrimestrali: costi,
    });
  };

  getDettagliCostiAffitti = (costi) => {
    this.setState({
      dettagliCostiAffitti: costi,
    });
  };

  // COSTI ONERI DIVERSI DI GESTIONE
  getTrimestriCostiOneriGestione = (costi) => {
    this.setState({
      costiOneriGestioneTrimestrali: costi,
    });
  };

  getDettagliCostiOneriGestione = (costi) => {
    this.setState({
      dettagliCostiOneriGestione: costi,
    });
  };

  // COSTI TOTALI
  getTrimestriCostiTotali = (costi) => {
    this.setState({
      costiTotaliTrimestrali: costi,
    });
  };

  getDettagliCostiTotali = (costi) => {
    this.setState({
      dettagliCostiTotali: costi,
    });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    const vettoreSelezione = Object.values(this.state.selezione);

    const selezione = vettoreSelezione.reduce((bool, curr) => {
      if (bool || curr) return true;
      else return bool;
    }, false);

    if (!selezione && this.state.listaDocumentiDaScaricare.length === 0) {
      ErrorAlert("Seleziona almeno una voce");
      this.setState({
        loading: false,
      });
      return;
    }
    const {
      utileGestionale,
      ricavi,
      costiMateriePrime,
      costiServizi,
      costiPersonale,
      costiAffitti,
      costiOneri,
      costiTotali,
      redditivita,
    } = this.state.selezione;

    var doc = new jsPDF();
    const center = doc.internal.pageSize.width / 2;

    var template = new Image();
    template.src = Template;

    switch (window.location.hostname) {
      case "sevat.piattaforma-copernico.com":
      case "sevat.piattaforma-copernico.it":
        var logo = new Image();
        logo.src = "/logo-pdf.png";
        doc.addImage(
          template,
          "PNG",
          0,
          0,
          doc.internal.pageSize.width,
          doc.internal.pageSize.height
        );
        doc.setFontSize(12);
        doc.addImage(logo, "PNG", 15, 15, 87, 17.85);

        break;

      default:
        var logo = new Image();
        logo.src = "/logo.png";
        doc.addImage(
          template,
          "PNG",
          0,
          0,
          doc.internal.pageSize.width,
          doc.internal.pageSize.height
        );
        doc.setFontSize(12);
        doc.addImage(logo, "PNG", 15, 15, 40, 40);
        break;
    }

    doc.text(
      window.location.hostname,
      doc.internal.pageSize.width - 10,
      doc.internal.pageSize.height - 13,
      "right"
    );
    doc.setFontSize(8);
    doc.text(
      "Software Copernico registrato in data 09/04/2019 in SIAE con n.013394 a nome GDP Analytics srl",
      doc.internal.pageSize.width - 10,
      doc.internal.pageSize.height - 8,
      "right"
    );
    doc.addPage();
    doc.setFontSize(20);

    if (utileGestionale) {
      doc.text("Utile Gestionale", center - 20, 20);

      doc.autoTable({
        startY: 42,
        head: [["Anno", "Ricavi (€)", "Costi (€)", "Utile gestionale (€)"]],
        body: this.state.utileGestionale.map((value) => {
          return value.map((v, index) => {
            if (index > 0) return Math.round(v).toLocaleString();
            else return v;
          });
        }),
        styles: { halign: "center" },
      });

      doc.addImage(
        document.getElementById("grafico-utile-gestionale").toDataURL(),
        "PNG",
        13,
        doc.internal.pageSize.height / 2 -
        doc.internal.pageSize.height / 8 -
        20,
        document.getElementById("grafico-ricavi").offsetWidth / 5,
        document.getElementById("grafico-ricavi").offsetHeight / 5
      );

      doc.addPage();
    }

    if (ricavi) {
      doc.text("Ricavi", center - 10, 20);

      doc.autoTable({
        startY: 42,
        head: [
          [
            "Trimestre",
            ...this.state.ricaviTrimestrali.map((anno) => anno.label + " (€)"),
          ],
        ],
        body: [
          [
            1,
            ...this.state.ricaviTrimestrali.map((anno) =>
              Math.round(anno.data[0]).toLocaleString()
            ),
          ],
          [
            2,
            ...this.state.ricaviTrimestrali.map((anno) =>
              Math.round(anno.data[1]).toLocaleString()
            ),
          ],
          [
            3,
            ...this.state.ricaviTrimestrali.map((anno) =>
              Math.round(anno.data[2]).toLocaleString()
            ),
          ],
          [
            4,
            ...this.state.ricaviTrimestrali.map((anno) =>
              Math.round(anno.data[3]).toLocaleString()
            ),
          ],
          [
            "Totale",
            ...this.state.ricaviTrimestrali.map((anno) => {
              return Math.round(
                anno.data.reduce((totale, val) => totale + val, 0)
              ).toLocaleString();
            }),
          ],
        ],
        styles: { halign: "center" },
      });

      doc.addImage(
        document.getElementById("grafico-ricavi").toDataURL(),
        "PNG",
        13,
        doc.internal.pageSize.height / 2 -
        doc.internal.pageSize.height / 8 -
        20,
        document.getElementById("grafico-ricavi").offsetWidth / 5,
        document.getElementById("grafico-ricavi").offsetHeight / 5
      );

      doc.autoTable({
        startY: 175,
        head: [
          [
            "Anno",
            ...this.state.ricaviTrimestrali.map((anno) => anno.label + " (€)"),
          ],
        ],
        body: this.state.dettagliRicavi.map((value) => {
          return value.map((v, index) => {
            if (index > 0) return Math.round(v).toLocaleString();
            else return v;
          });
        }),
        styles: { halign: "center" },
        columnStyles: {
          0: { halign: "left" },
        },
      });

      doc.addPage();
    }

    if (costiMateriePrime) {
      doc.text("Costi materie prime e merci", center - 33, 20);

      doc.autoTable({
        startY: 42,
        head: [
          [
            "Trimestre",
            ...this.state.costiMateriePrimeTrimestrali.map(
              (anno) => anno.label + " (€)"
            ),
          ],
        ],
        body: [
          [
            1,
            ...this.state.costiMateriePrimeTrimestrali.map((anno) =>
              Math.round(anno.data[0]).toLocaleString()
            ),
          ],
          [
            2,
            ...this.state.costiMateriePrimeTrimestrali.map((anno) =>
              Math.round(anno.data[1]).toLocaleString()
            ),
          ],
          [
            3,
            ...this.state.costiMateriePrimeTrimestrali.map((anno) =>
              Math.round(anno.data[2]).toLocaleString()
            ),
          ],
          [
            4,
            ...this.state.costiMateriePrimeTrimestrali.map((anno) =>
              Math.round(anno.data[3]).toLocaleString()
            ),
          ],
          [
            "Totale",
            ...this.state.costiMateriePrimeTrimestrali.map((anno) => {
              return Math.round(
                anno.data.reduce((totale, val) => totale + val, 0)
              ).toLocaleString();
            }),
          ],
        ],
        styles: { halign: "center" },
      });

      doc.addImage(
        document.getElementById("grafico-costi-materie-prime").toDataURL(),
        "PNG",
        13,
        doc.internal.pageSize.height / 2 -
        doc.internal.pageSize.height / 8 -
        20,
        document.getElementById("grafico-ricavi").offsetWidth / 5,
        document.getElementById("grafico-ricavi").offsetHeight / 5
      );

      doc.autoTable({
        startY: 175,
        head: [
          [
            "Anno",
            ...this.state.costiMateriePrimeTrimestrali.map(
              (anno) => anno.label + " (€)"
            ),
          ],
        ],
        body: this.state.dettagliCostiMateriePrime.map((value) => {
          return value.map((v, index) => {
            if (index > 0) return Math.round(v).toLocaleString();
            else return v;
          });
        }),
        styles: { halign: "center" },
        columnStyles: {
          0: { halign: "left" },
        },
      });

      doc.addPage();
    }

    if (costiServizi) {
      doc.text("Costi servizi", center - 16, 20);

      doc.autoTable({
        startY: 42,
        head: [
          [
            "Trimestre",
            ...this.state.costiServiziTrimestrali.map(
              (anno) => anno.label + " (€)"
            ),
          ],
        ],
        body: [
          [
            1,
            ...this.state.costiServiziTrimestrali.map((anno) =>
              Math.round(anno.data[0]).toLocaleString()
            ),
          ],
          [
            2,
            ...this.state.costiServiziTrimestrali.map((anno) =>
              Math.round(anno.data[1]).toLocaleString()
            ),
          ],
          [
            3,
            ...this.state.costiServiziTrimestrali.map((anno) =>
              Math.round(anno.data[2]).toLocaleString()
            ),
          ],
          [
            4,
            ...this.state.costiServiziTrimestrali.map((anno) =>
              Math.round(anno.data[3]).toLocaleString()
            ),
          ],
          [
            "Totale",
            ...this.state.costiServiziTrimestrali.map((anno) => {
              return Math.round(
                anno.data.reduce((totale, val) => totale + val, 0)
              ).toLocaleString();
            }),
          ],
        ],
        styles: { halign: "center" },
      });

      doc.addImage(
        document.getElementById("grafico-costi-servizi").toDataURL(),
        "PNG",
        13,
        doc.internal.pageSize.height / 2 -
        doc.internal.pageSize.height / 8 -
        20,
        document.getElementById("grafico-ricavi").offsetWidth / 5,
        document.getElementById("grafico-ricavi").offsetHeight / 5
      );

      doc.autoTable({
        startY: 175,
        head: [
          [
            "Anno",
            ...this.state.costiServiziTrimestrali.map(
              (anno) => anno.label + " (€)"
            ),
          ],
        ],
        body: this.state.dettagliCostiServizi.map((value) => {
          return value.map((v, index) => {
            if (index > 0) return Math.round(v).toLocaleString();
            else return v;
          });
        }),
        styles: { halign: "center" },
        columnStyles: {
          0: { halign: "left" },
        },
      });

      doc.addPage();
    }

    if (costiPersonale) {
      doc.text("Costi personale", center - 20, 20);

      doc.autoTable({
        startY: 42,
        head: [
          [
            "Trimestre",
            ...this.state.costiPersonaleTrimestrali.map(
              (anno) => anno.label + " (€)"
            ),
          ],
        ],
        body: [
          [
            1,
            ...this.state.costiPersonaleTrimestrali.map((anno) =>
              Math.round(anno.data[0]).toLocaleString()
            ),
          ],
          [
            2,
            ...this.state.costiPersonaleTrimestrali.map((anno) =>
              Math.round(anno.data[1]).toLocaleString()
            ),
          ],
          [
            3,
            ...this.state.costiPersonaleTrimestrali.map((anno) =>
              Math.round(anno.data[2]).toLocaleString()
            ),
          ],
          [
            4,
            ...this.state.costiPersonaleTrimestrali.map((anno) =>
              Math.round(anno.data[3]).toLocaleString()
            ),
          ],
          [
            "Totale",
            ...this.state.costiPersonaleTrimestrali.map((anno) => {
              return Math.round(
                anno.data.reduce((totale, val) => totale + val, 0)
              ).toLocaleString();
            }),
          ],
        ],
        styles: { halign: "center" },
      });

      doc.addImage(
        document.getElementById("grafico-costi-personale").toDataURL(),
        "PNG",
        13,
        doc.internal.pageSize.height / 2 -
        doc.internal.pageSize.height / 8 -
        20,
        document.getElementById("grafico-ricavi").offsetWidth / 5,
        document.getElementById("grafico-ricavi").offsetHeight / 5
      );

      doc.autoTable({
        startY: 175,
        head: [
          [
            "Anno",
            ...this.state.costiPersonaleTrimestrali.map(
              (anno) => anno.label + " (€)"
            ),
          ],
        ],
        body: this.state.dettagliCostiPersonale.map((value) => {
          return value.map((v, index) => {
            if (index > 0) return Math.round(v).toLocaleString();
            else return v;
          });
        }),
        styles: { halign: "center" },
        columnStyles: {
          0: { halign: "left" },
        },
      });

      doc.addPage();
    }

    if (costiAffitti) {
      doc.text("Costi affitti e noleggi", center - 26, 20);

      doc.autoTable({
        startY: 42,
        head: [
          [
            "Trimestre",
            ...this.state.costiAffittiTrimestrali.map(
              (anno) => anno.label + " (€)"
            ),
          ],
        ],
        body: [
          [
            1,
            ...this.state.costiAffittiTrimestrali.map((anno) =>
              Math.round(anno.data[0]).toLocaleString()
            ),
          ],
          [
            2,
            ...this.state.costiAffittiTrimestrali.map((anno) =>
              Math.round(anno.data[1]).toLocaleString()
            ),
          ],
          [
            3,
            ...this.state.costiAffittiTrimestrali.map((anno) =>
              Math.round(anno.data[2]).toLocaleString()
            ),
          ],
          [
            4,
            ...this.state.costiAffittiTrimestrali.map((anno) =>
              Math.round(anno.data[3]).toLocaleString()
            ),
          ],
          [
            "Totale",
            ...this.state.costiAffittiTrimestrali.map((anno) => {
              return Math.round(
                anno.data.reduce((totale, val) => totale + val, 0)
              ).toLocaleString();
            }),
          ],
        ],
        styles: { halign: "center" },
      });

      doc.addImage(
        document.getElementById("grafico-costi-affitti").toDataURL(),
        "PNG",
        13,
        doc.internal.pageSize.height / 2 -
        doc.internal.pageSize.height / 8 -
        20,
        doc.internal.pageSize.width - 30,
        doc.internal.pageSize.height / 4
      );

      doc.autoTable({
        startY: (doc.internal.pageSize.height * 3) / 5.1,
        head: [
          [
            "Anno",
            ...this.state.costiAffittiTrimestrali.map(
              (anno) => anno.label + " (€)"
            ),
          ],
        ],
        body: this.state.dettagliCostiAffitti.map((value) => {
          return value.map((v, index) => {
            if (index > 0) return Math.round(v).toLocaleString();
            else return v;
          });
        }),
        styles: { halign: "center" },
        columnStyles: {
          0: { halign: "left" },
        },
      });

      doc.addPage();
    }

    if (costiOneri) {
      doc.text("Costi oneri diversi di gestione", center - 33, 20);

      doc.autoTable({
        startY: 42,
        head: [
          [
            "Trimestre",
            ...this.state.costiOneriGestioneTrimestrali.map(
              (anno) => anno.label + " (€)"
            ),
          ],
        ],
        body: [
          [
            1,
            ...this.state.costiOneriGestioneTrimestrali.map((anno) =>
              Math.round(anno.data[0]).toLocaleString()
            ),
          ],
          [
            2,
            ...this.state.costiOneriGestioneTrimestrali.map((anno) =>
              Math.round(anno.data[1]).toLocaleString()
            ),
          ],
          [
            3,
            ...this.state.costiOneriGestioneTrimestrali.map((anno) =>
              Math.round(anno.data[2]).toLocaleString()
            ),
          ],
          [
            4,
            ...this.state.costiOneriGestioneTrimestrali.map((anno) =>
              Math.round(anno.data[3]).toLocaleString()
            ),
          ],
          [
            "Totale",
            ...this.state.costiOneriGestioneTrimestrali.map((anno) => {
              return Math.round(
                anno.data.reduce((totale, val) => totale + val, 0)
              ).toLocaleString();
            }),
          ],
        ],
        styles: { halign: "center" },
      });

      doc.addImage(
        document.getElementById("grafico-costi-oneri-gestione").toDataURL(),
        "PNG",
        13,
        doc.internal.pageSize.height / 2 -
        doc.internal.pageSize.height / 8 -
        20,
        document.getElementById("grafico-ricavi").offsetWidth / 5,
        document.getElementById("grafico-ricavi").offsetHeight / 5
      );

      doc.autoTable({
        startY: 175,
        head: [
          [
            "Anno",
            ...this.state.costiOneriGestioneTrimestrali.map(
              (anno) => anno.label + " (€)"
            ),
          ],
        ],
        body: this.state.dettagliCostiOneriGestione.map((value) => {
          return value.map((v, index) => {
            if (index > 0) return Math.round(v).toLocaleString();
            else return v;
          });
        }),
        styles: { halign: "center" },
        columnStyles: {
          0: { halign: "left" },
        },
      });

      doc.addPage();
    }

    if (costiTotali) {
      doc.text("Costi totali", center - 18, 20);

      doc.autoTable({
        startY: 42,
        head: [
          [
            "Trimestre",
            ...this.state.costiTotaliTrimestrali.map(
              (anno) => anno.label + " (€)"
            ),
          ],
        ],
        body: [
          [
            1,
            ...this.state.costiTotaliTrimestrali.map((anno) =>
              Math.round(anno.data[0]).toLocaleString()
            ),
          ],
          [
            2,
            ...this.state.costiTotaliTrimestrali.map((anno) =>
              Math.round(anno.data[1]).toLocaleString()
            ),
          ],
          [
            3,
            ...this.state.costiTotaliTrimestrali.map((anno) =>
              Math.round(anno.data[2]).toLocaleString()
            ),
          ],
          [
            4,
            ...this.state.costiTotaliTrimestrali.map((anno) =>
              Math.round(anno.data[3]).toLocaleString()
            ),
          ],
          [
            "Totale",
            ...this.state.costiTotaliTrimestrali.map((anno) => {
              return Math.round(
                anno.data.reduce((totale, val) => totale + val, 0)
              ).toLocaleString();
            }),
          ],
        ],
        styles: { halign: "center" },
      });

      doc.addImage(
        document.getElementById("grafico-costi-totali").toDataURL(),
        "PNG",
        13,
        doc.internal.pageSize.height / 2 -
        doc.internal.pageSize.height / 8 -
        20,
        document.getElementById("grafico-ricavi").offsetWidth / 5,
        document.getElementById("grafico-ricavi").offsetHeight / 5
      );

      doc.autoTable({
        startY: 175,
        head: [
          [
            "Anno",
            ...this.state.costiTotaliTrimestrali.map(
              (anno) => anno.label + " (€)"
            ),
          ],
        ],
        body: this.state.dettagliCostiTotali.map((value) => {
          return value.map((v, index) => {
            if (index > 0) return Math.round(v).toLocaleString();
            else return v;
          });
        }),
        styles: { halign: "center" },
        columnStyles: {
          0: { halign: "left" },
        },
      });

      doc.addPage();
    }

    if (redditivita) {
      doc.text("Redditività", center - 14, 20);
      // It can parse html:
      doc.autoTable({
        startY: 42,
        html: "#tabella-redditivita",
        styles: { halign: "center" },
        columnStyles: {
          0: { halign: "left" },
        },
      });

      const maxAnno = Math.max(
        Math.max(
          ...this.props.valoreDellProduzione.dettagli.map((v) => {
            return moment(v.periodo).year();
          })
        ),
        Math.max(
          ...this.props.costiDellProduzione.dettagli.map((v) => {
            return moment(v.periodo).year();
          })
        )
      );

      const ricavi = this.props.valoreDellProduzione.dettagli.reduce(
        (total, curr) => {
          if (maxAnno == moment(curr.periodo).year())
            return total + curr.importo;
          else return total;
        },
        0
      );

      const costi =
        this.props.costiDellProduzione.dettagli.reduce((total, curr) => {
          if (maxAnno == moment(curr.periodo).year())
            return total + curr.importo;
          else return total;
        }, 0) +
        this.props.rettifiche.ammortamenti +
        this.props.rettifiche.accantonamento_13_14 +
        this.props.rettifiche.accantonamento_tfr;
      const rol = ricavi - costi;
      console.log(ricavi, costi);
      doc.setFontSize(16);
      doc.text("Indici economici", 15, doc.internal.pageSize.height - 85);
      doc.setFontSize(12);
      doc.text(
        `ROL (Reddito Operativo Lordo) = ${Math.round(rol).toLocaleString()} €`,
        15,
        doc.internal.pageSize.height - 75
      );
      doc.text(
        `EBITDA (Earning Before Interest Taxes Depreciation and Amortization) = ${Math.round(
          this.props.EBITDA
        ).toLocaleString()} €`,
        15,
        doc.internal.pageSize.height - 61
      );
      doc.text(
        `EBIT (Earning Before Interest and Taxes) = ${Math.round(
          this.props.EBIT
        ).toLocaleString()} €`,
        15,
        doc.internal.pageSize.height - 68
      );

      doc.addPage();
    }

    doc.deletePage(doc.internal.getNumberOfPages());

    doc.setFontSize(12);
    var pdf_pages = doc.internal.pages;
    var myFooter = this.props.cliente;
    for (var i = 2; i < pdf_pages.length; i++) {
      doc.setPage(i);
      doc.text(myFooter, 10, 8);
    }
    // doc.save("download.pdf");

    function saveByteArray(reportName, byte) {
      var blob = new Blob([byte], { type: "application/pdf" });
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      var fileName = reportName;
      link.download = fileName;
      link.click();
    };

    const pdfDoc = await PDFDocument.load(doc.output('arraybuffer'))

    for (var i = 0; i < this.state.listaDocumentiDaScaricare.length; i++) {
      const documentoID = this.state.listaDocumentiDaScaricare[i]
      const request = await axios.get(API_URL + `documenti/${documentoID}`, {
        ...getToken(),
        params: {
          cliente: this.props.cliente
        }
      })
      var pdf = await PDFDocument.load(request.data.file)
      var pages = await pdfDoc.copyPages(pdf, pdf.getPageIndices())
      pages.forEach((page) => pdfDoc.addPage(page));
    }

    saveByteArray("download.pdf", await pdfDoc.save())
    this.setState({
      loading: false,
    });
  };

  render() {
    console.log(this.state.listaDocumentiDaScaricare)
    const { classes } = this.props;
    const {
      utileGestionale,
      ricavi,
      costiMateriePrime,
      costiServizi,
      costiPersonale,
      costiAffitti,
      costiOneri,
      costiTotali,
      redditivita,
    } = this.state.selezione;


    return (
      <div >
        {this.state.loading ? (
          <div
            style={{
              position: "fixed",
              width: "100vw",
              height: "100vh",
              top: 0,
              bottom: 0,
              left: 0,
              zIndex: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(0, 0, 0, 0.4)",
            }}
          >
            <Loading />
          </div>
        ) : (
          ""
        )}

        <Paper className={classes.paper} style={{ overflow: 'none' }}>
          <Grid
            container
            spacing={2}
            align="center"
            alignItems="center"
            justify="center"
          >
            <Grid item xs={12}>
              <Typography className={classes.titolo} variant="h6">
                Download
              </Typography>
            </Grid>
            <form onSubmit={this.onSubmit}>
              <Grid item xs={8}>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                  align="start"
                  style={{
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                >
                  <FormLabel component="legend">
                    <Typography variant="h6">Seleziona il periodo</Typography>

                    <Select
                      fullWidth
                      value={this.state.periodo}
                      onChange={(e) =>
                        this.setState({ periodo: e.target.value })
                      }
                    >
                      <MenuItem value={0}>Tutti gli anni</MenuItem>
                      <MenuItem value={1}>Ultimo anno</MenuItem>
                    </Select>
                  </FormLabel>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                  align="start"
                >
                  <FormLabel component="legend">
                    <Typography variant="h6">
                      Seleziona i dati da scaricare
                    </Typography>
                  </FormLabel>

                  <FormGroup>
                    <Divider light />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={utileGestionale}
                          color="primary"
                          onChange={() => {
                            this.setState({
                              selezione: {
                                ...this.state.selezione,
                                utileGestionale: !utileGestionale,
                              },
                            });
                          }}
                        />
                      }
                      label="Utile gestionale"
                    />
                    <Divider light />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={ricavi}
                          color="primary"
                          onChange={() => {
                            this.setState({
                              selezione: {
                                ...this.state.selezione,
                                ricavi: !ricavi,
                              },
                            });
                          }}
                        />
                      }
                      label="Ricavi"
                    />
                    <Divider light />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={costiMateriePrime}
                          color="primary"
                          onChange={() => {
                            this.setState({
                              selezione: {
                                ...this.state.selezione,
                                costiMateriePrime: !costiMateriePrime,
                              },
                            });
                          }}
                        />
                      }
                      label="Costi materie prime e merci"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={costiServizi}
                          color="primary"
                          onChange={() => {
                            this.setState({
                              selezione: {
                                ...this.state.selezione,
                                costiServizi: !costiServizi,
                              },
                            });
                          }}
                        />
                      }
                      label="Costi per servizi"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={costiPersonale}
                          color="primary"
                          onChange={() => {
                            this.setState({
                              selezione: {
                                ...this.state.selezione,
                                costiPersonale: !costiPersonale,
                              },
                            });
                          }}
                        />
                      }
                      label="Costi per il personale"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={costiAffitti}
                          color="primary"
                          onChange={() => {
                            this.setState({
                              selezione: {
                                ...this.state.selezione,
                                costiAffitti: !costiAffitti,
                              },
                            });
                          }}
                        />
                      }
                      label="Costi per affitti e noleggi"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={costiOneri}
                          color="primary"
                          onChange={() => {
                            this.setState({
                              selezione: {
                                ...this.state.selezione,
                                costiOneri: !costiOneri,
                              },
                            });
                          }}
                        />
                      }
                      label="Costi per oneri diversi di gestione"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={costiTotali}
                          color="primary"
                          onChange={() => {
                            this.setState({
                              selezione: {
                                ...this.state.selezione,
                                costiTotali: !costiTotali,
                              },
                            });
                          }}
                        />
                      }
                      label="Costi totali"
                    />
                    <Divider light />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={redditivita}
                          color="primary"
                          onChange={() => {
                            this.setState({
                              selezione: {
                                ...this.state.selezione,
                                redditivita: !redditivita,
                              },
                            });
                          }}
                        />
                      }
                      label="Redditività"
                    />
                    <Divider light />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item style={{ marginTop: 20 }}>
                <h1>Seleziona i documenti da scaricare</h1>
                <TableContainer style={{ maxHeight: '300px' }}>
                  <Table
                    size="small"
                    id="tabella-redditivita"
                    style={{ width: "99%", maxHeight: 100 }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          style={{
                            fontWeight: "bold",
                            width: "100%"
                          }}
                        >
                          Categoria
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            fontWeight: "bold",
                            width: "100%"
                          }}
                        >
                          Descrizione
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          Anno
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          Semestre
                        </TableCell>


                        <TableCell
                          align="center"
                        >
                          Selezione
                        </TableCell>

                      </TableRow>
                    </TableHead>
                    <TableBody className={classes.tableBody} >
                      {this.state.listaDocumenti
                        .sort((a, b) => {
                          if (a.anno !== b.anno) {
                            return (a.anno === null) - (b.anno === null) || -(a.anno > b.anno) || +(a.anno < b.anno)
                          } else {
                            return (a.semestre === null) - (b.semestre === null) || -(a.semestre > b.semestre) || +(a.semestre < b.semestre)
                          }
                        })
                        .filter(document => document.content_type === "application/pdf")
                        .filter(document => document.categoria === "2086" || document.categoria.includes('GDPR'))
                        .map(documento => {
                          return (
                            <TableRow>
                              <TableCell
                                className={classes.cell}
                                component="th"
                                scope="row"
                              >
                                {documento.categoria}
                              </TableCell>
                              <TableCell
                                className={classes.cell}
                                component="th"
                                scope="row"
                              >
                                {documento.descrizione}
                              </TableCell>

                              <TableCell
                                className={classes.cell}
                                component="th"
                                scope="row"
                              >
                                {documento.anno}
                              </TableCell>
                              <TableCell
                                className={classes.cell}
                                component="th"
                                scope="row"
                              >
                                {documento.semestre}
                              </TableCell>


                              <TableCell
                                className={classes.cell}
                                component="th"
                                scope="row"
                                style={{
                                  textAlign: "center",
                                  cursor: "pointer"
                                }}
                              >
                                <input type="checkbox" onChange={e => {
                                  if (e.target.checked === true) {
                                    this.setState({
                                      listaDocumentiDaScaricare: [...this.state.listaDocumentiDaScaricare, documento.id]
                                    })
                                  } else {
                                    this.setState({
                                      listaDocumentiDaScaricare: this.state.listaDocumentiDaScaricare.filter(id => id !== documento.id)
                                    })
                                  }
                                }}></input>
                              </TableCell>
                            </TableRow>
                          )
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>

              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  type="submit"
                  fullWidth
                  style={{
                    marginTop: 40,
                  }}
                >
                  Download
                </Button>
              </Grid>
            </form>
          </Grid>

          {ReactDOM.createPortal(
            <React.Fragment>
              {this.props.redditivita}
              {React.cloneElement(this.props.utileGestionale, {
                download: true,
                func: this.getDatasetsUtileGestionale,
                periodo: this.state.periodo,
              })}
              {React.cloneElement(this.props.ricavi, {
                download: true,
                trimestri: this.getTrimestriRicavi,
                dettagli: this.getDettagliRicavi,
                periodo: this.state.periodo,
              })}
              {React.cloneElement(this.props.costiMateriePrime, {
                download: true,
                trimestri: this.getTrimestriCostiMateriePrime,
                dettagli: this.getDettagliCostiMateriePrime,
                periodo: this.state.periodo,
              })}
              {React.cloneElement(this.props.costiServizi, {
                download: true,
                trimestri: this.getTrimestriCostiServizi,
                dettagli: this.getDettagliCostiServizi,
                periodo: this.state.periodo,
              })}
              {React.cloneElement(this.props.costiPersonale, {
                download: true,
                trimestri: this.getTrimestriCostiPersonale,
                dettagli: this.getDettagliCostiPersonale,
                periodo: this.state.periodo,
              })}
              {React.cloneElement(this.props.costiAffittiNoleggi, {
                download: true,
                trimestri: this.getTrimestriCostiAffitti,
                dettagli: this.getDettagliCostiAffitti,
                periodo: this.state.periodo,
              })}
              {React.cloneElement(this.props.costiOneriDiversiGestione, {
                download: true,
                trimestri: this.getTrimestriCostiOneriGestione,
                dettagli: this.getDettagliCostiOneriGestione,
                periodo: this.state.periodo,
              })}
              {React.cloneElement(this.props.costiTotali, {
                download: true,
                trimestri: this.getTrimestriCostiTotali,
                dettagli: this.getDettagliCostiTotali,
                periodo: this.state.periodo,
              })}
            </React.Fragment>,
            document.getElementById("download")
          )}
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cliente: state.auth.clienteSelezionato,
  EBIT: state.bilancio.EBIT,
  EBITDA: state.bilancio.EBITDA,
  valoreDellProduzione: state.bilancio.ValoreDellaProduzione,
  costiDellProduzione: state.bilancio.CostiDellaProduzione,
  rettifiche: state.bilancio.rettifiche,
});

export default connect(mapStateToProps)(withStyles(styles)(Download));
