import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/styles";
import Container from "@material-ui/core/Container";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";

import { login } from "../../../actions/auth";
import { connect } from "react-redux";

const styles = theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
      margin: 0
    }
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "100vh",
    justifyContent: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
});

class Login extends Component {
  state = {
    credenziali: {
      username: "",
      password: ""
    },
    errors: {
      occured: false,
      username: false,
      password: false
    }
  };

  onChange = e => {
    var credenziali = this.state.credenziali;

    if (e.target.name === "username") credenziali.username = e.target.value;
    if (e.target.name === "password") credenziali.password = e.target.value;

    this.setState({
      credenziali
    });
  };

  onSubmit = e => {
    e.preventDefault();

    var errors = {
      occured: false,
      username: false,
      password: false
    };

    if (this.state.credenziali.username.length === 0) {
      errors.occured = true;
      errors.username = true;
    }

    if (this.state.credenziali.password.length === 0) {
      errors.occured = true;
      errors.password = true;
    }

    this.setState({
      errors
    });

    if (!errors.occured) {
      this.props.login(
        this.state.credenziali.username,
        this.state.credenziali.password
      );
    }
  };

  render() {
    const { classes, auth } = this.props;

    if (auth.user.username.length !== 0) {
      return <Redirect to="/dashboard" />;
    }

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img
            src="/logo.png"
            alt="GDP Analytics logo"
            width={200}
            height={200}
          />

          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <form className={classes.form} noValidate onSubmit={this.onSubmit}>
            <TextField
              error={this.state.errors.username}
              helperText={
                this.state.errors.username
                  ? "Si prega di inserire un username valido"
                  : ""
              }
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              onChange={this.onChange}
              value={this.state.credenziali.username}
            />
            <TextField
              error={this.state.errors.password}
              helperText={
                this.state.errors.password
                  ? "Si prega di inserire una password valida"
                  : ""
              }
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              autoComplete="current-password"
              onChange={this.onChange}
              value={this.state.credenziali.password}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Login
            </Button>
            <Grid container>
              <Grid item xs style={{ fontSize: 12 }}>
                Per informazioni riguardanti la piattaforma scrivere una e-mail
                a{" "}
                <a href="mailto:info@gdpanalytics.com">info@gdpanalytics.com</a>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { login })(withStyles(styles)(Login));
