import React, { Component } from "react";
import Layout from "./layout/Layout";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Login from "./views/login/Login";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import { connect } from "react-redux";
import { loadUser } from "../actions/auth";
import PrivateComponent from "./layout/PrivateComponent";
import Caricamento from "./views/commercialista/CaricamentoDati";
import CreaUtente from "./views/commercialista/CreaUtente";
import Home from "./views/bilancio/Home";
import Ricavi from "./views/bilancio/Ricavi";
import CostiTotali from "./views/bilancio/CostiTotali";
import CostiMateriePrimeMerci from "./views/bilancio/CostiMateriePrimeMerci";
import CostiServizi from "./views/bilancio/CostiServizi";
import CostiPersonale from "./views/bilancio/CostiPersonale";
import CostiAffittiNoleggi from "./views/bilancio/CostiAffittiNoleggi";
import CostiOneriDiversiGestione from "./views/bilancio/CostiOneriDiversiGestione";
import UtileGestionale from "./views/bilancio/UtileGestionale";
import Redditivita from "./views/bilancio/Redditivita";
import Rettifiche from "./views/commercialista/Rettifiche";
import Download from "./views/bilancio/Download";
import Loading from "./spinners/Loading";
import GestioneConti from "./views/commercialista/GestioneConti";
import GestioneCosti from "./views/commercialista/GestioneCosti";
import CancellaDati from "./views/commercialista/CancellaDati";
import CaricamentoImposte from "./views/commercialista/CaricamentoImposte";
// import Tributi from "./views/bilancio/Tributi";
import InviaNotifica from "./views/commercialista/InviaNotifica";
import ListaDocumenti from "./views/bilancio/ListaDocumenti";
import CaricamentoDocumneti from "./views/commercialista/CaricamentoDocumneti";
import EliminazioneDocumenti from "./views/commercialista/EliminazioneDocumenti";

let theme = createMuiTheme({
  palette: {
    primary: {
      light: "#63ccff",
      main: "#009be5",
      dark: "#006db3",
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 60,
    },
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: "#18202c",
      },
    },
    MuiButton: {
      label: {
        textTransform: "none",
      },
      contained: {
        boxShadow: "none",
        "&:active": {
          boxShadow: "none",
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: "none",
        margin: "0 16px",
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up("md")]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "#404854",
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: "inherit",
        marginRight: 0,
        "& svg": {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
};

class App extends Component {
  componentDidMount() {
    this.props.loadUser();
  }
  render() {
    if (this.props.loading) {
      return <Loading />;
    }

    return (
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route path="/dashboard">
              <PrivateComponent>
                <Layout>
                  <Route path="/dashboard/caricamento-dati">
                    <Caricamento />
                  </Route>
                  <Route path="/dashboard/invia-notifica">
                    <InviaNotifica />
                  </Route>
                  <Route path="/dashboard/caricamento-imposte">
                    <CaricamentoImposte />
                  </Route>
                  <Route path="/dashboard/eliminazione-dati">
                    <CancellaDati />
                  </Route>
                  <Route path="/dashboard/caricamento-documenti">
                    <CaricamentoDocumneti />
                  </Route>
                  <Route path="/dashboard/eliminazione-documenti">
                    <EliminazioneDocumenti />
                  </Route>
                  <Route path="/dashboard/crea-utente">
                    <CreaUtente />
                  </Route>
                  <Route path="/dashboard/rettifiche">
                    <Rettifiche />
                  </Route>
                  <Route path="/dashboard/rinomina-conti">
                    <GestioneConti />
                  </Route>
                  <Route path="/dashboard/gestione-costi">
                    <GestioneCosti />
                  </Route>
                  <Route path="/dashboard/utile-gestionale">
                    <UtileGestionale func={(_) => { }} />
                  </Route>
                  <Route path="/dashboard/ricavi">
                    <Ricavi trimestri={(_) => { }} dettagli={(_) => { }} />
                  </Route>
                  <Route path="/dashboard/costi-materie-prime">
                    <CostiMateriePrimeMerci
                      trimestri={(_) => { }}
                      dettagli={(_) => { }}
                    />
                  </Route>
                  <Route path="/dashboard/costi-servizi">
                    <CostiServizi trimestri={(_) => { }} dettagli={(_) => { }} />
                  </Route>
                  <Route path="/dashboard/costi-personale">
                    <CostiPersonale
                      trimestri={(_) => { }}
                      dettagli={(_) => { }}
                    />
                  </Route>
                  <Route path="/dashboard/costi-affitti-noleggi">
                    <CostiAffittiNoleggi
                      trimestri={(_) => { }}
                      dettagli={(_) => { }}
                    />
                  </Route>
                  <Route path="/dashboard/documenti-2086">
                    <ListaDocumenti filter="2086" titolo="art.2086 CC" />
                  </Route>
                  <Route path="/dashboard/documenti-231">
                    <ListaDocumenti filter="231" titolo="Modello L.231" />
                  </Route>
                  <Route path="/dashboard/documenti-bilanci">
                    <ListaDocumenti filter="Bilanci" titolo="Bilanci" />
                  </Route>
                  <Route path="/dashboard/documenti-redditi">
                    <ListaDocumenti filter="Redditi" titolo="Redditi" />
                  </Route>
                  <Route path="/dashboard/documenti-gdpr-DataBreach">
                    <ListaDocumenti
                      filter="GDPR - Data Breach"
                      titolo="GDPR - Data Breach"
                    />
                  </Route>
                  <Route path="/dashboard/documenti-gdpr-Informativa">
                    <ListaDocumenti
                      filter="GDPR - Informativa"
                      titolo="GDPR - Informativa"
                    />
                  </Route>
                  <Route path="/dashboard/documenti-gdpr-letteredinominaincaricatiinterni">
                    <ListaDocumenti
                      filter="GDPR - lettere di nomina incaricati interni"
                      titolo="GDPR - Lettere di nomina incaricati interni"
                    />
                  </Route>
                  <Route path="/dashboard/documenti-gdpr-Letteredincaricorespesterni">
                    <ListaDocumenti
                      filter="GDPR - Lettere d'incarico resp. esterni"
                      titolo="GDPR - Lettere d'incarico resp. esterni"
                    />
                  </Route>
                  <Route path="/dashboard/documenti-gdpr-Registrodeitrattamenti">
                    <ListaDocumenti
                      filter="GDPR - Registro dei trattamenti"
                      titolo="GDPR - Registro dei trattamenti"
                    />
                  </Route>
                  <Route path="/dashboard/documenti-gdpr-Revocaconsenso">
                    <ListaDocumenti
                      filter="GDPR - Revoca consenso"
                      titolo="GDPR - Revoca consenso"
                    />
                  </Route>
                  <Route path="/dashboard/documenti-gdpr-ModelloOrganizzativoPrivacy">
                    <ListaDocumenti
                      filter="Modello organizzativo privacy"
                      titolo="Modello organizzativo privacy"
                    />
                  </Route>
                  <Route path="/dashboard/documenti-gdpr-AltriDocumenti">
                    <ListaDocumenti
                      filter="Altri documenti"
                      titolo="Altri documenti"
                    />
                  </Route>

                  <Route path="/dashboard/costi-oneri-diversi-gestione">
                    <CostiOneriDiversiGestione
                      trimestri={(_) => { }}
                      dettagli={(_) => { }}
                    />
                  </Route>
                  <Route path="/dashboard/costi-totali">
                    <CostiTotali trimestri={(_) => { }} dettagli={(_) => { }} />
                  </Route>
                  {/* <Route path="/dashboard/tributi">
                    <Tributi />
                  </Route> */}
                  <Route path="/dashboard/redditivita">
                    <Redditivita trimestri={(_) => { }} dettagli={(_) => { }} />
                  </Route>
                  <Route path="/dashboard/download">
                    <Download
                      utileGestionale={<UtileGestionale />}
                      redditivita={<Redditivita />}
                      ricavi={<Ricavi />}
                      costiMateriePrime={<CostiMateriePrimeMerci />}
                      costiServizi={<CostiServizi />}
                      costiPersonale={<CostiPersonale />}
                      costiAffittiNoleggi={<CostiAffittiNoleggi />}
                      costiOneriDiversiGestione={<CostiOneriDiversiGestione />}
                      costiTotali={<CostiTotali />}
                    />
                  </Route>

                  <Route path="/dashboard/homepage">
                    <Home />
                  </Route>
                  <Route path="/dashboard/">
                    <Redirect to="/dashboard/homepage" />
                  </Route>
                </Layout>
              </PrivateComponent>
            </Route>
            <Route path="/">
              <Login />
            </Route>
          </Switch>
        </Router>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.auth.isLoading,
  clienteSelezionato: state.auth.clienteSelezionato,
});

export default connect(mapStateToProps, { loadUser })(App);
