import {
  USER_FAIL,
  LOGOUT_SUCCESS,
  USER_LOADED,
  AGGIUNTA_CLIENTE,
  UPDATE_CLIENTE_SELEZIONATO,
  GET_IMPOSTE
} from "../actions/types";
import User from "../models/User";

const initialState = {
  isLoading: true,
  user: new User(),
  clienteSelezionato: "",
  clienteSelezionatoImposte: 0,
  clienteSelezionatoPercentualeImposte: 0
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOGOUT_SUCCESS:
    case USER_FAIL:
      localStorage.removeItem("token");
      return {
        ...state,
        isLoading: false,
        user: new User(),
        clienteSelezionato: ""
      };
    case AGGIUNTA_CLIENTE:
      return {
        ...state,
        user: {
          ...state.user,
          clienti: [...state.user.clienti, action.payload].sort((a, b) =>
            a.toLowerCase().localeCompare(b.toLowerCase())
          )
        }
      };
    case USER_LOADED:
      return {
        ...state,
        isLoading: false,
        user: Object.assign(new User(), action.payload),
        clienteSelezionato: action.payload.clienti[0]
      };

    case UPDATE_CLIENTE_SELEZIONATO:
      return {
        ...state,
        clienteSelezionato: action.payload
      };
    case GET_IMPOSTE:
      return {
        ...state,
        clienteSelezionatoImposte: action.payload.importo,
        clienteSelezionatoPercentualeImposte: action.payload.percentuale
      };
    default:
      return state;
  }
}
