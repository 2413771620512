export const USER_LOADED = "USER_LOADED";
export const USER_FAIL = "USER_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const GET_VALORE_DELLA_PRODUZIONE = "GET_VALORE_DELLA_PRODUZIONE";
export const GET_COSTI_DELLA_PRODUZIONE = "GET_COSTI_DELLA_PRODUZIONE";
export const GET_CREDITI_VERSO_SOCI = "GET_CREDITI_VERSO_SOCI";
export const GET_DISPONIBILITA_LIQUIDE = "GET_DISPONIBILITA_LIQUIDE";
export const GET_IMMOBILIZZAZIONI_IMMATERIALI =
  "GET_IMMOBILIZZAZIONI_IMMATERIALI";
export const GET_IMMOBILIZZAZIONI_MATERIALI = "GET_IMMOBILIZZAZIONI_MATERIALI";
export const GET_IMMOBILIZZAZIONI_FINANZIARIE =
  "GET_IMMOBILIZZAZIONI_FINANZIARIE";
export const GET_RIMANENZE = "GET_RIMANENZE";
export const GET_CREDITI = "GET_CREDITI";
export const GET_ATTIVITA_FINANZIARIE = "GET_ATTIVITA_FINANZIARIE";
export const GET_RATEI_RISCONTI_ATTIVI = "GET_RATEI_RISCONTI_ATTIVI";
export const GET_PATRIMONIO_NETTO = "GET_PATRIMONIO_NETTO";
export const GET_FONDO_RISCHI_ONERI = "GET_FONDO_RISCHI_ONERI";
export const GET_TFR = "GET_TFR";
export const GET_DEBITI = "GET_DEBITI";
export const GET_RATEI_RISCONTI_PASSIVI = "GET_RATEI_RISCONTI_PASSIVI";
export const GET_PROVENTI_FINANZIARI = "GET_PROVENTI_FINANZIARI";
export const GET_RIVALUTAZIONI = "GET_RIVALUTAZIONI";
export const GET_PROVENTI_STRAORDINARI = "GET_PROVENTI_STRAORDINARI";
export const GET_ONERI_FINANZIARI = "GET_ONERI_FINANZIARI";
export const GET_SVALUTAZIONI = "GET_SVALUTAZIONI";
export const GET_ONERI_TRIBUTARI = "GET_ONERI_TRIBUTARI";
export const GET_CONTO_ECONOMICO = "GET_CONTO_ECONOMICO";
export const GET_RETTIFICHE = "GET_RETTIFICHE";
export const AGGIUNTA_CLIENTE = "AGGIUNTA_CLIENTE";
export const DESCRIZIONE_CONTI = "DESCRIZIONE_CONTI";
export const UPDATE_CLIENTE_SELEZIONATO = "UPDATE_CLIENTE_SELEZIONATO";
export const GET_CANCELLA_LIST = "GET_CANCELLA_LIST";
export const REMOVE_CANCELLA_LIST = "REMOVE_CANCELLA_LIST";
export const INIT_STATE = "INIT_STATE";
export const GET_TRIBUTI = "GET_TRIBUTI";
export const GET_IMPOSTE = "GET_IMPOSTE";
export const SET_EBIT = "SET_EBIT";
export const SET_EBITDA = "SET_EBITDA";

export const API_URL = "https://piattaforma-copernico.com/api/";
// export const API_URL = "http://piattaforma-copernico.gdpanalytics.local:8000/api/";
