import React, { Component } from "react";
import {
  Paper,
  Typography,
  Grid,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

import { connect } from "react-redux";

import moment from "moment";

import { DatePicker } from "@material-ui/pickers";
import { DropzoneArea } from "material-ui-dropzone";
import {
  caricaDati,
  initState,
  getValoreDellaProduzione,
  getCostiDellaProduzione
} from "../../../actions/bilancio";
import ErrorAlert from "../../alerts/ErrorAlert";
import { ExpandMore } from "@material-ui/icons";

const styles = theme => ({
  paper: {
    margin: "auto",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),

    maxWidth: 850,
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3)
    }
  },
  textField: {
    maxWidth: "100%"
  },
  form: {
    marginTop: theme.spacing(2)
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%"
  },
  button: { margin: theme.spacing(1) }
});

class CaricamentoDati extends Component {
  state = {
    key: 0,
    data: moment().startOf("quarter"),
    trimestre: 1,
    file: null
  };

  formatDate = date => {
    return date.year();
  };

  cambiaData = data => {
    this.setState({
      data: data.endOf("year"),
      trimestre: data
        .quarter(this.state.trimestre)
        .startOf("quarter")
        .isBefore(moment())
        ? this.state.trimestre
        : 1
    });
  };

  filesUpload = files => {
    if (files.length === 1) {
      this.setState({
        file: files[0]
      });
    } else {
      this.setState({
        file: null
      });
    }
  };
  onSubmit = async e => {
    e.preventDefault();
    if (this.state.file === null) {
      ErrorAlert("Si prega di caricare un file");
      return;
    }

    const formData = new FormData();

    formData.append("bilancio", this.state.file);
    formData.append("cliente", this.props.clienteSelezionato);
    formData.append(
      "data",
      this.state.data
        .quarter(this.state.trimestre)
        .startOf("quarter")
        .format("YYYY/MM/DD")
    );
    await this.props.caricaDati(formData);
    this.setState({
      key: this.state.key + 1,
      file: null
    });
    this.props.getCostiDellaProduzione(true);
    this.props.getValoreDellaProduzione(true);
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.wrapper}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h5" align="center">
            Caricamento dati
          </Typography>
          <form noValidate className={classes.form} onSubmit={this.onSubmit}>
            <Grid container spacing={2} alignItems="center" justify="center">
              <Grid item xs={12} sm={4} md={3}>
                <FormControl fullWidth>
                  <InputLabel>Trimestre</InputLabel>
                  <Select
                    value={this.state.trimestre}
                    onChange={e => this.setState({ trimestre: e.target.value })}
                  >
                    {[1, 2, 3, 4].map(v => {
                      if (
                        this.state.data
                          .quarter(v)
                          .startOf("quarter")
                          .isBefore(moment())
                      ) {
                        return <MenuItem value={v}>{v}°</MenuItem>;
                      }
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <DatePicker
                  name="data"
                  label="Anno"
                  value={this.state.data}
                  labelFunc={this.formatDate}
                  views={["year"]}
                  cancelLabel="Chiudi"
                  onChange={this.cambiaData}
                  minDate={moment()
                    .subtract(5, "year")
                    .startOf("year")}
                  maxDate={moment().endOf("year")}
                  renderDay={() => {
                    return <div>asd</div>;
                  }}
                  fullWidth
                  InputProps={{
                    endAdornment: <ExpandMore />,

                    style: { cursor: "pointer" }
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <DropzoneArea
                  key={this.state.key}
                  filesLimit={1}
                  acceptedFiles={[".xlsx", ".csv"]}
                  showFileNames={true}
                  dropzoneText="Trascina o clicca per selezionare un file excel"
                  maxFileSize={10485760}
                  getFileAddedMessage={fileName =>
                    `File ${fileName} aggiunto con successo`
                  }
                  getFileRemovedMessage={fileName => `File ${fileName} rimosso`}
                  getDropRejectMessage={(
                    rejectedFile,
                    acceptedFiles,
                    maxFileSize
                  ) => {
                    if (rejectedFile.size > maxFileSize) {
                      return `Il file ${rejectedFile.name} supera la dimensione massima di 10M`;
                    } else {
                      return `Il file ${rejectedFile.name
                        } non è in formato ${acceptedFiles.join(", ")}`;
                    }
                  }}
                  onChange={this.filesUpload}
                />
              </Grid>
              <div className={classes.buttonContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  type="submit"
                >
                  CARICA
                </Button>
              </div>
            </Grid>
          </form>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  clienteSelezionato: state.auth.clienteSelezionato
});

export default connect(mapStateToProps, {
  caricaDati,
  getValoreDellaProduzione,
  getCostiDellaProduzione
})(withStyles(styles)(CaricamentoDati));
