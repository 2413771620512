import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";

import * as serviceWorker from "./serviceWorker";

import store from "./store";
import { Provider } from "react-redux";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";

import firebase from "firebase/app";
import "firebase/messaging";

import { defaults, Chart } from "react-chartjs-2";

import "moment/locale/it";
moment.locale("it");

defaults.global.responsive = true;
defaults.global.maintainAspectRatio = false;
defaults.global.defaultFontFamily =
  "'Roboto', 'Helvetica', 'Arial', sans-serif";
defaults.global.defaultFontSize = 14;
// Tooltips
defaults.global.tooltips.mode = "index";
defaults.global.tooltips.intersect = false;
defaults.global.tooltips.displayColors = false;
defaults.global.tooltips.titleFontSize = 17;
defaults.global.tooltips.backgroundColor = "white";
defaults.global.tooltips.titleMarginBottom = 8;
defaults.global.tooltips.bodyFontColor = "rgba(0,0,0,0.87)";
defaults.global.tooltips.titleFontColor = "#009be5";
defaults.global.tooltips.bodySpacing = 6;
// Legend
defaults.global.legend.labels.boxWidth = 14;
defaults.global.legend.labels.usePointStyle = true;
defaults.global.legend.labels.padding = 20;

Chart.Legend.prototype.afterFit = function () {
  this.height = this.height + 12;
};

ReactDOM.render(
  <Provider store={store}>
    <MuiPickersUtilsProvider utils={MomentUtils} locale="it">
      <App />
    </MuiPickersUtilsProvider>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.register();

if ("serviceWorker" in navigator) {
  const firebaseConfig = {
    apiKey: "AIzaSyDxIs8LT6K6V5-TAyPyoVeM_lu6UEL3RJI",
    authDomain: "copernico-notification.firebaseapp.com",
    databaseURL: "https://copernico-notification.firebaseio.com",
    projectId: "copernico-notification",
    storageBucket: "copernico-notification.appspot.com",
    messagingSenderId: "240104339754",
    appId: "1:240104339754:web:98615be543a08baac98653",
    measurementId: "G-W9PKEB4B2C",
  };

  firebase.initializeApp(firebaseConfig);

  const messaging = firebase.messaging();
  messaging.usePublicVapidKey(
    "BH40QttyTZTSu5xnhkUuYtiuvqlnbhFZa9HnWiHqGb84fyeB5spEDE77tEkztxZ1zUYXUtSHoT4dNptZyjG5WfA"
  );

  if (Notification.permission !== "granted") Notification.requestPermission();

  messaging
    .getToken()
    .then((token) => {
      console.log(token);
      localStorage.setItem("notification-token", token);
    })
    .catch((err) => {
      console.log("Unable to retrieve refreshed token ", err);
    });
  messaging.onMessage((payload) => {
    console.log("Message received. ", payload);
    const notification = payload.notification;
    if (Notification.permission !== "granted") Notification.requestPermission();
    const showNotification = new Notification(notification.title, {
      body: notification.body,
    });

    if (Notification.permission !== "granted") Notification.requestPermission();
  });
}
