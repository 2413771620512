import React, { Component } from "react";
import { Paper, Typography, Grid, Button, TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

import { connect } from "react-redux";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import { DeleteForever } from "@material-ui/icons";
import {
  getCancellaList,
  cancellaTrimestre,
  getCostiDellaProduzione,
  getValoreDellaProduzione
} from "../../../actions/bilancio";
import moment from "moment";
import InfoAlert from "../../alerts/InfoAlert";

const styles = theme => ({
  paper: {
    margin: "auto",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    maxWidth: 600,
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3)
    }
  },
  item: {
    display: "flex"
  },
  itemLabel: {
    flexGrow: 1,
    textAlign: "center"
  }
});

class CancellaDati extends Component {
  componentDidMount() {
    this.props.getCancellaList();
  }
  render() {
    const { classes, cancellaList } = this.props;

    var map = new Map();

    cancellaList
      .sort((a, b) => moment(a).valueOf() - moment(b).valueOf())
      .forEach(v => {
        const data = moment(v);
        const anno = data.year();
        if (!map.has(anno)) {
          map.set(anno, [data.quarter()]);
        } else {
          map.set(anno, [...map.get(anno), data.quarter()].sort());
        }
      });

    var keys = [];
    var keysIterator = map.keys();
    var k;
    while ((k = keysIterator.next()).done === false) {
      keys.push(k.value);
    }

    return (
      <Paper className={classes.paper}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column"
          }}
        >
          <Typography
            component="span"
            variant="h5"
            align="center"
            style={{
              margin: "auto"
            }}
          >
            Scegli il trimestre da cancellare
          </Typography>
          <div
            style={{
              width: 200,
              marginTop: 40,
              marginBottom: 20
            }}
          >
            <TreeView
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
            >
              {keys.map(anno => {
                return (
                  <TreeItem nodeId={anno} label={anno}>
                    {map.get(anno).map(trimestre => {
                      return (
                        <TreeItem
                          onClick={async () => {
                            if (await InfoAlert()) {
                              const data = moment(`${anno}-01-01`)
                                .quarter(trimestre)
                                .format("YYYY-MM-DD");
                              await this.props.cancellaTrimestre({ data });
                              this.props.getCostiDellaProduzione(true);
                              this.props.getValoreDellaProduzione(true);
                            }
                          }}
                          nodeId={`${anno}-${trimestre}`}
                          label={
                            <div className={classes.item}>
                              <span className={classes.itemLabel}>
                                {trimestre}° trimestre
                              </span>
                              <DeleteForever color="error" />
                            </div>
                          }
                        />
                      );
                    })}
                  </TreeItem>
                );
              })}
            </TreeView>
          </div>
        </div>
      </Paper>
    );
  }
}

const mapStateToProps = state => ({
  cancellaList: state.bilancio.cancellaList
});

export default connect(mapStateToProps, {
  getCancellaList,
  cancellaTrimestre,
  getCostiDellaProduzione,
  getValoreDellaProduzione
})(withStyles(styles)(CancellaDati));
