import React from "react";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Grid,
  Tooltip
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

import moment from "moment";
import { useState } from "react";

const styles = theme => ({
  card: {
    overflow: "visible",
    height: "100%"
  },
  rounded: {
    color: "#fff",
    boxShadow: "0 8px 17px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19)",
    borderRadius: "0.25rem",
    padding: "1rem",
    marginTop: -30,
    lineHeight: "0.5em",
    position: "absolute",
    zIndex: 999
  },
  cardActions: {
    padding: 16
  },
  floatRight: {
    float: "right"
  },
  progressContainer: {
    width: "100%",
    backgroundColor: "#eee",
    height: "0.25rem",
    marginBottom: "1rem"
  },
  arrow: {
    color: theme.palette.common.black
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: 13
  }
});

function HomepageCard(props) {
  const { classes } = props;

  const [show, setShow] = useState(false);

  var percentuale = props.percentuale;
  var segno = "";
  if (props.percentuale >= 0) segno = "+";
  if (props.colore === "green" && props.voce === "UTILE OPERATIVO") {
    segno = "Miglioramento del ";
    percentuale = Math.abs(props.percentuale);
  } else if (props.colore === "red" && props.voce === "UTILE OPERATIVO") {
    segno = "Peggioramento del ";
    percentuale = Math.abs(props.percentuale);
  }

  const lunghezzaBarra =
    Math.abs(props.percentuale) > 100 ? 100 : Math.abs(props.percentuale);

  return (
    <Tooltip
      open={show}
      arrow
      title={`Dati relativi al ${moment(
        props.periodo
      ).quarter()}° trimestre ${moment(props.periodo).year()}`}
      placement="top"
      classes={classes}
    >
      <Card
        className={classes.card}
        onClick={() => setShow(true)}
        onMouseOver={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      >
        <CardContent>
          <Grid container>
            <Grid item xs={6}>
              <i
                className={classes.rounded}
                style={{
                  backgroundColor: props.colore
                }}
              >
                {props.icon}
              </i>
            </Grid>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    className={classes.floatRight}
                  >
                    {props.voce}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.floatRight}>
                    {Math.round(props.importo).toLocaleString() + " €"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>

        <CardActions className={classes.cardActions}>
          <Grid style={{ width: "100%" }}>
            <div className={classes.progressContainer}>
              <div
                style={{
                  backgroundColor: props.colore,
                  width: `${lunghezzaBarra}%`,
                  height: "0.25rem"
                }}
              ></div>
            </div>
            {isFinite(percentuale) ? (
              <Typography variant="body2" color="textSecondary">
                {segno}
                {!isNaN(percentuale) ? percentuale : 0}% rispetto al trimestre
                prec.
              </Typography>
            ) : (
              <Typography variant="body2" color="textSecondary">
                Nessun dato relativo al trimestre precendente
              </Typography>
            )}
          </Grid>
        </CardActions>
      </Card>
    </Tooltip>
  );
}

export default withStyles(styles)(HomepageCard);
