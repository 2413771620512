import axios from "axios";
import {
  API_URL,
  GET_VALORE_DELLA_PRODUZIONE,
  GET_COSTI_DELLA_PRODUZIONE,
  GET_CREDITI_VERSO_SOCI,
  GET_DISPONIBILITA_LIQUIDE,
  GET_IMMOBILIZZAZIONI_IMMATERIALI,
  GET_IMMOBILIZZAZIONI_MATERIALI,
  GET_IMMOBILIZZAZIONI_FINANZIARIE,
  GET_RIMANENZE,
  GET_CREDITI,
  GET_ATTIVITA_FINANZIARIE,
  GET_RATEI_RISCONTI_ATTIVI,
  GET_PATRIMONIO_NETTO,
  GET_FONDO_RISCHI_ONERI,
  GET_TFR,
  GET_DEBITI,
  GET_RATEI_RISCONTI_PASSIVI,
  GET_PROVENTI_FINANZIARI,
  GET_RIVALUTAZIONI,
  GET_PROVENTI_STRAORDINARI,
  GET_SVALUTAZIONI,
  GET_ONERI_TRIBUTARI,
  GET_CONTO_ECONOMICO,
  GET_ONERI_FINANZIARI,
  GET_RETTIFICHE,
  DESCRIZIONE_CONTI,
  GET_CANCELLA_LIST,
  REMOVE_CANCELLA_LIST,
  INIT_STATE,
  GET_TRIBUTI,
  GET_IMPOSTE,
  SET_EBIT,
  SET_EBITDA
} from "./types";
import { getToken } from "./auth";
import ErrorAlert from "../components/alerts/ErrorAlert";
import SuccessAlert from "../components/alerts/SuccessAlert";

export const initState = () => async dispatch => {
  dispatch({
    type: INIT_STATE
  });
};

export const caricaDati = formData => async dispatch => {
  try {
    await axios.post(API_URL + "bilancio/", formData, getToken());

    SuccessAlert("Dati caricati con successo");
  } catch (error) {
    const errori = error.response.data;

    if (
      errori.includes(
        "The fields descrizione, periodo, user must make a unique set."
      )
    ) {
      ErrorAlert("Dati già caricati per questo periodo");
    } else {
      ErrorAlert(error.response.data);
    }
  }
};

export const caricaTributi = formData => async dispatch => {
  try {
    const res = await axios.post(
      API_URL + "bilancio/tributi/",
      formData,
      getToken()
    );
    console.log(res.data);
    SuccessAlert("Dati caricati con successo");
  } catch (error) {
    const errori = error.response.data;
    if (
      errori.includes(
        "The fields descrizione, periodo, user must make a unique set."
      )
    ) {
      ErrorAlert("Dati già caricati per questo periodo");
    } else {
      ErrorAlert(error.response.data);
    }
  }
};

export const getTributi = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      API_URL +
        `bilancio/tributi/?cliente=${getState().auth.clienteSelezionato.replace(
          "&",
          "%26"
        )}`,
      getToken()
    );
    dispatch({
      type: GET_TRIBUTI,
      payload: res.data
    });
  } catch (error) {}
};
export const caricaImposte = formData => async (dispatch, getState) => {
  try {
    const res = await axios.post(
      API_URL +
        `bilancio/imposte/?cliente=${getState().auth.clienteSelezionato.replace(
          "&",
          "%26"
        )}`,
      formData,
      getToken()
    );
    dispatch({
      type: GET_IMPOSTE,
      payload: res.data
    });
    SuccessAlert("Dati salvati con successo");
  } catch (error) {}
};

export const getImposte = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      API_URL +
        `bilancio/imposte/?cliente=${getState().auth.clienteSelezionato.replace(
          "&",
          "%26"
        )}`,
      getToken()
    );
    dispatch({
      type: GET_IMPOSTE,
      payload: res.data
    });
  } catch (error) {}
};

export const getValoreDellaProduzione = (dettagli = false) => async (
  dispatch,
  getState
) => {
  try {
    const result = await axios.get(
      API_URL +
        `bilancio/valore-della-produzione/?cliente=${getState().auth.clienteSelezionato.replace(
          "&",
          "%26"
        )}&dettagli=${dettagli}`,
      getToken()
    );
    dispatch({
      type: GET_VALORE_DELLA_PRODUZIONE,
      payload: result.data
    });
  } catch (error) {}
};

export const getDescrizioneConti = () => async (dispatch, getState) => {
  try {
    const result = await axios.get(
      API_URL +
        `bilancio/descrizione-conti/?cliente=${getState().auth.clienteSelezionato.replace(
          "&",
          "%26"
        )}`,
      getToken()
    );
    dispatch({
      type: DESCRIZIONE_CONTI,
      payload: result.data
    });
  } catch (error) {}
};

export const updateDescrizioneConti = conti => async (dispatch, getState) => {
  try {
    const result = await axios.post(
      API_URL +
        `bilancio/descrizione-conti/?cliente=${getState().auth.clienteSelezionato.replace(
          "&",
          "%26"
        )}`,
      conti,
      getToken()
    );
    dispatch({
      type: DESCRIZIONE_CONTI,
      payload: result.data
    });
    SuccessAlert("Descrizioni modificate correttamente");
  } catch (error) {}
};

export const updateVociCosti = costi => async (dispatch, getState) => {
  try {
    await axios.post(
      API_URL +
        `bilancio/gestione-costi/?cliente=${getState().auth.clienteSelezionato.replace(
          "&",
          "%26"
        )}`,
      costi,
      getToken()
    );
    getCostiDellaProduzione();
    SuccessAlert("Descrizioni modificate correttamente");
  } catch (error) {}
};

export const getCostiDellaProduzione = (dettagli = false) => async (
  dispatch,
  getState
) => {
  try {
    const result = await axios.get(
      API_URL +
        `bilancio/costi-della-produzione/?cliente=${getState().auth.clienteSelezionato.replace(
          "&",
          "%26"
        )}&dettagli=${dettagli}`,
      getToken()
    );
    dispatch({
      type: GET_COSTI_DELLA_PRODUZIONE,
      payload: result.data
    });
  } catch (error) {}
};

export const getCreditiVersoSoci = (dettagli = false) => async (
  dispatch,
  getState
) => {
  try {
    const result = await axios.get(
      API_URL +
        `bilancio/crediti-verso-soci/?cliente=${getState().auth.clienteSelezionato.replace(
          "&",
          "%26"
        )}&dettagli=${dettagli}`,
      getToken()
    );
    dispatch({
      type: GET_CREDITI_VERSO_SOCI,
      payload: result.data
    });
  } catch (error) {}
};

export const getDisponibilitaLiquide = (dettagli = false) => async (
  dispatch,
  getState
) => {
  try {
    const result = await axios.get(
      API_URL +
        `bilancio/disponibilita-liquide/?cliente=${getState().auth.clienteSelezionato.replace(
          "&",
          "%26"
        )}&dettagli=${dettagli}`,
      getToken()
    );
    dispatch({
      type: GET_DISPONIBILITA_LIQUIDE,
      payload: result.data
    });
  } catch (error) {}
};

export const getImmobilizzazioniImmateriali = (dettagli = false) => async (
  dispatch,
  getState
) => {
  try {
    const result = await axios.get(
      API_URL +
        `bilancio/immobilizzazioni-immateriali/?cliente=${getState().auth.clienteSelezionato.replace(
          "&",
          "%26"
        )}&dettagli=${dettagli}`,
      getToken()
    );
    dispatch({
      type: GET_IMMOBILIZZAZIONI_IMMATERIALI,
      payload: result.data
    });
  } catch (error) {}
};

export const getImmobilizzazioniMateriali = (dettagli = false) => async (
  dispatch,
  getState
) => {
  try {
    const result = await axios.get(
      API_URL +
        `bilancio/immobilizzazioni-materiali/?cliente=${getState().auth.clienteSelezionato.replace(
          "&",
          "%26"
        )}&dettagli=${dettagli}`,
      getToken()
    );
    dispatch({
      type: GET_IMMOBILIZZAZIONI_MATERIALI,
      payload: result.data
    });
  } catch (error) {}
};

export const getImmobilizzazioniFinanziarie = (dettagli = false) => async (
  dispatch,
  getState
) => {
  try {
    const result = await axios.get(
      API_URL +
        `bilancio/immobilizzazioni-finanziarie/?cliente=${getState().auth.clienteSelezionato.replace(
          "&",
          "%26"
        )}&dettagli=${dettagli}`,
      getToken()
    );
    dispatch({
      type: GET_IMMOBILIZZAZIONI_FINANZIARIE,
      payload: result.data
    });
  } catch (error) {}
};

export const getRimanenze = (dettagli = false) => async (
  dispatch,
  getState
) => {
  try {
    const result = await axios.get(
      API_URL +
        `bilancio/rimanenze/?cliente=${getState().auth.clienteSelezionato.replace(
          "&",
          "%26"
        )}&dettagli=${dettagli}`,
      getToken()
    );
    dispatch({
      type: GET_RIMANENZE,
      payload: result.data
    });
  } catch (error) {}
};

export const getCrediti = (dettagli = false) => async (dispatch, getState) => {
  try {
    const result = await axios.get(
      API_URL +
        `bilancio/crediti/?cliente=${getState().auth.clienteSelezionato.replace(
          "&",
          "%26"
        )}&dettagli=${dettagli}`,
      getToken()
    );
    dispatch({
      type: GET_CREDITI,
      payload: result.data
    });
  } catch (error) {}
};

export const getAttivitaFinanziarie = (dettagli = false) => async (
  dispatch,
  getState
) => {
  try {
    const result = await axios.get(
      API_URL +
        `bilancio/attivita-finanziarie/?cliente=${getState().auth.clienteSelezionato.replace(
          "&",
          "%26"
        )}&dettagli=${dettagli}`,
      getToken()
    );
    dispatch({
      type: GET_ATTIVITA_FINANZIARIE,
      payload: result.data
    });
  } catch (error) {}
};

export const getRateiRiscontiAttivi = (dettagli = false) => async (
  dispatch,
  getState
) => {
  try {
    const result = await axios.get(
      API_URL +
        `bilancio/ratei-risconti-attivi/?cliente=${getState().auth.clienteSelezionato.replace(
          "&",
          "%26"
        )}&dettagli=${dettagli}`,
      getToken()
    );
    dispatch({
      type: GET_RATEI_RISCONTI_ATTIVI,
      payload: result.data
    });
  } catch (error) {}
};

export const getPatrimonioNetto = (dettagli = false) => async (
  dispatch,
  getState
) => {
  try {
    const result = await axios.get(
      API_URL +
        `bilancio/patrimonio-netto/?cliente=${getState().auth.clienteSelezionato.replace(
          "&",
          "%26"
        )}&dettagli=${dettagli}`,
      getToken()
    );
    dispatch({
      type: GET_PATRIMONIO_NETTO,
      payload: result.data
    });
  } catch (error) {}
};

export const getFondiRischiOneri = (dettagli = false) => async (
  dispatch,
  getState
) => {
  try {
    const result = await axios.get(
      API_URL +
        `bilancio/fondi-rischi-oneri/?cliente=${getState().auth.clienteSelezionato.replace(
          "&",
          "%26"
        )}&dettagli=${dettagli}`,
      getToken()
    );
    dispatch({
      type: GET_FONDO_RISCHI_ONERI,
      payload: result.data
    });
  } catch (error) {}
};

export const getTFR = (dettagli = false) => async (dispatch, getState) => {
  try {
    const result = await axios.get(
      API_URL +
        `bilancio/tfr/?cliente=${getState().auth.clienteSelezionato.replace(
          "&",
          "%26"
        )}&dettagli=${dettagli}`,
      getToken()
    );
    dispatch({
      type: GET_TFR,
      payload: result.data
    });
  } catch (error) {}
};

export const getDebiti = (dettagli = false) => async (dispatch, getState) => {
  try {
    const result = await axios.get(
      API_URL +
        `bilancio/debiti/?cliente=${getState().auth.clienteSelezionato.replace(
          "&",
          "%26"
        )}&dettagli=${dettagli}`,
      getToken()
    );
    dispatch({
      type: GET_DEBITI,
      payload: result.data
    });
  } catch (error) {}
};

export const getRateiRiscontiPassivi = (dettagli = false) => async (
  dispatch,
  getState
) => {
  try {
    const result = await axios.get(
      API_URL +
        `bilancio/ratei-risconti-passivi/?cliente=${getState().auth.clienteSelezionato.replace(
          "&",
          "%26"
        )}&dettagli=${dettagli}`,
      getToken()
    );
    dispatch({
      type: GET_RATEI_RISCONTI_PASSIVI,
      payload: result.data
    });
  } catch (error) {}
};

export const getProventiFinanziari = (dettagli = false) => async (
  dispatch,
  getState
) => {
  try {
    const result = await axios.get(
      API_URL +
        `bilancio/proventi-finanziari/?cliente=${getState().auth.clienteSelezionato.replace(
          "&",
          "%26"
        )}&dettagli=${dettagli}`,
      getToken()
    );

    dispatch({
      type: GET_PROVENTI_FINANZIARI,
      payload: result.data
    });
  } catch (error) {}
};

export const getRivalutazioni = (dettagli = false) => async (
  dispatch,
  getState
) => {
  try {
    const result = await axios.get(
      API_URL +
        `bilancio/rivalutazioni/?cliente=${getState().auth.clienteSelezionato.replace(
          "&",
          "%26"
        )}&dettagli=${dettagli}`,
      getToken()
    );
    dispatch({
      type: GET_RIVALUTAZIONI,
      payload: result.data
    });
  } catch (error) {}
};

export const getProventiStraordinari = (dettagli = false) => async (
  dispatch,
  getState
) => {
  try {
    const result = await axios.get(
      API_URL +
        `bilancio/proventi-straordinari/?cliente=${getState().auth.clienteSelezionato.replace(
          "&",
          "%26"
        )}&dettagli=${dettagli}`,
      getToken()
    );
    dispatch({
      type: GET_PROVENTI_STRAORDINARI,
      payload: result.data
    });
  } catch (error) {}
};

export const getOneriFinanziari = (dettagli = false) => async (
  dispatch,
  getState
) => {
  try {
    const result = await axios.get(
      API_URL +
        `bilancio/oneri-finanziari/?cliente=${getState().auth.clienteSelezionato.replace(
          "&",
          "%26"
        )}&dettagli=${dettagli}`,
      getToken()
    );
    dispatch({
      type: GET_ONERI_FINANZIARI,
      payload: result.data
    });
  } catch (error) {}
};

export const getSvalutazioni = (dettagli = false) => async (
  dispatch,
  getState
) => {
  try {
    const result = await axios.get(
      API_URL +
        `bilancio/svalutazioni/?cliente=${getState().auth.clienteSelezionato.replace(
          "&",
          "%26"
        )}&dettagli=${dettagli}`,
      getToken()
    );
    dispatch({
      type: GET_SVALUTAZIONI,
      payload: result.data
    });
  } catch (error) {}
};

export const getOneriTributari = (dettagli = false) => async (
  dispatch,
  getState
) => {
  try {
    const result = await axios.get(
      API_URL +
        `bilancio/oneri-tributari/?cliente=${getState().auth.clienteSelezionato.replace(
          "&",
          "%26"
        )}&dettagli=${dettagli}`,
      getToken()
    );
    dispatch({
      type: GET_ONERI_TRIBUTARI,
      payload: result.data
    });
  } catch (error) {}
};

export const getContoEconomico = (dettagli = false) => async (
  dispatch,
  getState
) => {
  try {
    const result = await axios.get(
      API_URL +
        `bilancio/conto-economico/?cliente=${getState().auth.clienteSelezionato.replace(
          "&",
          "%26"
        )}&dettagli=${dettagli}`,
      getToken()
    );
    dispatch({
      type: GET_CONTO_ECONOMICO,
      payload: result.data
    });
  } catch (error) {}
};

export const getRettifiche = (anno = null) => async (dispatch, getState) => {
  try {
    const result = await axios.get(
      API_URL +
        `bilancio/rettifiche/?cliente=${getState().auth.clienteSelezionato.replace(
          "&",
          "%26"
        )}&anno=${anno}`,
      getToken()
    );
    dispatch({
      type: GET_RETTIFICHE,
      payload: result.data
    });
  } catch (error) {}
};

export const postRettifiche = rettifiche => async dispatch => {
  try {
    const result = await axios.post(
      API_URL + `bilancio/rettifiche/`,
      rettifiche,
      getToken()
    );
    dispatch({
      type: GET_RETTIFICHE,
      payload: result.data
    });
    SuccessAlert("Rettifiche aggiornate con successo");
  } catch (error) {}
};

export const getCancellaList = () => async (dispatch, getState) => {
  try {
    const result = await axios.get(
      API_URL +
        `bilancio/cancella/?cliente=${getState().auth.clienteSelezionato.replace(
          "&",
          "%26"
        )}`,
      getToken()
    );
    dispatch({
      type: GET_CANCELLA_LIST,
      payload: result.data
    });
  } catch (error) {}
};

export const cancellaTrimestre = data => async (dispatch, getState) => {
  try {
    const result = await axios.post(
      API_URL +
        `bilancio/cancella/?cliente=${getState().auth.clienteSelezionato.replace(
          "&",
          "%26"
        )}`,
      data,
      getToken()
    );
    dispatch({
      type: REMOVE_CANCELLA_LIST,
      payload: result.data
    });
  } catch (error) {}
};

export const setEBIT = val => dispatch => {
  dispatch({
    type: SET_EBIT,
    payload: val
  });
};
export const setEBITDA = val => dispatch => {
  dispatch({
    type: SET_EBITDA,
    payload: val
  });
};
