import React, { Component } from "react";
import { Paper, Typography, Grid, TextField, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import User from "../../../models/User";

import { connect } from "react-redux";
import { creazioneUtenteDaCommercialista } from "../../../actions/auth";

const styles = theme => ({
  paper: {
    margin: "auto",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    maxWidth: 500,
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3)
    }
  },
  textField: {
    maxWidth: "100%"
  },
  form: {
    marginTop: theme.spacing(2)
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%"
  },
  button: { margin: theme.spacing(1) }
});

class CreaUtente extends Component {
  state = {
    ragione_sociale: "",
    ragione_sociale_error: false,
    ragione_sociale_error_msg: "",
    username: "",
    username_error: false,
    username_error_msg: "",
    email: "",
    email_error: false,
    email_error_msg: "",
    password1: "",
    password1_error: false,
    password1_error_msg: "",
    password2: "",
    password2_error: false,
    password2_error_msg: "",
    errors: false
  };

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  onSubmit = e => {
    e.preventDefault();

    const validUsername = /[ !@#$%^&*()+=[]{};':"\\|,.<>\/?]/;
    const validEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    var state = {
      ...this.state,
      ragione_sociale_error: false,
      ragione_sociale_error_msg: "",
      username_error: false,
      username_error_msg: "",
      email_error: false,
      email_error_msg: "",
      password1_error: false,
      password1_error_msg: "",
      password2_error: false,
      password2_error_msg: "",
      errors: false
    };

    // ragione_sociale VALIDATION
    if (state.ragione_sociale.length === 0) {
      state.ragione_sociale_error = true;
      state.ragione_sociale_error_msg = "Il campo non può essere vuoto";
    }
    // username VALIDATION
    if (state.username.length === 0) {
      state.errors = true;
      state.username_error = true;
      state.username_error_msg = "Il campo non può essere vuoto";
    } else if (state.username.includes(" ")) {
      state.errors = true;
      state.username_error = true;
      state.username_error_msg = "Il campo username non può contenere spazi";
    } else if (validUsername.test(state.username)) {
      state.errors = true;
      state.username_error = true;
      state.username_error_msg =
        "Il campo username non può contenere caratteri speciali";
    }
    // email VALIDATION
    if (state.email.length === 0) {
      state.errors = true;
      state.email_error = true;
      state.email_error_msg = "Il campo non può essere vuoto";
    } else if (!validEmail.test(state.email)) {
      state.errors = true;
      state.email_error = true;
      state.email_error_msg = "Email non valida";
    }
    // password VALIDATION
    if (state.password1.length === 0) {
      state.errors = true;
      state.password1_error = true;
      state.password1_error_msg = "Il campo non può essere vuoto";
    }
    if (state.password2.length === 0) {
      state.errors = true;
      state.password2_error = true;
      state.password2_error_msg = "Il campo non può essere vuoto";
    }
    if (
      state.password1.length !== 0 &&
      state.password2.length !== 0 &&
      state.password1 !== state.password2
    ) {
      state.errors = true;
      state.password1_error = true;
      state.password2_error = true;
      state.password2_error_msg = "Le due password inserite non coincidono";
    }

    if (state.errors) {
      this.setState(state);
      return;
    }

    const user = new User(
      state.username,
      state.email,
      true,
      true,
      state.ragione_sociale
    );

    this.props.creazioneUtenteDaCommercialista(user, state.password1);

    this.setState({
      ragione_sociale: "",
      ragione_sociale_error: false,
      ragione_sociale_error_msg: "",
      username: "",
      username_error: false,
      username_error_msg: "",
      email: "",
      email_error: false,
      email_error_msg: "",
      password1: "",
      password1_error: false,
      password1_error_msg: "",
      password2: "",
      password2_error: false,
      password2_error_msg: "",
      errors: false
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h5" align="center">
          Crea Cliente
        </Typography>
        <form noValidate className={classes.form} onSubmit={this.onSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="off"
                autoFocus
                required
                name="ragione_sociale"
                label="Nome / Ragione Sociale"
                fullWidth
                value={this.state.ragione_sociale}
                onChange={this.onChange}
                error={this.state.ragione_sociale_error}
                helperText={this.state.ragione_sociale_error_msg}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="off"
                required
                name="username"
                label="Nome utente"
                fullWidth
                placeholder="Il nome utente non deve contenere spazi..."
                value={this.state.username}
                onChange={this.onChange}
                error={this.state.username_error}
                helperText={this.state.username_error_msg}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="off"
                required
                name="email"
                label="Email"
                type="email"
                fullWidth
                value={this.state.email}
                onChange={this.onChange}
                error={this.state.email_error}
                helperText={this.state.email_error_msg}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="off"
                required
                name="password1"
                label="Password"
                type="password"
                fullWidth
                value={this.state.password1}
                onChange={this.onChange}
                error={this.state.password1_error}
                helperText={this.state.password1_error_msg}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="off"
                required
                name="password2"
                label="Ripeti password"
                type="password"
                fullWidth
                value={this.state.password2}
                onChange={this.onChange}
                error={this.state.password2_error}
                helperText={this.state.password2_error_msg}
              />
            </Grid>
            <div className={classes.buttonContainer}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                type="submit"
              >
                CREA
              </Button>
            </div>
          </Grid>
        </form>
      </Paper>
    );
  }
}

export default connect(null, { creazioneUtenteDaCommercialista })(
  withStyles(styles)(CreaUtente)
);
