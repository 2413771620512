import React, { Component } from "react";
import { Paper, Typography, Grid, Button, TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

import { connect } from "react-redux";

import moment from "moment";

import { DropzoneArea } from "material-ui-dropzone";
import {
  caricaTributi,
  getImposte,
  caricaImposte
} from "../../../actions/bilancio";
import ErrorAlert from "../../alerts/ErrorAlert";

const styles = theme => ({
  paper: {
    margin: "auto",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),

    maxWidth: 850,
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3)
    }
  },
  textField: {
    maxWidth: "100%"
  },
  form: {
    marginTop: theme.spacing(2)
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%"
  },
  button: { margin: theme.spacing(1) }
});

class CaricamentoImposte extends Component {
  state = {
    keyCrediti: 0,
    keyDebiti: 1000,
    data: moment().startOf("quarter"),
    trimestre: 1,
    crediti: null,
    debiti: null,
    percentuale: this.props.clienteSelezionatoPercentualeImposte,
    importo: this.props.clienteSelezionatoImposte
  };
  componentDidMount() {
    this.props.getImposte();
  }
  componentWillReceiveProps(props) {
    this.setState({
      percentuale: props.clienteSelezionatoPercentualeImposte,
      importo: props.clienteSelezionatoImposte
    });
  }

  formatDate = date => {
    return date.year();
  };
  cambiaData = data => {
    this.setState({
      data: data.endOf("year"),
      trimestre: data
        .quarter(this.state.trimestre)
        .startOf("quarter")
        .isBefore(moment())
        ? this.state.trimestre
        : 1
    });
  };

  creditiUpload = files => {
    if (files.length === 1) {
      this.setState({
        crediti: files[0]
      });
    } else {
      this.setState({
        crediti: null
      });
    }
  };
  debitiUpload = files => {
    if (files.length === 1) {
      this.setState({
        debiti: files[0]
      });
    } else {
      this.setState({
        debiti: null
      });
    }
  };

  onSubmit = async e => {
    e.preventDefault();
    if (this.state.file === null) {
      ErrorAlert("Si prega di caricare un file");
      return;
    }

    const formData = new FormData();

    formData.append("crediti", this.state.crediti);
    formData.append("debiti", this.state.debiti);
    formData.append("cliente", this.props.clienteSelezionato);
    formData.append("percentuale", this.state.percentuale);
    formData.append("importo", this.state.importo);

    await this.props.caricaTributi(formData);
    this.setState({
      keyCrediti: this.state.keyCrediti + 1,
      keyDebiti: this.state.keyDebiti + 1,
      crediti: null,
      debiti: null
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.wrapper}>
        <form
          noValidate
          className={classes.form}
          onSubmit={e => {
            e.preventDefault();
            const formData = new FormData();

            formData.append("percentuale", this.state.percentuale);
            formData.append("importo", this.state.importo);

            this.props.caricaImposte(formData);
          }}
        >
          <Paper className={classes.paper}>
            <Typography
              component="h1"
              variant="h5"
              align="center"
              style={{ marginBottom: 20 }}
            >
              Previsione imposte annuali
            </Typography>
            <Grid
              container
              justify="space-evenly"
              style={{ marginBottom: 10, marginTop: 10 }}
            >
              <Grid xs="12" sm="10" md="5">
                <TextField
                  fullWidth
                  name="percentuale"
                  label="Percentuale sull'utile ante imposte"
                  value={this.state.percentuale}
                  type="number"
                  onChange={e =>
                    this.setState({
                      percentuale: e.target.value
                    })
                  }
                />
              </Grid>
              <Grid xs="12" sm="10" md="5">
                <TextField
                  fullWidth
                  name="importo"
                  label="Importo imposte"
                  type="number"
                  value={this.state.importo}
                  onChange={e =>
                    this.setState({
                      importo: e.target.value
                    })
                  }
                />
              </Grid>
            </Grid>
            <div className={classes.buttonContainer}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                type="submit"
              >
                Salva
              </Button>
            </div>
          </Paper>
          <Paper className={classes.paper}>
            <Typography
              component="h1"
              variant="h5"
              align="center"
              style={{ marginBottom: 20 }}
            >
              Caricamento Tributi a 6 mesi
            </Typography>

            <Grid container spacing={2} alignItems="center" justify="center">
              <Grid item xs={12} style={{ marginBottom: 10, marginTop: 10 }}>
                <Typography component="h2" variant="h6" align="center">
                  Crediti
                </Typography>
                <DropzoneArea
                  key={this.state.keyCrediti}
                  filesLimit={1}
                  acceptedFiles={[".xlsx", ".csv"]}
                  showFileNames={true}
                  dropzoneText="Trascina o clicca per selezionare un file excel"
                  maxFileSize={10485760}
                  getFileAddedMessage={fileName =>
                    `File ${fileName} aggiunto con successo`
                  }
                  getFileRemovedMessage={fileName => `File ${fileName} rimosso`}
                  getDropRejectMessage={(
                    rejectedFile,
                    acceptedFiles,
                    maxFileSize
                  ) => {
                    if (rejectedFile.size > maxFileSize) {
                      return `Il file ${rejectedFile.name} supera la dimensione massima di 10M`;
                    } else {
                      return `Il file ${
                        rejectedFile.name
                      } non è in formato ${acceptedFiles.join(", ")}`;
                    }
                  }}
                  onChange={this.creditiUpload}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography component="h2" variant="h6" align="center">
                  Debiti
                </Typography>
                <DropzoneArea
                  key={this.state.keyDebiti}
                  filesLimit={1}
                  acceptedFiles={[".xlsx", ".csv"]}
                  showFileNames={true}
                  dropzoneText="Trascina o clicca per selezionare un file excel"
                  maxFileSize={10485760}
                  getFileAddedMessage={fileName =>
                    `File ${fileName} aggiunto con successo`
                  }
                  getFileRemovedMessage={fileName => `File ${fileName} rimosso`}
                  getDropRejectMessage={(
                    rejectedFile,
                    acceptedFiles,
                    maxFileSize
                  ) => {
                    if (rejectedFile.size > maxFileSize) {
                      return `Il file ${rejectedFile.name} supera la dimensione massima di 10M`;
                    } else {
                      return `Il file ${
                        rejectedFile.name
                      } non è in formato ${acceptedFiles.join(", ")}`;
                    }
                  }}
                  onChange={this.debitiUpload}
                />
              </Grid>
              <div className={classes.buttonContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  type="submit"
                >
                  CARICA
                </Button>
              </div>
            </Grid>
          </Paper>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  clienteSelezionato: state.auth.clienteSelezionato,
  clienteSelezionatoImposte: state.auth.clienteSelezionatoImposte,
  clienteSelezionatoPercentualeImposte:
    state.auth.clienteSelezionatoPercentualeImposte
});

export default connect(mapStateToProps, {
  caricaTributi,
  caricaImposte,
  getImposte
})(withStyles(styles)(CaricamentoImposte));
