export default class User {
  constructor(
    username = "",
    email = "",
    commercialista = "",
    is_superuser = "",
    ragione_sociale = "",
    clienti = []
  ) {
    this.username = username;
    this.email = email;
    this.commercialista = commercialista;
    this.is_superuser = is_superuser;
    this.ragione_sociale = ragione_sociale;
    this.clienti = clienti;
  }
}
